// assets
import { IconArticle, IconMessage, IconBell } from '@tabler/icons';

// constant
const icons = { IconArticle, IconMessage, IconBell };

// ==============================||Others MENU ITEMS ||============================== //

const others = {
    id: 'others',
    type: 'group',
    title: 'Others',
    children: [
        {
            id: 'notification',
            title: 'Notification',
            type: 'item',
            url: '/others/notification',
            icon: icons.IconBell,
            breadcrumbs: false,
            children: [
                {
                    id: 'Notification-Create',
                    title: 'Create Notification',
                    type: 'item',
                    url: '/create-notification',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'article',
            title: 'Articles',
            type: 'item',
            url: '/others/articles',
            icon: icons.IconArticle,
            breadcrumbs: false,
            children: [
                {
                    id: 'Articles-Details',
                    title: 'Article Details',
                    type: 'item',
                    url: '/article-details',
                    breadcrumbs: false
                },
                {
                    id: 'Articles-Create',
                    title: 'Create Article',
                    type: 'item',
                    url: '/create-article',
                    breadcrumbs: false
                },
                {
                    id: 'Articles-Edit',
                    title: 'Edit Article',
                    type: 'item',
                    url: '/edit-article',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'feedback',
            title: 'Feedback',
            type: 'item',
            url: '/others/feedback',
            icon: icons.IconMessage,
            breadcrumbs: false
        }
    ]
};

export default others;
