import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase, Typography } from '@mui/material';

// project imports
import config from 'utils/config/config';
import Logo from 'assets/images/myrice.png';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <img src={Logo} alt="company-logo" width={50} height={50} />
            {/* <Typography sx={{ ml: 1, fontWeight: 'bold', fontSize: 18 }}>MyRice</Typography> */}
        </div>
    </ButtonBase>
);

export default LogoSection;
