import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    IconButton,
    SwipeableDrawer,
    Switch,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { ToastMessageType, userTypes } from 'utils/constants';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import { formatServerDate, formatTimeStampDate } from 'utils/miscellaneous';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import VerifiedRoundedIcon from 'assets/images/circle-check.svg';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import config from 'utils/config/config';
import { LoadingButton } from '@mui/lab';
import useAxios from 'hooks/useAxios';
import { GET_USERS, UPDATE_SELLER_APPROVAL, UPDATE_SELLER_REJECTED, USER_STATUS_UPDATE } from 'utils/config/endpoints';
import { OK } from 'utils/config/response-codes';
import useFeedbackContext from 'contexts/FeedbackContext';
import UpdateStatusDialog from './UpdateStatusDialog';
import CardLoading from 'components/Loading/CardLoading';

const CardContentNoPadding = styled(CardContent)(`
  padding: 0.5;
  &:last-child {
    padding-bottom: 0;
  }
`);

function UserDetailsPopUp({ showDrawer, closeDrawer, userData, onUpdateCallback, isUserApprovalJourney = false, updateUserTable }) {
    const theme = useTheme();
    const [userAddress, setUserAddress] = useState('');
    const [userStatusActive, setUserStatusActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userSelectedAction, setUserSelectedAction] = useState(0);
    const [bankInfoDetails, setBankInfoDetails] = useState(null);
    const [businessInfoDetails, setBusinessInfoDetails] = useState(null);
    const { axiosInstance } = useAxios();
    const { showToastMessage } = useFeedbackContext();
    const [isRejectPopUp, setIsRejectPopUp] = useState(false);
    const [selectedButtonAction, setSelectedButtonAction] = useState(null);
    const [userStatusLoading, setUserStatusLoading] = useState(false);
    const [isUserStatusFetchError, setUserStatusFetchError] = useState(false);

    const isMobileUi = useMediaQuery('(max-width:600px)');

    React.useEffect(() => {
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (selectedButtonAction !== null) {
            setIsRejectPopUp(true);
        }
    }, [selectedButtonAction]);

    React.useEffect(() => {
        if (userData != null) {
            const formattedAddress = `${userData?.area}, ${userData?.city}, ${userData?.state}, ${userData?.country}, ${userData?.pincode}`;
            setUserAddress(userData?.area !== null ? formattedAddress : '');
            setBankInfoDetails({
                bank_account_holder_name: userData?.bank_account_holder_name,
                bank_account_no: userData?.bank_account_no,
                bank_ifsc: userData?.bank_ifsc,
                bank_swift: userData?.bank_swift
            });
            setBusinessInfoDetails({
                cp_name: userData?.cp_name,
                cp_email: userData?.cp_email,
                brand_design: userData?.brand_design,
                licence: userData?.licence,
                cp_tan: userData?.cp_tan,
                cp_pan: userData?.cp_pan,
                gstin: userData?.gstin,
                brand_selected: userData?.brand_selected
            });

            const status = userData?.status === null ? true : userData?.status; // 0 or null unblock and 1 block false means active true means inactive
            setUserStatusActive(status);
        }
    }, [userData]);

    const fetchUserBlockStatus = () => {
        setUserStatusLoading(true);
        axiosInstance
            .get(`${GET_USERS}/${userData?.seller_id}`)
            .then((response) => {
                const tempStatus = response.data?.data?.status;
                setUserStatusFetchError(false);
                setUserStatusLoading(false);
                if (response.data.response_code === OK) {
                    const status = tempStatus === null ? true : !tempStatus; // 0 or null unblock and 1 block
                    setUserStatusActive(status);
                    updateUserTable(true);
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch((error) => {
                setUserStatusFetchError(true);
                setUserStatusLoading(false);
                showToastMessage(error.message, ToastMessageType.ERROR);
            });
    };

    const updateUserBlockStatus = (userStatus) => {
        axiosInstance
            .post(`${USER_STATUS_UPDATE}/${userData?.seller_id}?status=${userStatus}`)
            .then((response) => {
                if (response.data.response_code === OK) {
                    setUserStatusActive(userStatus);
                    showToastMessage(userStatus ? 'User Unblocked!!' : 'User Blocked!!', ToastMessageType.DEFAULT);
                    updateUserTable(true);
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch((error) => {
                showToastMessage(error.message, ToastMessageType.ERROR);
            });
    };

    const closeDialog = () => {
        if (isLoading) {
            showToastMessage('Updating status please wait!!', ToastMessageType.ERROR);
        } else {
            setIsLoading(false);
            closeDrawer();
        }
    };

    const updateUserStatus = (status, message) => {
        const isUserApprove = status === config.userStatus.approved;
        setUserSelectedAction(status);
        setIsLoading(true);

        axiosInstance
            .post(`${isUserApprove ? UPDATE_SELLER_APPROVAL : UPDATE_SELLER_REJECTED}/${userData?.id}`, null, {
                params: { reason: message }
            })
            .then((response) => {
                setIsLoading(false);
                if (response.data.response_code === OK) {
                    onUpdateCallback();
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch((error) => {
                showToastMessage(error.message, ToastMessageType.ERROR);
                setIsLoading(false);
            });
    };

    return (
        <SwipeableDrawer
            anchor={'right'}
            open={showDrawer}
            onOpen={(val) => {}}
            onClose={closeDialog}
            PaperProps={{
                style: {
                    width: isMobileUi ? '100%' : 400
                }
            }}
        >
            <Box
                as="div"
                sx={{
                    width: isMobileUi ? '100%' : 400,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    bgcolor: theme.palette.primary.light
                }}
            >
                {userData !== null && (
                    <>
                        <div
                            style={{
                                backgroundColor: userData?.status === config.userStatus.pending ? '#C1EAF6' : 'transparent',
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <Tooltip
                                title="Close"
                                style={{ position: userData?.status === config.userStatus.pending ? 'fixed' : 'absolute' }}
                            >
                                <IconButton
                                    onClick={closeDialog}
                                    sx={{
                                        position: 'relative',
                                        '&:after': {
                                            content: "''",
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            borderRadius: '50%',
                                            opacity: 0.3
                                        }
                                    }}
                                >
                                    <CloseIcon style={{ color: 'black' }} />
                                </IconButton>
                            </Tooltip>

                            <Typography
                                as="div"
                                variant="h5"
                                color="white"
                                sx={{
                                    display: userData?.status === config.userStatus.pending ? 'flex' : 'none',
                                    color: 'black',
                                    width: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: 1
                                }}
                            >
                                Pending Approval Id:
                                <b>{userData?.id}</b>
                            </Typography>
                        </div>

                        <Box sx={{ flex: 1, overflowY: 'scroll' }}>
                            <Typography
                                fontSize={12}
                                color="black"
                                alignItems={'center'}
                                display={'flex'}
                                sx={{ fontStyle: 'italic' }}
                                justifyContent={'center'}
                                mt={2}
                            >
                                Created: {formatServerDate(userData?.chdate)}
                            </Typography>

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'flex-end', marginRight: 10 }}>
                                    {userData?.status !== config.userStatus.pending && (
                                        <Typography
                                            sx={{
                                                alignItems: 'center',
                                                display: userData?.review_avg ? 'flex' : 'none',
                                                mt: 1
                                            }}
                                        >
                                            <StarRateRoundedIcon style={{ color: 'gold' }} />
                                            <Typography fontSize={16} fontWeight={700}>
                                                {userData?.review_avg}{' '}
                                            </Typography>{' '}
                                            /5.0
                                        </Typography>
                                    )}
                                </div>
                            </div>

                            <Box sx={{ m: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Avatar
                                    alt="user-profile"
                                    src={userData?.profile_image}
                                    sx={{
                                        width: 100,
                                        height: 100,
                                        border: 4,
                                        borderColor: userData?.type === userTypes.SELLER.toLowerCase() ? '#3FB9B4' : '#B93F84'
                                    }}
                                />
                                <Box sx={{ ml: 2 }}>
                                    {userData?.name !== null && (
                                        <Typography variant="h4">{`${userData?.name} ${
                                            userData?.last_name == null ? '' : userData?.last_name
                                        }`}</Typography>
                                    )}

                                    <Typography sx={{ fontStyle: 'italic' }}>
                                        <b>ID:</b> #{userData?.seller_id}
                                    </Typography>

                                    {/* {userData?.display_name && (
                            <Typography>
                                <b>Display Name:</b> {userData?.display_name}
                            </Typography>
                        )} */}
                                    <Typography>
                                        <b>Email:</b>{' '}
                                        <a
                                            href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${userData?.email}`}
                                            target="_blank"
                                            onClick={() => {
                                                window.location.href = `mailto:${userData?.email}`;
                                            }}
                                            rel="noreferrer"
                                        >
                                            {userData?.email}
                                        </a>
                                    </Typography>
                                    <Typography>
                                        <b>Phone:</b> {userData?.mobile}
                                    </Typography>
                                    <Chip
                                        label={userData?.type}
                                        sx={{
                                            backgroundColor: userData?.type === userTypes.SELLER.toLowerCase() ? '#E0EDEC' : '#F3E6ED',
                                            color: userData?.type === userTypes.SELLER.toLowerCase() ? '#3FB9B4' : '#B93F84',
                                            fontWeight: '600',
                                            mt: 1,
                                            mr: -1
                                        }}
                                    />
                                </Box>
                            </Box>

                            <Box m={2}>
                                {userData?.alternate_mobile && (
                                    <Typography>
                                        <b>Alternate Contact:</b> {userData?.alternate_mobile}
                                    </Typography>
                                )}
                                <Typography>
                                    <b>Address:</b> {userAddress}
                                </Typography>

                                {userData?.reason && (
                                    <Typography
                                        variant="h5"
                                        style={{
                                            backgroundColor: userData?.status === config.userStatus.approved ? '#D6FACC' : '#FFBBBB',
                                            color: userData?.status === config.userStatus.approved ? '#119122' : '#EC5454',
                                            marginTop: 10,
                                            padding: 20,
                                            borderRadius: 10
                                        }}
                                    >
                                        {userData?.reason}
                                    </Typography>
                                )}
                            </Box>

                            <Divider m={2} />

                            <Box m={2}>
                                {userData?.status !== config.userStatus.pending &&
                                    (userStatusLoading ? (
                                        <CardLoading />
                                    ) : isUserStatusFetchError ? (
                                        <Box
                                            sx={{
                                                p: 2,
                                                alignItems: 'center',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                backgroundColor: 'white',
                                                mb: 2
                                            }}
                                        >
                                            <Typography variant="h4" textAlign={'center'}>
                                                Something Went Wrong While Fetching user Block Status!!
                                            </Typography>
                                            <Button sx={{ mt: 2 }} variant="contained" onClick={fetchUserBlockStatus}>
                                                Retry
                                            </Button>
                                        </Box>
                                    ) : (
                                        <Card elevation={2} sx={{ p: 1, borderRadius: 2, mt: 2, mb: 2 }}>
                                            <CardContentNoPadding
                                                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', p: 1 }}
                                            >
                                                <div>
                                                    <Typography variant="h5" component="div" sx={{ flex: 1, ml: 1, mr: 2 }}>
                                                        Toggle the switch to Block or UnBlock User
                                                    </Typography>
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <Switch
                                                        checked={userStatusActive}
                                                        color="success"
                                                        onChange={(event) => {
                                                            updateUserBlockStatus(event.target.checked);
                                                        }}
                                                    />
                                                    <Typography fontSize={12} fontWeight={200} component="div" sx={{ flex: 1, ml: 1 }}>
                                                        {userStatusActive ? 'Active' : 'Block'}
                                                    </Typography>
                                                </div>
                                            </CardContentNoPadding>
                                        </Card>
                                    ))}

                                {userData?.subscription && (
                                    <Accordion elevation={2} sx={{ p: 1, mb: 2, borderRadius: 2 }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <div style={{ flex: 1, marginRight: 5 }}>
                                                    <Typography variant="h5">Subscription Information</Typography>
                                                </div>
                                                <div>
                                                    {userData?.subscription?.active ? (
                                                        <img src={VerifiedRoundedIcon} style={{ width: 20, height: 20 }} />
                                                    ) : (
                                                        <CancelRoundedIcon style={{ color: 'red' }} />
                                                    )}
                                                </div>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Divider />
                                            <Typography sx={{ mt: 2, mb: 2 }}>
                                                <b>Name:</b> {userData?.subscription?.plan?.name}
                                            </Typography>
                                            <Typography sx={{ mt: 2, mb: 2 }}>
                                                <b>Purchase Date:</b> {formatTimeStampDate(userData?.subscription?.crdate)}
                                            </Typography>
                                            <Typography sx={{ mt: 2, mb: 2 }}>
                                                <b>Expiry Date:</b> {formatTimeStampDate(userData?.subscription?.expiry_at)}
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                )}

                                <Accordion hidden={businessInfoDetails === null} elevation={2} sx={{ p: 1, mb: 2, borderRadius: 2 }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Typography variant="h5">Business Information</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Divider />
                                        <Typography sx={{ mt: 2, mb: 2 }}>
                                            <b>Name:</b> {businessInfoDetails?.cp_name}
                                        </Typography>
                                        <Typography sx={{ mt: 2, mb: 2 }}>
                                            <b>Email:</b>{' '}
                                            <a
                                                href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${businessInfoDetails?.cp_email}`}
                                                target="_blank"
                                                onClick={() => {
                                                    window.location.href = `mailto:${businessInfoDetails?.cp_email}`;
                                                }}
                                                rel="noreferrer"
                                            >
                                                {businessInfoDetails?.cp_email}
                                            </a>
                                        </Typography>

                                        {businessInfoDetails?.brand_design !== null && (
                                            <>
                                                <Typography sx={{ mt: 2, mb: 2 }}>
                                                    <b>Brand Logos:</b>
                                                </Typography>
                                                <Box sx={{ display: 'flex', flexWrap: 'nowrap', overflowX: 'auto' }}>
                                                    {businessInfoDetails?.brand_design?.map((item, index) => (
                                                        <div key={item.id} style={{ position: 'relative' }}>
                                                            <Avatar
                                                                sx={{ ml: index === 0 ? 0 : 2, height: 50, width: 50 }}
                                                                src={item.url}
                                                            />
                                                            {businessInfoDetails?.business_info?.brand_selected === item.id && (
                                                                <div style={{ position: 'absolute', bottom: 1, right: -5 }}>
                                                                    <img src={VerifiedRoundedIcon} style={{ width: 20, height: 20 }} />
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </Box>
                                            </>
                                        )}
                                        <Typography sx={{ mt: 2, mb: 2 }}>
                                            <b>Licence:</b>{' '}
                                            {businessInfoDetails?.licence === null || businessInfoDetails?.licence === ''
                                                ? '-'
                                                : businessInfoDetails?.licence}
                                        </Typography>
                                        <Typography sx={{ mt: 2, mb: 2 }}>
                                            <b>Tan Number:</b>{' '}
                                            {businessInfoDetails?.cp_tan === null || businessInfoDetails?.cp_tan === ''
                                                ? '-'
                                                : businessInfoDetails?.cp_tan}
                                        </Typography>
                                        <Typography sx={{ mt: 2, mb: 2 }}>
                                            <b>Pan Number:</b>{' '}
                                            {businessInfoDetails?.cp_pan === null || businessInfoDetails?.cp_pan === ''
                                                ? '-'
                                                : businessInfoDetails?.cp_pan}
                                        </Typography>
                                        <Typography sx={{ mt: 2, mb: 2 }}>
                                            <b>Gst Number:</b>{' '}
                                            {businessInfoDetails?.gstin === null || businessInfoDetails?.gstin === ''
                                                ? '-'
                                                : businessInfoDetails?.gstin}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion hidden={bankInfoDetails === null} elevation={2} sx={{ p: 1, mb: 2, borderRadius: 2 }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Typography variant="h5">Bank Details</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Divider />
                                        <Typography sx={{ mt: 2, mb: 2 }}>
                                            <b>Account Holder Name:</b>
                                            {bankInfoDetails?.bank_account_holder_name === null ||
                                            bankInfoDetails?.bank_account_holder_name === ''
                                                ? '-'
                                                : bankInfoDetails?.bank_account_holder_name}
                                        </Typography>
                                        <Typography sx={{ mt: 2, mb: 2 }}>
                                            <b>Account Number:</b>{' '}
                                            {bankInfoDetails?.bank_account_no === null || bankInfoDetails?.bank_account_no === ''
                                                ? '-'
                                                : bankInfoDetails?.bank_account_no}
                                        </Typography>
                                        <Typography sx={{ mt: 2, mb: 2 }}>
                                            <b>IFSC Code:</b>{' '}
                                            {bankInfoDetails?.bank_ifsc === null || bankInfoDetails?.bank_ifsc === ''
                                                ? '-'
                                                : bankInfoDetails?.bank_ifsc}
                                        </Typography>
                                        <Typography sx={{ mt: 2, mb: 2 }}>
                                            <b>Swift Code:</b>
                                            {bankInfoDetails?.bank_swift === null || bankInfoDetails?.bank_swift === ''
                                                ? '-'
                                                : bankInfoDetails?.bank_swift}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: userData?.status === config.userStatus.pending ? 'flex' : 'none',
                                flexDirection: 'row',
                                height: 50
                            }}
                        >
                            <LoadingButton
                                fullWidth
                                loading={isLoading}
                                variant="contained"
                                hidden={userSelectedAction === config.userStatus.approved && isLoading}
                                onClick={() => {
                                    setSelectedButtonAction(config.userStatus.approved);
                                }}
                                sx={{
                                    display: userSelectedAction !== config.userStatus.approved && isLoading ? 'none' : 'auto',
                                    borderRadius: 0,
                                    color: 'white',
                                    ':hover': { backgroundColor: '#82DEAF' },
                                    backgroundColor: '#0D9D52'
                                }}
                            >
                                Approve
                            </LoadingButton>
                            <LoadingButton
                                fullWidth
                                variant="contained"
                                loading={isLoading}
                                onClick={() => {
                                    setSelectedButtonAction(config.userStatus.rejected);
                                }}
                                sx={{
                                    display: userSelectedAction !== config.userStatus.rejected && isLoading ? 'none' : 'auto',
                                    borderRadius: 0,
                                    color: 'white',
                                    ':hover': { backgroundColor: '#E38282' },
                                    backgroundColor: '#D80B0B'
                                }}
                            >
                                Reject
                            </LoadingButton>
                        </Box>
                    </>
                )}
            </Box>

            <UpdateStatusDialog
                showDialog={isRejectPopUp}
                userStatus={selectedButtonAction}
                onCloseCallback={() => {
                    setSelectedButtonAction(null);
                    setIsRejectPopUp(false);
                }}
                updateStatusCallback={(val) => {
                    setSelectedButtonAction(null);
                    updateUserStatus(selectedButtonAction, val);
                    setIsRejectPopUp(false);
                }}
            />
        </SwipeableDrawer>
    );
}

export default UserDetailsPopUp;
