export const TOAST_MESSAGE_TIME = 3000;
export const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB in bytes

export const ToastMessageType = {
    DEFAULT: 1,
    SUCCESS: 2,
    WARNING: 3,
    ERROR: 4
};

export const ArticleType = {
    DEFAULT: 'Default',
    CUSTOM: 'Custom'
};
export const ArticleTypes = [ArticleType.DEFAULT, ArticleType.CUSTOM];

export const ArticleDefaultData = { id: '', type: '', image: '', date: '', uri: '', title: '', description: '', image_blob: '' };

export const userStatus = {
    DEFAULT: 'Active',
    BLOCK: 'Block'
};

export const subscriptionStatus = {
    IN_ACTIVE: 'In Active',
    ACTIVE: 'Active'
};

export const userTypes = {
    GUEST: 'Guest',
    BUYER: 'Buyer',
    SELLER: 'Seller'
};

export const firebaseStorageUrl = 'https://firebasestorage.googleapis.com';

export const serverState = {
    LOADING: 'loading',
    ERROR: 'error',
    DEFAULT: 'default'
};
