import { Avatar, Chip, Typography } from '@mui/material';
import { formatServerDate, utcTime } from 'utils/miscellaneous';

export const tableConfig = {
    noDataMessage: 'No Products Added',
    enablePagination: true,
    maxRows: 10,
    enableRowClick: true
};

export const ProductTableConfig = {
    headCells: [
        {
            id: 'id',
            numeric: true,
            disablePadding: false,
            label: 'id'
        },
        {
            id: 'category',
            disablePadding: false,
            label: 'category',
            export_render: (value) => {
                return <Typography variant="h5">{value}</Typography>;
            }
        },
        {
            id: 'name',
            disablePadding: false,
            label: 'Name',
            data_key: ['name', 'image'],
            export_render: (value) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', width: 200, alignItems: 'center' }}>
                        <img alt={value.name} src={value.image} width={50} height={50} />
                        <Typography variant="h5" sx={{ ml: 2 }}>
                            {value.name}
                        </Typography>
                    </div>
                );
            }
        },
        {
            id: 'specifications',
            numeric: true,
            disablePadding: false,
            label: 'specifications',
            export_render: (value) => {
                console.log(value);
                var data;
                for (const key in value) {
                    data = data + `${key}:${value[key]}`;
                }
                return (
                    <>
                        {value.length > 0 ? (
                            value.map((item) => (
                                <div key={`${item.w}`}>
                                    {item.w} kg | <b>$ {item.price}</b>
                                </div>
                            ))
                        ) : (
                            <div>-</div>
                        )}
                    </>
                );
            }
        },
        {
            id: 'min_price',
            disablePadding: false,
            label: 'min_price'
        },
        {
            id: 'max_price',
            disablePadding: false,
            label: 'max_price'
        },
        {
            id: 'last_traded_price',
            numeric: true,
            disablePadding: false,
            label: 'Last Traded Price'
        },
        {
            id: 'quotation_count',
            numeric: true,
            disablePadding: false,
            label: 'Total Quotations'
        },
        {
            id: 'cr_date',
            disablePadding: false,
            label: 'Date',
            export_render: (value) => <Typography>{utcTime(value)}</Typography>
        }
    ]
};

export const QuotationTableConfig = {
    tableConfig: {
        viewOnly: true,
        noDataMessage: 'No Quotations uploaded',
        enableExternalPagination: true,
        maxRows: 5,
        enableRowClick: true,
        isRefresh: true,
        enableSearch: true
    },
    headCells: [
        {
            id: 'id',
            label: 'Id'
        },
        {
            id: 'seller_name',
            disablePadding: false,
            label: 'Seller Name',
            data_key: ['seller_name', 'seller_lastName', 'seller_profileImage'],
            export_render: (value) => {
                return (
                    <Chip
                        avatar={<Avatar alt="Natacha" src={value.seller_profileImage} />}
                        label={`${value.seller_name} ${value.seller_lastName == null ? '' : value.seller_lastName}`}
                        variant="outlined"
                    />
                );
            }
        },
        {
            id: 'cp_name',
            label: 'Company Name'
        },
        {
            id: 'riceSubCategory',
            label: 'Rice Information',
            export_render: (value) => {
                return (
                    <div style={{ textAlign: 'start' }}>
                        <Typography sx={{ textAlign: 'start' }}>
                            {value === null ? '-' : value.title === null ? value.id : value.title}
                        </Typography>
                    </div>
                );
            }
        },
        {
            id: 'quantity',
            label: 'Quantity'
        },
        {
            id: 'price',
            label: 'Price'
        },
        {
            id: 'chdate',
            label: 'Date',
            export_render: (value) => {
                return <Typography sx={{ width: 150 }}>{formatServerDate(value)}</Typography>;
            }
        }
    ]
};
