import { lazy } from 'react';

// project imports
import Loadable from 'components/Loadable';

// login option 3 routing
const Login = Loadable(lazy(() => import('pages/authentication/Login')));
const NotFound = Loadable(lazy(() => import('pages/NotFound')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/login',
    title: 'AuthenticationRoutes',
    children: [
        {
            path: '/login',
            element: <Login />
        },
        {
            path: '*',
            element: <NotFound />
        }
    ]
};

export default AuthenticationRoutes;
