const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    company_name: 'IowavePvtLtd',
    company_webLink: 'iowave.in',
    myRiceWebLinkLabel: 'myrice.org',
    myRiceWebLink: 'https://myrice.org/',

    copyright: 'Iowave Pvt Ltd',
    devBaseUrl: 'https://api.myrice.org/rice/api/v2/',
    proBaseUrl: 'https://api.myrice.org/rice/api/v2/',
    version: 'v1.0.0',
    maxDefaultInputLength: 50,
    maxCurrencyInputLength: 50,
    maxCurrencyCodeInputLength: 3,
    maxCurrencySymbolInputLength: 3,
    maxSeaportInputLength: 50,
    maxSeaportPriceInputLength: 6,
    maxSeaportPreferenceInputLength: 10,
    timeout: 10000, //10s,
    notificationTitleLength: 30,
    notificationMessageLength: 1000,
    articleTitleLength: 100,
    articleDescriptionLength: 1000,
    articleWebLinkLength: 500,
    maxWeightsInputLength: 50,
    maxWeightsLength: 4,
    minRiceNameInputLength: 3,
    maxBuildVersion: 6,

    maxBannerTitleLength: 3,

    notificationActionList: [
        { id: 1, name: 'My Account' },
        { id: 2, name: 'My Approvals' },
        { id: 3, name: 'User Approvals' },
        { id: 4, name: 'Admin Approvals' },
        { id: 5, name: 'Products List' },
        { id: 6, name: 'My Orders' },
        { id: 7, name: 'Currency Data' },
        { id: 8, name: 'Seaport Data' },
        { id: 9, name: 'Packaging Data' },
        { id: 10, name: 'News Feed' },
        { id: 11, name: 'Subscriptions' },
        { id: 12, name: 'Help Support' },
        { id: 13, name: 'Logout' },
        { id: 14, name: 'Sign In' },
        { id: 15, name: 'Web' },
        { id: 16, name: 'Upload Design' },
        { id: 17, name: 'Delete Account' },
        { id: 18, name: 'Notifications' },
        { id: 19, name: 'Customer Care Support' },
        { id: 20, name: 'Restart' },
        { id: 21, name: 'Payment Terms' }
    ],
    defaultTableConfig: {
        title: '',
        viewOnly: false,
        noDataMessage: 'No Data Added',
        isActionEdit: false,
        isAddButton: false,
        maxRows: 5,
        minSearchCharacters: 3,
        isTableDense: true,
        isFilterMenu: false,
        enablePagination: false,
        enableExternalPagination: false,
        orderDefault: 'asc',
        orderByDefault: 'title',
        addButtonLabel: 'ADD',
        filterMenus: [],
        defaultFilter: null,
        searchKeys: [],
        showFilterSelectedLabel: true,
        enableSearch: false,
        enableRowClick: false,
        isRefresh: false,
        isCalendar: false,
        enableAllSelection: false,
        customActions: []
    },
    userStatus: {
        pending: 1,
        approved: 2,
        rejected: 3,
        block: 4
    },

    orderStatus: {
        pending: 1,
        approved: 2,
        rejected: 3
    },

    userRoles: {
        all: [0, 1, 3],
        user: 0,
        seller: 1,
        guest: 3
    },

    userTypesValues: {
        seller_update: 12,
        seller_create: 11,
        quotation_create: 5,
        quotation_update: 6,
        quotation_delete: 9
    },

    quotationStatus: {
        pending: 1,
        approved: 2,
        rejected: 3
    },
    serviceUsers: {
        admin: 3,
        guest: 4,
        buyer: 1,
        seller: 2,
        sellerNotApproved: 5
    },
    serviceDefaultTypeList: [
        {
            label: 'Admin',
            value: false,
            id: 3
        },
        {
            label: 'Guest',
            value: false,
            id: 4
        },
        {
            label: 'Buyer',
            value: false,
            id: 1
        },
        {
            label: 'Seller',
            value: false,
            id: 2
        },
        {
            label: 'Seller Not Approved',
            value: false,
            id: 5
        }
    ],
    platformOS: {
        Android: 0,
        iOS: 1,
        Web: 2
    }
};

export default config;
