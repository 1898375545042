import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { Avatar, Card, CardContent, CardMedia, Link, Typography, colors } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { formatServiceTypeUsers } from 'utils/miscellaneous';
import PhotoPlaceholder from 'assets/images/image_placeholder.svg';

const DraggableListItem = ({ item, index, onItemClick }) => {
    const theme = useTheme();
    const [imageError, setImageError] = React.useState(false);

    React.useEffect(() => {
        if (item.banner_image_url === null || item.banner_image_url === '') {
            setImageError(true);
        } else {
            setImageError(false);
        }
    }, [item.banner_image_url]);

    const handleImageError = () => {
        setImageError(true);
    };

    return (
        <Draggable draggableId={item.id.toString()} index={index}>
            {(provided, snapshot) => (
                <Card
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    sx={{
                        background: snapshot.isDragging ? theme.palette.primary.light : '',
                        boxShadow: 3,
                        mb: 5,
                        maxWidth: 300,
                        minWidth: 200,
                        position: 'relative'
                    }}
                    onClick={onItemClick}
                >
                    <Avatar sx={{ position: 'absolute', top: 20, left: 20, backgroundColor: 'white' }}>
                        <Typography fontWeight={700} color="black">
                            {index}
                        </Typography>
                    </Avatar>
                    <CardMedia
                        component="img"
                        sx={{ height: 150, objectFit: 'cover' }}
                        image={imageError ? PhotoPlaceholder : item.banner_image_url}
                        onError={handleImageError}
                    />
                    <CardContent sx={{ p: 2 }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                variant="body1"
                                sx={{
                                    mb: 2,
                                    display: '-webkit-box',
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: 'vertical',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden'
                                }}
                            >
                                {item.title}
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    mb: 1,
                                    display: '-webkit-box',
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: 'vertical',
                                    textOverflow: 'ellipsis',
                                    fontSize: 10,
                                    overflow: 'hidden'
                                }}
                            >
                                {formatServiceTypeUsers(item.serviceType)}
                            </Typography>

                            {item.hyperlink !== null && item.hyperlink !== '' && (
                                <Link
                                    href={item.hyperlink}
                                    sx={{ display: 'inline' }}
                                    variant="body2"
                                    color={colors.blue[400]}
                                    target="_blank"
                                >
                                    Link
                                </Link>
                            )}
                        </div>
                    </CardContent>
                </Card>
            )}
        </Draggable>
    );
};

export default DraggableListItem;
