// assets
import { IconArticle } from '@tabler/icons';

// constant
const icons = { IconArticle };

// ==============================||Approval MENU ITEMS ||============================== //

const approval = {
    id: 'approval-group',
    type: 'group',
    title: '',
    children: [
        {
            id: 'approval-collapsable',
            title: 'Approval',
            type: 'collapse',
            icon: icons.IconArticle,
            children: [
                {
                    id: 'approval_seller',
                    title: 'Seller',
                    type: 'item',
                    url: '/approval/seller',
                    breadcrumbs: false
                },
                {
                    id: 'approval_quotation',
                    title: 'Quotation',
                    type: 'item',
                    url: '/approval/quotation',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default approval;
