import { Box } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';

const tabVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
    exitLeft: { opacity: 0, x: -50 },
    exitRight: { opacity: 0, x: 50 },
    transition: { ease: 'easeInOut', duration: 0.3 }
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <AnimatePresence mode="wait">
            <motion.div
                key={index}
                variants={
                    index > value
                        ? { ...tabVariants, initial: 'hidden', animate: 'visible', exit: 'exitRight' }
                        : { ...tabVariants, initial: 'hidden', animate: 'visible', exit: 'exitLeft' }
                }
                initial="hidden"
                animate="visible"
                exit={index > value ? 'exitRight' : 'exitLeft'}
                transition={{ ease: 'easeInOut', duration: 0.3 }}
                style={{ display: value === index ? 'block' : 'none' }}
            >
                <Box p={2}>{children}</Box>
            </motion.div>
        </AnimatePresence>
    );
}

export function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}
export default TabPanel;
