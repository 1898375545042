import SessionExpiry from 'components/Dialogs/SessionExpiry';
import useAuthContext from 'contexts/AuthContext';
import React from 'react';

function AppErrorDialog() {
    const { isSessionExpiry, logout } = useAuthContext();
    return <>{isSessionExpiry && <SessionExpiry handleOnClose={logout} />}</>;
}

export default AppErrorDialog;
