import React from 'react';
import DashboardMainLayout from 'layout/DashboardMainLayout';
import { AppConfigContextProvider } from '../provider/AppConfigContext';

import ConfigLayout from './layout/configLayout';

function AppConfig() {
    return (
        <AppConfigContextProvider>
            <DashboardMainLayout title={'Settings'}>
                <ConfigLayout />
            </DashboardMainLayout>
        </AppConfigContextProvider>
    );
}

export default AppConfig;
