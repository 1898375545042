import { Box, Chip, Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';

const allChip = { id: 'all', label: 'All', value: 'all' };

function FilterProducts({ filterList, selectedIdCallback, filterDefaultSelected }) {
    const [selectedProducts, setSelectedProducts] = useState([allChip.id]);
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [selectedSubProducts, setSelectedSubProducts] = useState([]);

    useEffect(() => {
        setSelectedSubProducts(filterDefaultSelected.subProductIds);
        setSelectedProducts(filterDefaultSelected.cat_ids);
    }, [filterDefaultSelected]);

    useEffect(() => {
        selectedIdCallback(selectedProducts, selectedSubProducts);
        const tempList = [];

        if (selectedProducts !== null && selectedProducts !== undefined) {
            for (var j = 0; j < selectedProducts.length; j++) {
                for (var i = 0; i < filterList.length; i++) {
                    if (selectedProducts[j] === filterList[i].id) {
                        tempList.push(filterList[i].sub_category);
                    }
                }
            }
            const subCategoryList = tempList.flatMap((obj) => obj);
            setSubCategoryList(subCategoryList);
        }
    }, [selectedProducts, selectedSubProducts]);

    const handleSubProductChipClick = (id) => {
        let newSelectedChips = [];

        if (selectedSubProducts.includes(id)) {
            newSelectedChips = selectedSubProducts.filter((chipId) => chipId !== id);
        } else {
            newSelectedChips = [...selectedSubProducts, id];
        }

        setSelectedSubProducts(newSelectedChips);
    };

    const handleAllChipSelector = () => {
        if (selectedProducts.length > 0) {
            setSelectedProducts([]);
            setSelectedSubProducts([]);
            setSubCategoryList([]);
        } else {
            const tempList = filterList.map((chip) => chip.id);
            setSelectedProducts(tempList);
        }
    };

    const handleCategorySelection = (categoryId) => {
        let newSelectedChips = [];

        if (selectedProducts.includes(categoryId)) {
            newSelectedChips = selectedProducts.filter((chipId) => chipId !== categoryId);
        } else {
            newSelectedChips = [...selectedProducts, categoryId];
        }

        const isCategorySelected = newSelectedChips.includes(categoryId);

        // let selectedSubProductsTemp = [];
        // let subCategoryList = [];

        // if (isCategorySelected) {
        //     selectedSubProductsTemp = newSelectedChips
        //         .filter((item) => item.id === categoryId)
        //         .map((item) => item.sub_category)
        //         .flatMap((obj) => obj.map((subItem) => subItem.id));

        //     subCategoryList = newSelectedChips
        //         .filter((item) => item.id === categoryId)
        //         .map((item) => item.sub_category)
        //         .flatMap((obj) => obj);
        // } else {
        //     selectedSubProductsTemp = selectedSubProducts.filter((item) => !subCategoryList.includes(item));
        //     subCategoryList = subCategoryList.filter((item) => item.parent_id !== categoryId);
        // }

        let selectedSubProductsTemp = [...selectedSubProducts];
        let subCategoryListTemp = [...subCategoryList];

        if (isCategorySelected) {
            const selectedCategory = filterList.find((item) => item.id === categoryId);
            const newSubCategoryList = selectedCategory.sub_category;
            const newSelectedSubProducts = newSubCategoryList.map((subItem) => subItem.id);

            selectedSubProductsTemp = [...selectedSubProductsTemp, ...newSelectedSubProducts];
            subCategoryListTemp = [...subCategoryListTemp, ...newSubCategoryList];
        } else {
            const selectedCategory = filterList.find((item) => item.id === categoryId);
            const oldSubCategoryList = selectedCategory.sub_category;

            selectedSubProductsTemp = selectedSubProductsTemp.filter(
                (item) => !oldSubCategoryList.map((subItem) => subItem.id).includes(item)
            );
            subCategoryListTemp = subCategoryListTemp.filter((item) => item.parent_id !== categoryId);
        }

        setSelectedProducts(newSelectedChips);
        setSelectedSubProducts(selectedSubProductsTemp);
        setSubCategoryList(subCategoryListTemp);
    };

    return (
        <Box
            sx={{
                mb: 2,
                padding: { xs: 2, lg: 2 }
            }}
        >
            <Grid container spacing={1} alignItems={'center'}>
                {filterList.length > 0 && (
                    <>
                        <Grid item>
                            <Chip
                                label={allChip.label}
                                clickable
                                onClick={() => {
                                    handleAllChipSelector();
                                }}
                                sx={{ fontWeight: selectedProducts.length === filterList.length + 1 ? 600 : 300 }}
                                color={selectedProducts.length === filterList.length + 1 ? 'primary' : 'default'}
                                variant={selectedProducts.length === filterList.length + 1 ? 'filled' : 'outlined'}
                                deleteIcon={selectedProducts.length === filterList.length + 1 ? <DoneIcon /> : null}
                            />
                        </Grid>
                    </>
                )}
                {filterList.map((chip) => {
                    const isSelected = selectedProducts.indexOf(chip.id) !== -1;
                    return (
                        <Grid item key={chip.id}>
                            <Chip
                                key={chip.id}
                                label={chip.title}
                                clickable
                                onClick={() => {
                                    handleCategorySelection(chip.id);
                                }}
                                sx={{ fontWeight: isSelected ? 600 : 300 }}
                                color={isSelected ? 'primary' : 'default'}
                                variant={isSelected ? 'filled' : 'outlined'}
                                deleteIcon={isSelected ? <DoneIcon /> : null}
                            />
                        </Grid>
                    );
                })}
            </Grid>

            <Divider sx={{ mt: 2, mb: 2 }} />
            <Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography mb={2}>
                        Total Products: <b>{subCategoryList.length}</b>
                    </Typography>
                    <Typography mb={2}>
                        Total Selected Items: <b>{selectedSubProducts.length}</b>
                    </Typography>
                </Box>

                <Grid container spacing={1} alignItems={'center'}>
                    {subCategoryList.map((chip) => {
                        const isSelected = selectedSubProducts.indexOf(chip.id) !== -1;
                        return (
                            <Grid item key={chip.id}>
                                <Chip
                                    key={chip.id}
                                    label={chip.title}
                                    clickable
                                    onClick={() => {
                                        handleSubProductChipClick(chip.id);
                                    }}
                                    sx={{ fontWeight: isSelected ? 600 : 300 }}
                                    color={isSelected ? 'primary' : 'default'}
                                    variant={isSelected ? 'filled' : 'outlined'}
                                    deleteIcon={isSelected ? <DoneIcon /> : null}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        </Box>
    );
}

export default FilterProducts;
