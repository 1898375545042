import { clearLogout, getUserToken, saveUserLogin, saveUserToken } from 'data/local-storage';
// import useFirebaseService from 'hooks/useFirebaseService';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isSessionExpiry, setIsSessionExpiry] = useState(false);
    const [user, setUser] = useState({});
    const [authToken, setAuthToken] = useState(null);
    // const { logoutFirebase } = useFirebaseService();

    const navigate = useNavigate();

    useEffect(() => {
        const isTokenLocal = authToken === null ? getUserToken() : authToken;
        setAuthToken(isTokenLocal);
        if (isTokenLocal) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, []);

    useEffect(() => {
        console.log('isAuthenticated', isAuthenticated);
        if (isAuthenticated) {
            navigate('/home', { replace: true });
        } else {
            navigate('/login', { replace: true });
        }
    }, [isAuthenticated]);

    const login = (userToken) => {
        setIsAuthenticated(true);
        setAuthToken(userToken);
        saveUserLogin(true);
        saveUserToken(userToken);
    };

    const logout = () => {
        clearLogout();
        setAuthToken(null);
        setIsAuthenticated(false);
        setIsSessionExpiry(false);
        setUser({});
        //logoutFirebase();
    };

    return (
        <AuthContext.Provider
            value={{ isAuthenticated, user, login, logout, isSessionExpiry, setIsSessionExpiry, authToken, setAuthToken }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default function useAuthContext() {
    return useContext(AuthContext);
}
