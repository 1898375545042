import React, { useEffect, useState } from 'react';
import { SettingsContextProvider } from '../provider/SettingsContext';
import DashboardMainLayout from 'layout/DashboardMainLayout';
import DynamicTableSelection from 'components/Common/DynamicTableSelection';
import DynamicAddEditSideDrawer from '../components/DynamicAddEditSideDrawer';
import config from 'utils/config/config';
import useAxios from 'hooks/useAxios';
import { ADD_UPDATE_CURRENCY, DELETE_CURRENCY, GET_CURRENCY } from 'utils/config/endpoints';
import { ToastMessageType } from 'utils/constants';
import { OK } from 'utils/config/response-codes';
import useFeedbackContext from 'contexts/FeedbackContext';

function CurrencyConfig() {
    const [showSideDrawer, setShowSideDrawer] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [dataValue, setDataValue] = useState(null);
    const [dataList, setDataList] = useState([]);
    const { axiosInstance } = useAxios();
    const [isLoading, setIsLoading] = useState(false);
    const { showToastMessage } = useFeedbackContext();
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [isTableErrorLoading, setIsTableErrorLoading] = useState(false);

    const headCells = [
        {
            id: 'id',
            headCellStyle: { display: 'none' },
            tableCellStyle: { display: 'none' },
            drawerFieldStyle: { display: 'none' }
        },
        {
            id: 'title',
            numeric: false,
            disablePadding: false,
            label: 'Title',
            mandatory: true,
            maxLength: config.maxCurrencyInputLength
        },
        {
            id: 'key',
            numeric: false,
            disablePadding: false,
            label: 'Currency',
            mandatory: true,
            maxLength: config.maxCurrencyCodeInputLength
        },
        {
            id: 'symbol',
            numeric: false,
            disablePadding: false,
            label: 'Symbol',
            mandatory: true,
            maxLength: config.maxCurrencySymbolInputLength
        }
    ];

    const tableConfig = {
        noDataMessage: 'No Currency Added',
        title: 'Currency',
        isAddButton: true,
        addButtonLabel: 'Add Currency',
        isActionEdit: true
    };

    useEffect(() => {
        fetch();
    }, []);

    const getValueFromId = (id) => {
        return dataList?.filter((item) => item.id === id);
    };

    const fetch = () => {
        showTableLoader();
        axiosInstance
            .get(GET_CURRENCY, {})
            .then((response) => {
                hideTableLoader();

                if (response.data.response_code === OK) {
                    setDataList(response.data.data);
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch(() => {
                setIsTableErrorLoading(true);
                hideTableLoader();
            });
    };

    const updateAddLogic = (newDataValue) => {
        axiosInstance
            .post(ADD_UPDATE_CURRENCY, newDataValue)
            .then((response) => {
                setIsLoading(false);

                if (response.data.response_code === OK) {
                    let data_values_temp = dataList;
                    const index = dataList.findIndex((item) => item.id === dataValue?.id);

                    if (index === -1) {
                        //ADD
                        data_values_temp.push(response.data.data);
                    } else {
                        //EDIT
                        // create a new array with the updated item
                        data_values_temp = [
                            ...dataList.slice(0, index), // items before the updated item
                            response.data.data,
                            ...dataList.slice(index + 1) // items after the updated item
                        ];
                    }

                    setDataList(data_values_temp);
                    setDataValue(null);
                    showToastMessage('Currency Updated', ToastMessageType.SUCCESS);
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const deleteLogic = (items) => {
        axiosInstance
            .delete(DELETE_CURRENCY, { data: items })
            .then((response) => {
                setIsLoading(false);

                if (response.data.response_code === OK) {
                    const newData = dataList.filter((item) => !items.includes(item.id));
                    setDataList(newData);
                    showToastMessage('Currency Deleted', ToastMessageType.SUCCESS);
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const showTableLoader = () => {
        setIsTableLoading(true);
        setIsTableErrorLoading(false);
    };

    const hideTableLoader = () => {
        setTimeout(() => {
            setIsTableLoading(false);
        }, 500);
    };

    return (
        <SettingsContextProvider>
            <DashboardMainLayout title={'Settings'}>
                <DynamicTableSelection
                    data={dataList}
                    headCells={headCells}
                    tableConfig={tableConfig}
                    onAddClickCallback={() => {
                        setDataValue(null);
                        setIsEdit(false);
                        setShowSideDrawer(true);
                    }}
                    onEditClickCallback={(id) => {
                        setDataValue(getValueFromId(id)[0]);
                        setIsEdit(true);
                        setShowSideDrawer(true);
                    }}
                    onDeleteClickCallback={(items) => {
                        deleteLogic(items);
                    }}
                    isLoading={isTableLoading}
                    onRefreshCallback={() => {
                        fetch();
                    }}
                    isError={isTableErrorLoading}
                />
            </DashboardMainLayout>
            <DynamicAddEditSideDrawer
                showDrawer={showSideDrawer}
                closeDrawer={() => {
                    setDataValue(null);
                    setShowSideDrawer(false);
                }}
                isEdit={isEdit}
                dataValue={dataValue}
                onAddCallback={(newDataDrawer) => {
                    updateAddLogic(newDataDrawer);
                    setShowSideDrawer(false);
                }}
                onEditCallback={(newDataDrawer) => {
                    updateAddLogic(newDataDrawer);
                    setShowSideDrawer(false);
                }}
                dataConfig={headCells}
                pageLabel="Currency"
            />
        </SettingsContextProvider>
    );
}

export default CurrencyConfig;
