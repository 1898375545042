import { LoadingButton } from '@mui/lab';
import { Chip, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import useAppConfigContext from 'pages/settings/provider/AppConfigContext';
import React, { useEffect, useState } from 'react';
import config from 'utils/config/config';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

export default function OtherTab() {
    const [selectedOtherTabData, setSelectedOtherTabData] = useState({
        graphDays: '',
        maxSellers: '',
        maxSellersTitle: '',
        sellerDataDays: '',
        containerSize: '',
        subscriptionUsers: [],
        otherCharges: '',
        insuranceCharge: '',
        alertDuration: ''
    });

    const [userTypeSelected, setUserTypeSelected] = useState([]);
    const [isButtonEnable, setIsButtonEnable] = useState(false);
    const { isOthersSaveLoading, selectedTabOtherAppConfig, createAdditionalConfig } = useAppConfigContext();

    const isMobileUi = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width:600px) and (max-width: 991px)');

    const handleFormEvent = (e) => {
        var id = e.target.id;
        var value = e.target.value;
        if (id === 'maxSellersTitle') {
            setSelectedOtherTabData({ ...selectedOtherTabData, [id]: value });
        } else {
            setSelectedOtherTabData({ ...selectedOtherTabData, [id]: value === '' ? '' : parseInt(value) });
        }
    };

    const defaultUserTypes = [
        { name: 'Buyer', value: config.serviceUsers.buyer },
        { name: 'Seller', value: config.serviceUsers.seller }
    ];

    useEffect(() => {
        if (selectedTabOtherAppConfig !== null) {
            const subValues = selectedTabOtherAppConfig.subscriptionUsers ?? [];

            setSelectedOtherTabData({
                graphDays: selectedTabOtherAppConfig.graphDays ?? '',
                maxSellers: selectedTabOtherAppConfig.maxSellers ?? '',
                maxSellersTitle: selectedTabOtherAppConfig.maxSellersTitle ?? '',
                sellerDataDays: selectedTabOtherAppConfig.sellerDataDays ?? '',
                containerSize: selectedTabOtherAppConfig.containerSize ?? '',
                subscriptionUsers: subValues,
                otherCharges: selectedTabOtherAppConfig.otherCharges ?? '',
                insuranceCharge: selectedTabOtherAppConfig.insuranceCharge ?? '',
                alertDuration: selectedTabOtherAppConfig.alertDuration ?? ''
            });

            const tempValues = subValues
                .map((item) => defaultUserTypes.filter((userVal) => userVal.value === item))
                .flat()
                .map((userVal) => {
                    return userVal.name;
                });

            setUserTypeSelected(tempValues);
        } else {
            setUserTypeSelected([]);
        }
    }, [selectedTabOtherAppConfig]);

    useEffect(() => {
        if (selectedOtherTabData.graphDays.toString().length > 0) {
            setIsButtonEnable(true);
        } else {
            setIsButtonEnable(false);
        }
    }, [selectedOtherTabData]);

    const handleDeleteUserFromList = (label) => {
        setUserTypeSelected((prevList) => {
            const newList = prevList.filter((value) => value !== label);
            return newList;
        });
    };

    const handleChange = (event) => {
        const value = event.target.value;

        const defaultUserType = defaultUserTypes.find((type) => type.value === value);
        const name = defaultUserType ? defaultUserType.name : '';
        // check if the value already exists in the array
        if (!userTypeSelected.includes(name)) {
            setUserTypeSelected([...userTypeSelected, name]);
        }
    };
    const addAdditionalData = () => {
        const tempValues = userTypeSelected
            .map((item) => defaultUserTypes.filter((userVal) => userVal.name === item))
            .flat()
            .map((userVal) => userVal.value);
        createAdditionalConfig({ ...selectedOtherTabData, subscriptionUsers: tempValues });
    };

    return (
        <Grid container spacing={3} sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={12} md={6}>
                <Typography sx={{ color: 'grey' }}>Graph Days</Typography>
                <TextField
                    id="graphDays"
                    variant="outlined"
                    type="number"
                    sx={{ mt: 2 }}
                    fullWidth
                    value={`${selectedOtherTabData.graphDays}`}
                    placeholder="Enter Graph Days"
                    onChange={handleFormEvent}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography sx={{ color: 'grey' }}>Max Sellers</Typography>
                <TextField
                    id="maxSellers"
                    variant="outlined"
                    type="number"
                    margin="dense"
                    sx={{ mt: 2 }}
                    fullWidth
                    value={selectedOtherTabData.maxSellers}
                    placeholder="Enter Max Sellers"
                    onChange={handleFormEvent}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography sx={{ color: 'grey' }}>Max Seller Title</Typography>
                <TextField
                    id="maxSellersTitle"
                    variant="outlined"
                    margin="dense"
                    sx={{ mt: 2 }}
                    fullWidth
                    value={selectedOtherTabData.maxSellersTitle}
                    placeholder="Enter Max Seller Title"
                    onChange={handleFormEvent}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography sx={{ color: 'grey' }}> Seller Days Count</Typography>
                <TextField
                    id="sellerDataDays"
                    variant="outlined"
                    type="number"
                    margin="dense"
                    sx={{ mt: 2 }}
                    fullWidth
                    value={`${selectedOtherTabData.sellerDataDays}`}
                    placeholder="Enter Seller Days Count"
                    onChange={handleFormEvent}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography sx={{ color: 'grey' }}>Container Size</Typography>
                <TextField
                    id="containerSize"
                    variant="outlined"
                    type="number"
                    margin="dense"
                    sx={{ mt: 2 }}
                    fullWidth
                    value={`${selectedOtherTabData.containerSize}`}
                    placeholder="Enter Container Size"
                    onChange={handleFormEvent}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography sx={{ color: 'grey' }}>Alert Duration</Typography>
                <TextField
                    id="alertDuration"
                    variant="outlined"
                    type="number"
                    margin="dense"
                    sx={{ mt: 2 }}
                    fullWidth
                    value={`${selectedOtherTabData.alertDuration}`}
                    placeholder="Enter Alert Duration"
                    onChange={handleFormEvent}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <Typography variant="h4">Charges</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography sx={{ color: 'grey' }}> Insurance Charges</Typography>
                <TextField
                    id="insuranceCharge"
                    variant="outlined"
                    type="number"
                    margin="dense"
                    sx={{ mt: 2 }}
                    fullWidth
                    value={`${selectedOtherTabData.insuranceCharge}`}
                    placeholder="Enter Insurance Charges"
                    onChange={handleFormEvent}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography sx={{ color: 'grey' }}>Other Charges</Typography>
                <TextField
                    id="otherCharges"
                    variant="outlined"
                    type="number"
                    margin="dense"
                    sx={{ mt: 2 }}
                    fullWidth
                    value={`${selectedOtherTabData.otherCharges}`}
                    placeholder="Enter Other Charges"
                    onChange={handleFormEvent}
                />
            </Grid>

            <Grid item xs={12} md={12}>
                <Typography variant="h4">Active Subscription Users</Typography>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                    <FormControl>
                        <InputLabel>Select User</InputLabel>
                        <Select
                            labelId="subscriptionUsers"
                            id="subscriptionUsers"
                            placeholder="Select User"
                            onChange={handleChange}
                            label="Select User"
                            autoWidth
                            value={selectedOtherTabData.userSelect || ''}
                            variant="outlined"
                            style={{ width: 200, marginBottom: 15 }}
                            MenuProps={MenuProps}
                        >
                            <MenuItem disabled value="">
                                <em>Select User</em>
                            </MenuItem>
                            {defaultUserTypes.map((d) => (
                                <MenuItem key={d.name} value={d.value}>
                                    {d.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Stack direction="row" spacing={1}>
                        {userTypeSelected.map((data) => {
                            return <Chip key={data} label={data} onDelete={() => handleDeleteUserFromList(data)} />;
                        })}
                    </Stack>
                </div>
            </Grid>

            <Grid item xs={12} md={12} mt={2} justifyContent={isMobileUi || isTablet ? 'center' : 'end'} display={'flex'}>
                <LoadingButton
                    loading={isOthersSaveLoading}
                    disabled={!isButtonEnable}
                    variant="contained"
                    color="secondary"
                    onClick={addAdditionalData}
                    sx={{ color: '#ffffff' }}
                >
                    Submit
                </LoadingButton>
            </Grid>
        </Grid>
    );
}
