import React, { useState, useEffect } from 'react';
import { SettingsContextProvider } from '../provider/SettingsContext';
import DashboardMainLayout from 'layout/DashboardMainLayout';
import DynamicTableSelection from 'components/Common/DynamicTableSelection';
import PackagingAddEditSideDrawer from '../components/PackagingAddEditSideDrawer';
import { Button } from '@mui/material';
import DeleteDialogConfirmation from 'components/Dialogs/DeleteDialogConfirmation';
import useAxios from 'hooks/useAxios';
import { ADD_UPDATE_PACKAGING, DELETE_PACKAGING, GET_PACKAGING } from 'utils/config/endpoints';
import { ToastMessageType } from 'utils/constants';
import { OK } from 'utils/config/response-codes';
import useFeedbackContext from 'contexts/FeedbackContext';

function PackagingConfig() {
    const [packaging, setPackaging] = useState([]);
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [showSideDrawer, setShowSideDrawer] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isDeleteDialogShow, setDeleteDialogShow] = React.useState(false);
    const { axiosInstance } = useAxios();
    const { showToastMessage } = useFeedbackContext();
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [isTableErrorLoading, setIsTableErrorLoading] = useState(false);

    const headCells = [
        {
            id: 'id',
            numeric: true,
            disablePadding: false,
            label: 'ID',
            mandatory: true
        },
        {
            id: 'title',
            numeric: false,
            disablePadding: false,
            label: 'Name',
            mandatory: true
        },
        {
            id: 'kgsWeightItem',
            numeric: false,
            disablePadding: false,
            label: 'Weight (kg)',
            mandatory: true,
            export_render: (value) => (
                <>
                    {value.length > 0 ? (
                        value.map((item) => (
                            <div key={`${item.weight}`}>
                                {item.weight} kg | <b>$ {item.price}</b>
                            </div>
                        ))
                    ) : (
                        <div>-</div>
                    )}
                </>
            )
        },
        {
            id: 'lbsWeightItem',
            numeric: false,
            disablePadding: false,
            label: 'Weight (lbs)',
            mandatory: true,
            export_render: (value) => (
                <>
                    {value.length > 0 ? (
                        value.map((item) => (
                            <div key={`${item.weight}`}>
                                {item.weight} lbs | <b>$ {item.price}</b>
                            </div>
                        ))
                    ) : (
                        <div>-</div>
                    )}
                </>
            )
        }
    ];

    const tableConfig = {
        noDataMessage: 'No Packaging Added',
        title: 'Packaging Config',
        isAddButton: true,
        addButtonLabel: 'Add Packaging',
        isActionEdit: true
    };

    useEffect(() => {
        fetch();
    }, []);

    const fetch = () => {
        showTableLoader();
        setIsTableErrorLoading(false);
        axiosInstance
            .get(GET_PACKAGING)
            .then((response) => {
                hideTableLoader();

                if (response.data.response_code === OK) {
                    setPackaging(response.data.data);
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch(() => {
                setIsTableErrorLoading(true);
                hideTableLoader();
            });
    };

    const updateAddLogic = (newDataValue) => {
        console.log(newDataValue);
        axiosInstance
            .post(ADD_UPDATE_PACKAGING, newDataValue)
            .then((response) => {
                setIsLoading(false);

                if (response.data.response_code === OK) {
                    let data_values_temp = packaging;
                    const index = packaging.findIndex((item) => item.id === selectedItem?.id);

                    if (index === -1) {
                        //ADD
                        data_values_temp.push(response.data.data);
                    } else {
                        //EDIT
                        // create a new array with the updated item
                        data_values_temp = [
                            ...packaging.slice(0, index), // items before the updated item
                            response.data.data,
                            ...packaging.slice(index + 1) // items after the updated item
                        ];
                    }

                    setPackaging(data_values_temp);
                    setSelectedItem(null);

                    showToastMessage('Packaging Updated', ToastMessageType.SUCCESS);
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const deleteLogic = (items) => {
        axiosInstance
            .delete(DELETE_PACKAGING, { data: items })
            .then((response) => {
                setIsLoading(false);

                console.log('response', response);
                if (response.data.response_code === OK) {
                    const newData = packaging.filter((item) => !items.includes(item.id));
                    setPackaging(newData);
                    showToastMessage('Packaging Deleted', ToastMessageType.SUCCESS);
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const getValueFromId = (id) => {
        return packaging?.filter((item) => item.id === id);
    };

    const showTableLoader = () => {
        setIsTableLoading(true);
    };

    const hideTableLoader = () => {
        setTimeout(() => {
            setIsTableLoading(false);
        }, 500);
    };

    return (
        <SettingsContextProvider>
            <DashboardMainLayout
                isBackButton={showSideDrawer}
                navigateBack={() => {
                    setSelectedItem(null);
                    setIsEdit(false);
                    setShowSideDrawer(false);
                }}
                secondary={
                    isEdit ? (
                        <Button
                            onClick={() => {
                                setDeleteDialogShow(true);
                            }}
                            color="error"
                            variant="contained"
                        >
                            Delete
                        </Button>
                    ) : null
                }
                title={showSideDrawer ? (isEdit ? 'Edit Packaging' : 'Add Packaging') : 'Settings'}
            >
                {showSideDrawer ? (
                    <PackagingAddEditSideDrawer
                        showDrawer={showSideDrawer}
                        closeDrawer={() => {
                            setSelectedItem(null);
                            setShowSideDrawer(false);
                        }}
                        config={headCells}
                        isEdit={isEdit}
                        dataList={selectedItem}
                        onAddCallback={(dataDrawer) => {
                            updateAddLogic(dataDrawer);
                            setShowSideDrawer(false);
                        }}
                        onEditCallback={(dataDrawer) => {
                            setIsEdit(false);
                            updateAddLogic(dataDrawer);
                            setShowSideDrawer(false);
                        }}
                    />
                ) : (
                    <DynamicTableSelection
                        data={packaging}
                        headCells={headCells}
                        tableConfig={tableConfig}
                        onAddClickCallback={() => {
                            setSelectedItem(null);
                            setIsEdit(false);
                            setShowSideDrawer(true);
                        }}
                        onEditClickCallback={(id) => {
                            setSelectedItem(getValueFromId(id)[0]);
                            setIsEdit(true);
                            setShowSideDrawer(true);
                        }}
                        onDeleteClickCallback={(items) => {
                            deleteLogic(items);
                        }}
                        isLoading={isTableLoading}
                        onRefreshCallback={() => {
                            fetch();
                        }}
                        isError={isTableErrorLoading}
                    />
                )}
            </DashboardMainLayout>

            <DeleteDialogConfirmation
                deleteCallback={() => {
                    setIsEdit(false);
                    setShowSideDrawer(false);
                    deleteLogic([selectedItem.id]);
                    setDeleteDialogShow(false);
                }}
                cancelCallback={() => {
                    setDeleteDialogShow(false);
                }}
                msg="Do you want to delete this Packaging?"
                showDeleteConfirmation={isDeleteDialogShow}
            />
        </SettingsContextProvider>
    );
}

export default PackagingConfig;
