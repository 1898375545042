import { Checkbox, Grid, Switch, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import CustomDialog from 'components/Common/CustomDialog';
import config from 'utils/config/config';

function NavigationMenuAddEditDrawer({
    window,
    showDrawer,
    closeDrawer,
    isLoading = false,
    isEdit = false,
    onAddCallback,
    onEditCallback,
    data
}) {
    const [isButtonEnable, setButtonEnable] = useState(false);
    const defaultFormValue = {
        title: '',
        description: '',
        visibility: false,
        lower_version: null,
        upper_version: null,
        lower_build_number: null,
        upper_build_number: null,
        hyperlink: '',
        serviceType: [],
        order_seq: null,
        id: null
    };

    const [dataDrawer, setDataDrawer] = useState(defaultFormValue);
    const [serviceTypes, setServiceTypes] = useState(config.serviceDefaultTypeList);

    useEffect(() => {
        if (data === null || data === undefined) {
            resetForm();
        } else {
            setDataDrawer({
                title: data.title ?? '',
                description: data.description ?? '',
                visibility: data.visibility ?? false,
                lower_version: data.lower_version ?? '',
                upper_version: data.upper_version ?? '',
                lower_build_number: data.lower_build_number ?? '',
                upper_build_number: data.upper_build_number ?? '',
                hyperlink: data.hyperlink ?? '',
                serviceType: data.serviceType ?? [],
                ...data
            });
            const tempServices = [];

            if (data.serviceType ?? [].length > 0) {
                for (const service of config.serviceDefaultTypeList) {
                    const matchingService = data.serviceType.find((item) => item === service.id);
                    const updatedService = { ...service, value: Boolean(matchingService) };
                    tempServices.push(updatedService);
                }
            }
            setServiceTypes(tempServices);
        }
    }, [data]);

    useEffect(() => {
        if (dataDrawer.title?.length > 0 && dataDrawer.description?.length > 0) {
            setButtonEnable(true);
        } else {
            setButtonEnable(false);
        }
    }, [dataDrawer]);

    useEffect(() => {
        const selectedIds = serviceTypes.filter((item) => item.value === true).map((item) => item.id);
        setDataDrawer({ ...dataDrawer, serviceType: selectedIds });
    }, [serviceTypes]);

    const handleInputFiled = (event) => {
        const inputName = event.target.name;
        const inputValue = event.target.value;
        if (inputName === 'title') {
            // if (inputValue.length < config.maxCurrencyInputLength) {
            setDataDrawer({ ...dataDrawer, title: inputValue });
            // }
        } else if (inputName === 'description') {
            // if (inputValue.length <= config.maxSeaportPriceInputLength) {
            setDataDrawer({ ...dataDrawer, description: inputValue });
            // }
        } else {
            if (
                inputName === 'lower_version' ||
                inputName === 'upper_version' ||
                inputName === 'lower_build_number' ||
                inputName === 'upper_build_number'
            ) {
                if (inputValue.length <= config.maxBuildVersion) {
                    setDataDrawer({ ...dataDrawer, [inputName]: inputValue });
                }
            } else {
                setDataDrawer({ ...dataDrawer, [inputName]: inputValue });
            }
        }
    };

    const resetForm = () => {
        setDataDrawer(defaultFormValue);
    };

    const closeDialog = () => {
        resetForm();
        closeDrawer();
    };

    console.log('data', data);

    const NavigationMenuForm = () => {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <TextField name="title" label="Enter Title" value={dataDrawer.title ?? ''} onChange={handleInputFiled} fullWidth />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        name="description"
                        label="Enter Description"
                        value={dataDrawer.description ?? ''}
                        onChange={handleInputFiled}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <Typography variant="h4">Version</Typography>
                    <Grid container spacing={1} mt={1}>
                        <Grid item xs={6}>
                            <TextField
                                type="number"
                                name="upper_version"
                                label="Enter Upper Version"
                                value={dataDrawer.upper_version ?? ''}
                                onChange={handleInputFiled}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                type="number"
                                name="lower_version"
                                label="Enter Lower Version"
                                value={dataDrawer.lower_version ?? ''}
                                onChange={handleInputFiled}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Typography variant="h4">Build Number</Typography>
                    <Grid container spacing={1} mt={1}>
                        <Grid item xs={6}>
                            <TextField
                                type="number"
                                name="upper_build_number"
                                label="Enter Upper Number"
                                value={dataDrawer.upper_build_number ?? ''}
                                onChange={handleInputFiled}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                type="number"
                                name="lower_build_number"
                                label="Enter Lower Number"
                                value={dataDrawer.lower_build_number ?? ''}
                                onChange={handleInputFiled}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography sx={{ flex: 1 }} variant="h4">
                            Visibility
                        </Typography>
                        <Switch
                            checked={dataDrawer.visibility}
                            onChange={(e) => {
                                console.log(e.target.checked);
                                setDataDrawer({ ...dataDrawer, visibility: e.target.checked });
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography variant="h4" mb={2}>
                        HyperLink
                    </Typography>

                    <TextField
                        name="hyperlink"
                        label="Enter HyperLink"
                        value={dataDrawer.hyperlink ?? ''}
                        onChange={handleInputFiled}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <Typography variant="h4" mb={1}>
                        Select Supported Service Type Users
                    </Typography>
                    <Grid container>
                        {serviceTypes.map((item, index) => {
                            return (
                                <Grid key={item.id} item>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Typography>{item.label}</Typography>
                                        <Checkbox
                                            checked={item.value}
                                            onChange={() => {
                                                const updatedServiceTypes = [...serviceTypes];
                                                updatedServiceTypes[index] = {
                                                    ...updatedServiceTypes[index],
                                                    value: !updatedServiceTypes[index].value
                                                };
                                                setServiceTypes(updatedServiceTypes);
                                            }}
                                        />
                                    </div>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <CustomDialog
            window={window}
            buttonLabel={isEdit ? 'Edit Item' : 'Add Item'}
            dialogTitle={isEdit ? 'Edit Menu Item' : 'Add  Menu Item'}
            showDrawer={showDrawer}
            isLoading={isLoading}
            onClickCallback={() => {
                isEdit ? onEditCallback(dataDrawer) : onAddCallback(dataDrawer);
            }}
            isButtonEnable={isButtonEnable}
            closeDrawer={closeDialog}
            customFormUi={NavigationMenuForm()}
        />
    );
}

export default NavigationMenuAddEditDrawer;
