import React from 'react';
import { Box, Button, Checkbox, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import Iframe from 'react-iframe';
import { isValidUrl } from 'utils/miscellaneous';
import PhotoPlaceholder from 'assets/images/image_placeholder.svg';
import { firebaseStorageUrl } from 'utils/constants';

function AppBannerForm({ bannerData, handleFormEvent, serviceTypes, setServiceTypes }) {
    const [imagePath, setImagePath] = React.useState(PhotoPlaceholder);

    React.useEffect(() => {
        if (isValidUrl(bannerData?.banner_image_url)) {
            setImagePath(bannerData?.banner_image_url);
        } else {
            setImagePath(PhotoPlaceholder);
        }
    }, [bannerData.banner_image_url]);

    React.useEffect(() => {
        fetchImage(imagePath);
    }, [imagePath]);

    React.useEffect(() => {
        if (
            (bannerData.image_blob === null || bannerData.image_blob === undefined) &&
            (bannerData.banner_image_url === null || bannerData.banner_image_url === undefined)
        ) {
            setImagePath(PhotoPlaceholder);
        }
    }, [bannerData.image_blob]);

    const fetchImage = async (url) => {
        if (url !== PhotoPlaceholder) {
            if (!url.includes(firebaseStorageUrl)) {
                try {
                    const response = await fetch(url);
                    const blob = await response.blob();
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        const dataUrl = reader.result;
                        bannerData.image_blob = dataUrl;
                    };
                } catch (e) {
                    console.log(e);
                }
            }
        } else {
            bannerData.image_blob = null;
        }
    };

    return (
        <Grid container spacing={4} sx={{ mb: 2, width: '100%' }}>
            <Grid item xs={12} md={12}>
                <Typography sx={{ color: 'grey' }}>Title</Typography>
                <TextField
                    id="title"
                    variant="outlined"
                    sx={{ mt: 2 }}
                    fullWidth
                    placeholder="Enter Title"
                    value={bannerData?.title === null ? '' : bannerData?.title}
                    onChange={handleFormEvent}
                />
            </Grid>

            <Grid item xs={12} md={12}>
                <Typography sx={{ color: 'grey' }}>Description</Typography>
                <TextField
                    id="description"
                    variant="outlined"
                    multiline
                    rows={10}
                    margin="dense"
                    sx={{ mt: 2 }}
                    fullWidth
                    placeholder="Enter Description"
                    value={bannerData?.description === null ? '' : bannerData?.description}
                    onChange={handleFormEvent}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <Typography sx={{ color: 'grey', mb: 2 }}>Hero image </Typography>

                <Box sx={{ p: 2, backgroundColor: '#F3F3F3', width: 'fit-content', border: 'dashed 3px #B4B4B4' }}>
                    <img alt="hero-article-img" src={imagePath} height={100} />
                </Box>
                <TextField
                    id="banner_image_url"
                    variant="outlined"
                    sx={{ mt: 2, mb: 1 }}
                    fullWidth
                    placeholder="Example: www.yourapp/sampleimage.jpeg"
                    value={bannerData?.banner_image_url === null ? '' : bannerData?.banner_image_url}
                    onChange={handleFormEvent}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography sx={{ mt: 2, mb: 2 }}>OR</Typography>
                    <Button variant="contained" component="label">
                        Upload
                        <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={(e) => {
                                const files = e.target.files;
                                console.log(files[0]);
                                if (files.length > 0) {
                                    const selectedFile = files[0];
                                    const objectUrl = URL.createObjectURL(selectedFile);
                                    console.log('url', objectUrl);
                                    setImagePath(objectUrl);
                                }
                            }}
                        />
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={12} md={12}>
                <Typography sx={{ color: 'grey' }}>Hyperlink</Typography>
                <TextField
                    id="hyperlink"
                    variant="outlined"
                    sx={{ mt: 2, mb: 1 }}
                    fullWidth
                    placeholder="Example: www.yourapp/blog"
                    value={bannerData?.hyperlink === null ? '' : bannerData?.hyperlink}
                    onChange={handleFormEvent}
                />

                {bannerData?.hyperlink?.length > 0 && !isValidUrl(bannerData?.hyperlink) && (
                    <FormHelperText error id="invalid-image">
                        Please enter a valid URL starting with https://
                    </FormHelperText>
                )}
                {isValidUrl(bannerData?.uri) && <Iframe height="250px" width="350px" src={bannerData?.hyperlink} />}
            </Grid>

            <Grid item xs={12} md={12}>
                <Typography sx={{ color: 'grey', mb: 2 }}> Select Supported Service Type Users</Typography>

                <Grid container>
                    {serviceTypes.map((item, index) => {
                        return (
                            <Grid key={item.id} item>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Typography>{item.label}</Typography>
                                    <Checkbox
                                        checked={item.value}
                                        onChange={() => {
                                            const updatedServiceTypes = [...serviceTypes];
                                            updatedServiceTypes[index] = {
                                                ...updatedServiceTypes[index],
                                                value: !updatedServiceTypes[index].value
                                            };
                                            setServiceTypes(updatedServiceTypes);
                                        }}
                                    />
                                </div>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default AppBannerForm;
