import { FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import config from 'utils/config/config';
import { isValidUrl } from 'utils/miscellaneous';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

function StepperNotificationForm({ data, handleFormEvent }) {
    const [isValid, setIsValid] = useState(true);
    const [actionList, setActionList] = useState([]);

    React.useEffect(() => {
        setActionList(config.notificationActionList);
    }, []);

    function handleImageError() {
        setIsValid(false);
    }

    React.useEffect(() => {
        setIsValid(isValidUrl(data.image));
    }, [data.image]);

    return (
        <Grid container spacing={4} sx={{ mt: 2, mb: 2 }}>
            <Grid item xs={12} md={12}>
                <Typography sx={{ color: 'grey' }}>Notification Title</Typography>
                <TextField
                    id="title"
                    variant="outlined"
                    sx={{ mt: 2 }}
                    fullWidth
                    placeholder="Enter Notification"
                    value={data?.title === null ? '' : data?.title}
                    onChange={handleFormEvent}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <Typography sx={{ color: 'grey' }}>Notification text</Typography>
                <TextField
                    id="description"
                    variant="outlined"
                    multiline
                    rows={4}
                    margin="dense"
                    sx={{ mt: 2 }}
                    fullWidth
                    placeholder="Enter Notification text"
                    value={data?.description === null ? '' : data?.description}
                    onChange={handleFormEvent}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <Typography sx={{ color: 'grey' }}>Notification image (optional)</Typography>
                <TextField
                    id="image"
                    variant="outlined"
                    sx={{ mt: 2, mb: 1 }}
                    fullWidth
                    placeholder="Example: www.yourapp/sampleimage.jpeg"
                    value={data?.image === null ? '' : data?.image}
                    onChange={handleFormEvent}
                />
                {isValid ? (
                    <img src={data.image} alt="notification-img" onError={handleImageError} height={100} width={100} />
                ) : (
                    data.image?.length > 0 && (
                        <FormHelperText error id="invalid-image">
                            Please enter a valid image URL starting with https://
                        </FormHelperText>
                    )
                )}
            </Grid>
            <Grid item xs={12} md={12}>
                <Typography sx={{ color: 'grey' }}>Notification Actions (optional)</Typography>
                <Select
                    labelId="select-action"
                    name="action-notify"
                    id="action-notify"
                    sx={{ mt: 2, mb: 1, width: '100%' }}
                    displayEmpty
                    value={data?.selectedAction}
                    label="Select Action"
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                    onChange={handleFormEvent}
                    renderValue={(selected) => {
                        if (!selected) {
                            return <em>Select Action</em>;
                        }
                        return selected.name;
                    }}
                >
                    <MenuItem disabled value="">
                        <em>Select Action</em>
                    </MenuItem>
                    {actionList.map((option) => (
                        <MenuItem key={option.id} value={option}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>

                <Grid item xs={12} md={12}>
                    <Typography sx={{ color: 'grey', mt: 2 }}>Action Link (optional)</Typography>
                    <TextField
                        id="uri"
                        variant="outlined"
                        sx={{ mt: 2, mb: 1 }}
                        fullWidth
                        placeholder="Example: www.yourapp.sample.com"
                        value={data?.uri === null ? '' : data?.uri}
                        onChange={handleFormEvent}
                    />
                    {/* {isValid ? (
                        <img src={data.image} alt="notification-link" onError={handleImageError} height={100} width={100} />
                    ) : (
                        data.image?.length > 0 && (
                            <FormHelperText error id="invalid-image">
                                Please enter a valid URL starting with https://
                            </FormHelperText>
                        )
                    )} */}
                </Grid>

                <Grid item xs={12} md={12}>
                    <Typography sx={{ color: 'grey', mt: 2 }}>Action Text (optional)</Typography>
                    <TextField
                        id="action_text"
                        variant="outlined"
                        sx={{ mt: 2, mb: 1 }}
                        fullWidth
                        placeholder="Know More"
                        value={data?.action_text === null ? '' : data?.action_text}
                        onChange={handleFormEvent}
                    />
                    {/* {isValid ? (
                        <img src={data.image} alt="notification-link" onError={handleImageError} height={100} width={100} />
                    ) : (
                        data.image?.length > 0 && (
                            <FormHelperText error id="invalid-image">
                                Please enter a valid URL starting with https://
                            </FormHelperText>
                        )
                    )} */}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default StepperNotificationForm;
