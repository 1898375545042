import { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import config from 'utils/config/config';
import useAuthContext from 'contexts/AuthContext';
import { FORBIDDEN, UNAUTHORIZED } from 'utils/config/response-codes';

const useAxios = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState(null);
    const { authToken, setIsSessionExpiry } = useAuthContext();
    const controller = new AbortController();
    const endPointUrl = config.devBaseUrl;

    useEffect(() => {
        return () => {
            if (controller !== null) {
                controller.abort();
            }
        };
    }, []);

    const axiosInstance = axios.create({
        baseURL: endPointUrl,
        signal: controller.signal,
        timeout: config.timeout
    });

    axiosInstance.interceptors.request.use(
        async (req) => {
            setLoading(true);
            if (authToken !== null) {
                req.headers.Authorization = `Bearer ${authToken}`;
            }
            req.headers['Content-Type'] = 'application/json';
            req.headers['Accept-Encoding'] = 'gzip, deflate, br';
            req.headers['Sec-Fetch-Mode'] = 'navigate';

            req.params = {
                ...req.params
            };
            return req;
        },
        (requestError) => {
            setLoading(false);
            setError(requestError);
            return Promise.reject(requestError);
        }
    );

    axiosInstance.interceptors.response.use(
        function (res) {
            setLoading(false);
            setResponse(res.data);
            if (res.data.response_code === FORBIDDEN) {
                setIsSessionExpiry(true);
            } else {
                setIsSessionExpiry(false);
            }
            return res;
        },
        function (error) {
            setLoading(false);
            setError(error);
            const { code } = error;
            if (code !== AxiosError.ERR_NETWORK && code !== AxiosError.ERR_CANCELED) {
                if (error?.response?.status === FORBIDDEN) {
                    setIsSessionExpiry(true);
                } else {
                    setIsSessionExpiry(false);
                }
            }
            return Promise.reject(error);
        }
    );

    return { axiosInstance, error, loading, response };
};

export default useAxios;
