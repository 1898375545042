import NetworkRetry from 'components/Dialogs/NetworkRetry';
import MainCard from 'components/cards/MainCard';
import React from 'react';

export default function DashboardMainLayout({
    children,
    title,
    secondary,
    showRetryNetwork,
    retryCallback,
    isBackButton = false,
    navigateBack,
    contentSX
}) {
    return (
        <MainCard title={title} secondary={secondary} isBackButton={isBackButton} navigateBack={navigateBack} contentSX={contentSX}>
            {children}
            <NetworkRetry showRetryDialog={showRetryNetwork} retryCallback={retryCallback} />
        </MainCard>
    );
}
