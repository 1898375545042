import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

import useAuthContext from 'contexts/AuthContext';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { isAuthenticated } = useAuthContext();
    const routes = [AuthenticationRoutes, MainRoutes];
    const routerAccepted = isAuthenticated ? [routes[1]] : [routes[0]];
    return useRoutes(routerAccepted);
}
