// assets
import { IconSettings } from '@tabler/icons';

// constant
const icons = { IconSettings };

// ==============================|| Settings MENU ITEMS ||============================== //

const settings = {
    id: 'settings',
    type: 'group',
    title: 'Settings',
    children: [
        {
            id: 'admin-settings',
            title: 'Settings',
            type: 'collapse',
            icon: icons.IconSettings,
            breadcrumbs: false,
            children: [
                {
                    id: 'app-config-settings',
                    title: 'App Config',
                    type: 'item',
                    url: '/settings/app-config',
                    breadcrumbs: false
                },
                {
                    id: 'currency-settings',
                    title: 'Currency Config',
                    type: 'item',
                    url: '/settings/currency-config',
                    breadcrumbs: false
                },
                {
                    id: 'seaport-settings',
                    title: 'SeaPort Config',
                    type: 'item',
                    url: '/settings/seaport-config',
                    breadcrumbs: false
                },
                {
                    id: 'packaging-settings',
                    title: 'Packaging',
                    type: 'item',
                    url: '/settings/packaging-config',
                    breadcrumbs: false
                },
                {
                    id: 'faqs-settings',
                    title: 'Faqs',
                    type: 'item',
                    url: '/settings/faqs-config',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default settings;
