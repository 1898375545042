// assets
import { IconBox } from '@tabler/icons';

// constant
const icons = { IconBox };

// ==============================|| PRODUCT MANAGEMENT MENU ITEMS ||============================== //

const productManagement = {
    id: 'product-management-group',
    type: 'group',
    title: '',
    children: [
        {
            id: 'product-collapsable',
            title: 'Product Management',
            type: 'collapse',
            icon: icons.IconBox,
            breadcrumbs: false,
            children: [
                {
                    id: 'product-management',
                    title: 'Products',
                    type: 'item',
                    url: '/product-management/products',
                    breadcrumbs: false,
                    children: [
                        {
                            id: 'Product-Details',
                            title: 'ProductDetails',
                            type: 'item',
                            url: '/product-details',
                            breadcrumbs: false
                        }
                    ]
                },
                {
                    id: 'quotation-management',
                    title: 'Quotation',
                    type: 'item',
                    url: '/product-management/quotation',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default productManagement;
