// assets
import { IconBasket } from '@tabler/icons';

// constant
const icons = { IconBasket };

// ==============================||Orders MENU ITEMS ||============================== //

const orders = {
    id: 'orders-group',
    type: 'group',
    title: 'Orders',
    children: [
        {
            id: 'orders',
            title: 'Orders',
            type: 'item',
            url: '/orders',
            icon: icons.IconBasket,
            breadcrumbs: false
        }
    ]
};

export default orders;
