import React from 'react';
import { Box, Button, Divider, FormHelperText, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { ArticleType, ArticleTypes, firebaseStorageUrl } from 'utils/constants';
import Iframe from 'react-iframe';
import { isValidUrl } from 'utils/miscellaneous';
import PhotoPlaceholder from 'assets/images/image_placeholder.svg';
function ArticleForm({ articleData, articleType, handleArticleChange, handleFormEvent }) {
    const [imagePath, setImagePath] = React.useState(PhotoPlaceholder);

    React.useEffect(() => {
        if (isValidUrl(articleData?.image)) {
            setImagePath(articleData?.image);
        } else {
            setImagePath(PhotoPlaceholder);
        }
    }, [articleData.image]);

    React.useEffect(() => {
        fetchImage(imagePath);
    }, [imagePath]);

    React.useEffect(() => {
        if (
            (articleData.image_blob === null || articleData.image_blob === undefined) &&
            (articleData.image === null || articleData.image === undefined)
        ) {
            setImagePath(PhotoPlaceholder);
        }
    }, [articleData.image_blob]);

    const fetchImage = async (url) => {
        if (url !== PhotoPlaceholder) {
            if (!url.includes(firebaseStorageUrl)) {
                try {
                    const response = await fetch(url);
                    const blob = await response.blob();
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        const dataUrl = reader.result;
                        articleData.image_blob = dataUrl;
                    };
                } catch (e) {
                    console.log(e);
                }
            }
        } else {
            articleData.image_blob = null;
        }
    };

    return (
        <Grid container spacing={4} sx={{ mb: 2, width: '100%' }}>
            <Grid item xs={12} md={12}>
                <Typography sx={{ color: 'grey', mb: 2 }}>Select Article</Typography>
                <Select
                    fullWidth
                    labelId="selector-article"
                    id="select-article"
                    value={articleType}
                    label="Select Article"
                    onChange={handleArticleChange}
                >
                    {ArticleTypes.map((data) => {
                        return (
                            <MenuItem key={data} id={data} value={data}>
                                {data}
                            </MenuItem>
                        );
                    })}
                </Select>
                <Divider sx={{ mt: 4 }} />
            </Grid>

            <Grid item xs={12} md={12}>
                <Typography sx={{ color: 'grey' }}>Article Title</Typography>
                <TextField
                    id="title"
                    variant="outlined"
                    sx={{ mt: 2 }}
                    fullWidth
                    placeholder="Enter Article Title"
                    value={articleData?.title === null ? '' : articleData?.title}
                    onChange={handleFormEvent}
                />
            </Grid>

            <Grid item xs={12} md={12}>
                <Typography sx={{ color: 'grey' }}>Article Title</Typography>
                <TextField
                    id="date"
                    variant="outlined"
                    sx={{ mt: 2 }}
                    type="date"
                    placeholder="Enter Article Date"
                    value={articleData?.date === null ? '' : articleData?.date}
                    onChange={handleFormEvent}
                />
            </Grid>

            {articleType === ArticleType.CUSTOM && (
                <>
                    <Grid item xs={12} md={12}>
                        <Typography sx={{ color: 'grey' }}>Article Description</Typography>
                        <TextField
                            id="description"
                            variant="outlined"
                            multiline
                            rows={10}
                            margin="dense"
                            sx={{ mt: 2 }}
                            fullWidth
                            placeholder="Enter Article Description"
                            value={articleData?.description === null ? '' : articleData?.description}
                            onChange={handleFormEvent}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography sx={{ color: 'grey', mb: 2 }}>Article Hero image (optional)</Typography>

                        <Box sx={{ p: 2, backgroundColor: '#F3F3F3', width: 'fit-content', border: 'dashed 3px #B4B4B4' }}>
                            <img alt="hero-article-img" src={imagePath} height={100} />
                        </Box>
                        <TextField
                            id="image"
                            variant="outlined"
                            sx={{ mt: 2, mb: 1 }}
                            fullWidth
                            placeholder="Example: www.yourapp/sampleimage.jpeg"
                            value={articleData?.image === null ? '' : articleData?.image}
                            onChange={handleFormEvent}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography sx={{ mt: 2, mb: 2 }}>OR</Typography>
                            <Button variant="contained" component="label">
                                Upload
                                <input
                                    hidden
                                    accept="image/*"
                                    type="file"
                                    onChange={(e) => {
                                        const files = e.target.files;
                                        console.log(files[0]);
                                        if (files.length > 0) {
                                            const selectedFile = files[0];
                                            const objectUrl = URL.createObjectURL(selectedFile);
                                            console.log('url', objectUrl);
                                            setImagePath(objectUrl);
                                        }
                                    }}
                                />
                            </Button>
                        </Box>
                    </Grid>
                </>
            )}

            <Grid item xs={12} md={12}>
                <Typography sx={{ color: 'grey' }}>Article Web link</Typography>
                <TextField
                    id="uri"
                    variant="outlined"
                    sx={{ mt: 2, mb: 1 }}
                    fullWidth
                    placeholder="Example: www.yourapp/blog"
                    value={articleData?.uri === null ? '' : articleData?.uri}
                    onChange={handleFormEvent}
                />

                {articleData?.uri?.length > 0 && !isValidUrl(articleData?.uri) && (
                    <FormHelperText error id="invalid-image">
                        Please enter a valid URL starting with https://
                    </FormHelperText>
                )}
                {isValidUrl(articleData?.uri) && <Iframe height="250px" width="350px" src={articleData?.uri} />}
            </Grid>
        </Grid>
    );
}

export default ArticleForm;
