import DashboardMainLayout from 'layout/DashboardMainLayout';
import React, { useEffect, useState } from 'react';
import { QuotationContextProvider } from './provider/QuotationContext';
import UserDetailsPopUp from 'pages/user-management/components/user_details';
import QuotationDetailsPopUp from 'components/QuotationDetails';
import DynamicTableSelection from 'components/Common/DynamicTableSelection';
import useFeedbackContext from 'contexts/FeedbackContext';
import useAxios from 'hooks/useAxios';
import config from 'utils/config/config';
import { ToastMessageType, userTypes } from 'utils/constants';
import {
    GET_ALL_LIVE_TRADE_PRICE,
    GET_ALL_QUOTES,
    GET_ALL_QUOTES_GRAPH,
    GET_PACKAGING,
    GET_PRODUCTS,
    GET_SEAPORT
} from 'utils/config/endpoints';
import { OK } from 'utils/config/response-codes';
import useDebouncedEffect from 'hooks/useDebouncer';
import { Box, Fab, Tab, TablePagination, Tabs, useMediaQuery } from '@mui/material';
import { QuotationTableConfig } from './config-utils';
import QuotationGraph from './components/QuotationGraph';
import QuotationFilterDialog from './components/QuotationFilterDialog';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import MultiSelectorGraph from './components/MultiSelectorGraph';
import TabPanel, { a11yProps } from 'components/table/TablePanel';
import { formatTimeToUnix } from 'utils/miscellaneous';
import { addDays, startOfDay } from 'date-fns';

const ServerDefaultCalls = {
    default: 0,
    fetchPackaging: 1,
    fetchSeaPort: 2,
    fetchProducts: 3,
    fetchQuotationCount: 3,
    fetchQuotationList: 4
};

function QuotationManagement() {
    const [quotationList, setQuotationList] = useState([]);
    const { showToastMessage } = useFeedbackContext();
    const { axiosInstance } = useAxios();
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = React.useState('');
    const [maxDataLength, setMaxDataLength] = React.useState(0);
    const [maxTotalPages, setMaxTotalPages] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(QuotationTableConfig.tableConfig.maxRows);
    const [seaPortList, setSeaPortList] = useState([]);
    const [packagingList, setPackagingList] = useState([]);
    const [productCategoryList, setProductCategoryList] = useState([]);
    const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
    const [selectedSubCategoryIds, setSelectedSubCategoryIds] = useState([]);

    const [isShowQuotationDetails, setShowQuotationDetails] = useState(false);
    const [quotationDetails, setQuotationDetails] = useState(null);

    const [isShowUserDetails, setShowUserDetails] = React.useState(false);
    const [userData, setUserData] = useState(null);
    const [userStatusLoading, setUserStatusLoading] = useState(false);
    const [serverRetryCall, setServerRetryCall] = React.useState(ServerDefaultCalls.default);
    const [isQuotationFetchServerError, setIsQuotationFetchServerError] = React.useState(false);
    const [isQuotationGraphFetchServerError, setIsQuotationGraphFetchServerError] = React.useState(false);
    const [selectedGraphData, setSelectedGraphData] = useState([]);
    const [productLoadingError, setProductLoadingError] = useState(false);
    const [isShowFilterDialog, setShowFilterDialog] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [dateSelection, setDateSelection] = useState('Last 7 Days');
    const [isLiveTradingDataLoading, setIsLiveTradingDataLoading] = useState(false);
    const [multiTradingData, setMultiTradingData] = useState([]);
    const [startEndDate, setStartEndDate] = useState(null);
    const [filterData, setFilterData] = useState(null);
    const [isLiveTradingDataError, setIsLiveTradingDataError] = useState(false);
    const [intialLoad, setInitailLoad] = useState(false);

    const isMobileUi = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await fetchSeaPort();
        await fetchPackagingConfig();
        await fetchProducts();
        setStartEndDate({ startDate: startOfDay(addDays(new Date(), -7)), endDate: new Date() });
        setInitailLoad(true);
    }

    useEffect(() => {
        if (page < maxTotalPages) {
            fetchQuotationsApproval();
        }
    }, [page, rowsPerPage, seaPortList, packagingList]);

    useDebouncedEffect(
        () => {
            if (searchText.length > config.defaultTableConfig.minSearchCharacters) {
                fetchQuotationsApproval();
            } else if (searchText.length === 0) {
                fetchQuotationsApproval();
            }
        },
        500,
        [searchText]
    );

    useEffect(() => {
        fetchQuotesGraphData();
        fetchQuotationsApproval();
    }, [selectedCategoryIds]);

    useEffect(() => {
        console.log('startEndDate', startEndDate);
        if (startEndDate !== null) {
            fetchTradingGraphData(startEndDate.startDate, startEndDate.endDate);
        }
    }, [startEndDate, selectedCategoryIds]);

    async function fetchPackagingConfig() {
        showTableLoader();
        // fetchPackagingConfig();
        setServerRetryCall(ServerDefaultCalls.fetchPackaging);
        axiosInstance
            .get(GET_PACKAGING)
            .then((response) => {
                hideTableLoader();
                if (response.data.response_code === OK) {
                    setPackagingList(response.data.data);
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch(() => {
                setIsQuotationFetchServerError(true);
                hideTableLoader();
            });
    };

    async function fetchSeaPort() {
        showTableLoader();
        setServerRetryCall(ServerDefaultCalls.fetchSeaPort);
        return axiosInstance
            .get(GET_SEAPORT)
            .then((response) => {
                if (response.data.response_code === OK) {
                    setSeaPortList(response.data.data);
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch(() => {
                setIsQuotationFetchServerError(true);
                hideTableLoader();
            });
    };

    const fetchQuotationsApproval = () => {
        if (intialLoad) {
            setServerRetryCall(ServerDefaultCalls.fetchQuotationList);
            showTableLoader();
            axiosInstance
                .get(GET_ALL_QUOTES, {
                    params: {
                        limit: rowsPerPage,
                        page_count: page,
                        search: searchText,
                        rice_ids: Array.isArray(selectedSubCategoryIds) ? selectedSubCategoryIds.join(',') : selectedSubCategoryIds
                    }
                })
                .then((response) => {
                    hideTableLoader();
                    setIsQuotationFetchServerError(false);
                    if (response.data.response_code === OK) {
                        try {
                            const dataList = response.data.data.content ?? [];
                            setMaxDataLength(response.data.data.totalElements);
                            setMaxTotalPages(response.data.data.totalPages);

                            // approval id ,quotation id, seller name, seller company name, rice id and name, expiry date,
                            const newItems = dataList.flatMap((item) => {
                                const isRiceDetailsExist = item.hasOwnProperty('rice_details');

                                // Map seaports data
                                const mappedSeaportsData = item.seaports.map((seaport) => {
                                    const matchingSeaport = seaPortList.find((sp) => sp.id === seaport);

                                    if (matchingSeaport === null || matchingSeaport === undefined) {
                                        return { id: seaport, title: '', price: '' };
                                    }
                                    return {
                                        id: matchingSeaport.id,
                                        title: matchingSeaport.title,
                                        price: matchingSeaport.price
                                    };
                                });

                                // Map packaging data
                                const mappedPackagingData = item.packaging.map((packaging) => {
                                    const matchingPackaging = packagingList.find((p) => p.id === packaging?.id);

                                    if (matchingPackaging === null || matchingPackaging === undefined) {
                                        const packageWeightData = packaging.kg.map((kg) => {
                                            return {
                                                id: kg,
                                                weight: '',
                                                price: ''
                                            };
                                        });

                                        const packageLbsData = packaging.lbs.map((lbs) => {
                                            return {
                                                id: lbs,
                                                weight: '',
                                                price: ''
                                            };
                                        });

                                        return {
                                            id: packaging.id,
                                            title: '',
                                            subtitle: '',
                                            kgsWeightItem: packageWeightData,
                                            lbsWeightItem: packageLbsData
                                        };
                                    }

                                    return {
                                        id: matchingPackaging.id,
                                        title: matchingPackaging.title,
                                        subtitle: matchingPackaging.subtitle,
                                        kgsWeightItem: packaging.kg.map((kg) => {
                                            const matchingKgWeightItem = matchingPackaging.kgsWeightItem.find((k) => k.id === kg);

                                            if (matchingKgWeightItem === null || matchingKgWeightItem === undefined)
                                                return {
                                                    id: kg,
                                                    weight: '',
                                                    price: ''
                                                };

                                            return {
                                                id: matchingKgWeightItem.id,
                                                weight: matchingKgWeightItem.weight,
                                                price: matchingKgWeightItem.price
                                            };
                                        }),
                                        lbsWeightItem: packaging.lbs.map((lb) => {
                                            const matchingLbWeightItem = matchingPackaging.lbsWeightItem.find((l) => l.id === lb);

                                            if (matchingLbWeightItem === null || matchingLbWeightItem === undefined)
                                                return {
                                                    id: lb,
                                                    weight: '',
                                                    price: ''
                                                };

                                            return {
                                                id: matchingLbWeightItem.id,
                                                weight: matchingLbWeightItem.weight,
                                                price: matchingLbWeightItem.price
                                            };
                                        })
                                    };
                                });

                                return {
                                    ...item,
                                    chdate: item.chdate,
                                    expiry: item.validity,
                                    id: item.id,
                                    reason: item.reason,
                                    seller_name: item.seller.name,
                                    seller_lastName: item.seller.last_name,
                                    seller_profileImage: item.seller.profile_image,
                                    cp_name: item.seller.cp_name,
                                    useData: {
                                        ...item.seller,
                                        seller_id: item.seller.id,
                                        type: item.seller.role?.includes('ROLE_SUPPLIER') ? userTypes.SELLER : userTypes.BUYER
                                    },
                                    seaports: mappedSeaportsData,
                                    packaging: mappedPackagingData,
                                    riceSubCategory: isRiceDetailsExist
                                        ? item.rice_details === null
                                            ? { id: item.riceId, title: null, iconURL: null }
                                            : item.rice_details
                                        : { id: item.riceId, title: null, iconURL: null }
                                };
                            });
                            setQuotationList(newItems);
                        } catch (e) {
                            console.log(e);
                        }
                    } else {
                        showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                    }
                })
                .catch((error) => {
                    setIsQuotationFetchServerError(true);
                    hideTableLoader();
                });
        }
    };

    const fetchTradingGraphData = (startDate, endDate) => {
        if (selectedSubCategoryIds.length > 0) {
            setIsLiveTradingDataLoading(true);
            axiosInstance
                .get(GET_ALL_LIVE_TRADE_PRICE, {
                    params: {
                        ids: Array.isArray(selectedSubCategoryIds) ? selectedSubCategoryIds.join(',') : selectedSubCategoryIds,
                        startDate: formatTimeToUnix(startDate),
                        endDate: formatTimeToUnix(endDate)
                    }
                })
                .then((response) => {
                    setIsLiveTradingDataError(false);
                    setIsLiveTradingDataLoading(false);
                    if (response.data.response_code === OK) {
                        setMultiTradingData(response.data.data);
                    } else {
                        showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                    }
                })
                .catch((error) => {
                    setIsLiveTradingDataError(true);
                    setIsLiveTradingDataLoading(false);
                });
        }
    };

    const fetchQuotesGraphData = () => {
        showLoader();
        axiosInstance
            .get(GET_ALL_QUOTES_GRAPH, {
                params: { cat_ids: Array.isArray(selectedCategoryIds) ? selectedCategoryIds.join(',') : selectedCategoryIds }
            })
            .then((response) => {
                hideLoader();
                setIsQuotationGraphFetchServerError(false);
                if (response.data.response_code === OK) {
                    setSelectedGraphData(response.data.data);
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch((error) => {
                setIsQuotationGraphFetchServerError(true);
                hideLoader();
            });
    };

    async function fetchProducts() {
        setServerRetryCall(ServerDefaultCalls.fetchProducts);
        axiosInstance
            .get(GET_PRODUCTS)
            .then((response) => {
                setProductLoadingError(false);
                if (response.data.response_code === OK) {
                    const dataList = response.data.data ?? [];
                    const newItems = dataList.flatMap((item) => {
                        return { id: item.id, title: item.title, sub_category: item.varietyItems };
                    });
                    setProductCategoryList(newItems);
                    const newItemsIds = dataList.flatMap((item) => {
                        return item.id;
                    });
                    const subCatIds = newItems.flatMap((item) => {
                        return item.sub_category.map((data) => data.id);
                    });
                    setSelectedCategoryIds(newItemsIds);
                    setSelectedSubCategoryIds(subCatIds);
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch(() => {
                setProductLoadingError(true);
            });
    };

    const showLoader = () => {
        setIsLoading(true);
    };

    const hideLoader = () => {
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    };

    const showQuotationData = (data) => {
        setQuotationDetails(data);
        setShowQuotationDetails(true);
    };

    const showTableLoader = () => {
        setIsTableLoading(true);
    };

    const hideTableLoader = () => {
        hideLoader();
        setTimeout(() => {
            setIsTableLoading(false);
        }, 500);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const callRetry = () => {
        setSearchText('');
        switch (serverRetryCall) {
            case ServerDefaultCalls.fetchSeaPort:
                fetchSeaPort();
                break;
            case ServerDefaultCalls.fetchPackaging:
                fetchPackagingConfig();
                break;
            case ServerDefaultCalls.fetchQuotationList:
                fetchQuotationsApproval();
                break;
            case ServerDefaultCalls.fetchProducts:
                fetchProducts();
                break;
            default:
                fetchQuotationsApproval();
                break;
        }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <QuotationContextProvider>
            <DashboardMainLayout
                title="Quotation Management"
                contentSX={{ padding: { xs: 0, md: 1, sm: 2, lg: 3 } }}
                showRetryNetwork={productLoadingError}
                retryCallback={() => {
                    fetchProducts();
                }}
            >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="select-graph-tabs"
                        textColor="secondary"
                        indicatorColor="secondary"
                    >
                        <Tab label="Quotation Graph" {...a11yProps(0)} />
                        <Tab label="Trading Price vs Time" {...a11yProps(1)} />
                    </Tabs>
                </Box>

                <TabPanel value={value} index={0}>
                    <QuotationGraph
                        data={selectedGraphData}
                        isLoading={isLoading}
                        isError={isQuotationGraphFetchServerError}
                        productCategoryList={productCategoryList}
                        selectedIdCallback={(ids) => {
                            setSelectedCategoryIds(ids);
                        }}
                        retryCallback={() => {
                            fetchQuotesGraphData();
                        }}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <MultiSelectorGraph
                        isLoading={isLiveTradingDataLoading}
                        isError={isLiveTradingDataError}
                        data={multiTradingData}
                        defaultDateSelection={dateSelection}
                        defaultDateSelected={startEndDate}
                        dateSelection={(val) => {
                            setDateSelection(val);
                        }}
                        onDateFetch={(date) => {
                            setStartEndDate(date);
                        }}
                        retryCallback={() => {
                            fetchTradingGraphData(startEndDate.startDate, startEndDate.endDate);
                        }}
                    />
                </TabPanel>

                <DynamicTableSelection
                    data={quotationList}
                    headCells={QuotationTableConfig.headCells}
                    tableConfig={QuotationTableConfig.tableConfig}
                    isError={isQuotationFetchServerError}
                    isLoading={isTableLoading}
                    onRetryCallback={callRetry}
                    onRefreshCallback={() => {
                        setPage(0);
                        setSearchText('');
                        fetchQuotationsApproval();
                    }}
                    handleRowClick={(rowData) => {
                        setUserStatusLoading(false);
                        showQuotationData(rowData);
                    }}
                    handleSearchChange={(val) => {
                        setSearchText(val);
                    }}
                    externalPaginationRender={() => {
                        return (
                            <TablePagination
                                rowsPerPageOptions={[5, 25, 50, 100]}
                                component="div"
                                count={maxDataLength}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        );
                    }}
                />

                <Fab
                    sx={{ position: 'fixed', right: 30, bottom: 30 }}
                    variant={isMobileUi ? 'circular' : 'extended'}
                    size={isMobileUi ? 'small' : 'medium'}
                    color="primary"
                    onClick={() => {
                        setFilterData({ subProductIds: selectedSubCategoryIds, cat_ids: selectedCategoryIds });
                        setShowFilterDialog(true);
                    }}
                >
                    <FilterListRoundedIcon /> {isMobileUi ? '' : 'Filter'}
                </Fab>
            </DashboardMainLayout>

            <QuotationDetailsPopUp
                showDrawer={isShowQuotationDetails}
                quotationData={quotationDetails}
                closeDrawer={() => {
                    setUserStatusLoading(false);
                    setShowQuotationDetails(false);
                }}
                sellerDetailsCallback={() => {
                    setUserData(quotationDetails.useData);
                    setShowUserDetails(true);
                }}
                userStatusLoading={userStatusLoading}
            />

            <UserDetailsPopUp
                showDrawer={isShowUserDetails}
                userData={userData}
                closeDrawer={() => {
                    setShowUserDetails(false);
                }}
                onUpdateCallback={() => {
                    setShowUserDetails(false);
                }}
                isUserApprovalJourney={true}
            />

            <QuotationFilterDialog
                filterDefaultSelected={filterData}
                data={productCategoryList}
                showDrawer={isShowFilterDialog}
                closeDrawer={() => {
                    setShowFilterDialog(false);
                }}
                onFilterSelected={(value) => {
                    setSelectedSubCategoryIds(value.subProductIds);
                    if (value.cat_ids.length === 0) {
                        if (value.cat_ids[0] === 'all') {
                            setSelectedCategoryIds([]);
                        } else {
                            setSelectedCategoryIds(value.cat_ids);
                        }
                    } else {
                        const filterIds = value.cat_ids.filter((data) => data !== 'all');
                        setSelectedCategoryIds(filterIds);
                    }
                    if (isMobileUi) {
                        setShowFilterDialog(false);
                    }
                }}
            />
        </QuotationContextProvider>
    );
}

export default QuotationManagement;
