import { Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import DraggableNavigationMenuItem from './DraggableNavigationMenuItem';

const DraggableNavigationMenu = React.memo(({ items, onDragEnd, onEditItem, onDeleteItem }) => {
    const isMobileUi = useMediaQuery('(max-width:600px)');

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-menu-list">
                {(provided) => (
                    <Grid spacing={5} rowSpacing={2} container ref={provided.innerRef} {...provided.droppableProps}>
                        {items.map((item, index) => (
                            <Grid key={item.id} item width={isMobileUi ? '100%' : null}>
                                <DraggableNavigationMenuItem
                                    item={item}
                                    index={index}
                                    key={item.id}
                                    onEditItem={() => {
                                        onEditItem(item);
                                    }}
                                    onDeleteItem={() => {
                                        onDeleteItem(item);
                                    }}
                                />
                            </Grid>
                        ))}
                        {provided.placeholder}
                    </Grid>
                )}
            </Droppable>
        </DragDropContext>
    );
});

export default DraggableNavigationMenu;
