import CustomDialog from 'components/Common/CustomDialog';
import React, { useState } from 'react';

import { Grid, MenuItem, Select, TextField, Typography } from '@mui/material';

function AddWeightDrawer(props) {
    const { window, showDrawer, closeDrawer, isLoading = false, isEdit = false, onAddCallback, onEditCallback, data, configDrawer } = props;
    const label = isEdit ? 'Edit Weight' : 'Add Weight';
    const defaultFormValue = { name: 'kg', weight: {} };
    const [dataDrawer, setDataDrawer] = useState(defaultFormValue);
    const [isButtonEnable, setButtonEnable] = useState(false);
    const [weightInputValue, setWeightInputValue] = useState('');
    const [priceInputValue, setPriceInputValue] = useState('');

    React.useEffect(() => {
        if (isEdit) {
            if (data !== null) {
                setDataDrawer({ name: data.weight, weight: data.data });
                setWeightInputValue(data.data.weight);
                setPriceInputValue(data.data.price);
            } else {
                resetForm();
            }
        } else {
            resetForm();
        }
    }, [data]);

    React.useEffect(() => {
        if (dataDrawer !== null) {
            setButtonEnable(weightInputValue?.toString().length > 0 && priceInputValue?.toString().length > 0);
        } else {
            setButtonEnable(false);
        }
    }, [dataDrawer, weightInputValue, priceInputValue]);

    const resetForm = () => {
        setWeightInputValue('');
        setPriceInputValue('');
        setDataDrawer(defaultFormValue);
    };

    const closeDialog = () => {
        resetForm();
        closeDrawer();
    };

    const handleWeightChange = (event) => {
        setDataDrawer({ ...dataDrawer, name: event.target.value });
    };

    const handleChange = (e) => {
        const id = e.currentTarget.id;
        const value = e.currentTarget.value;

        const regex = /^\d{0,4}(\.\d{0,4})?$/;

        if (regex.test(value) || value === '') {
            if (id === 'weight') {
                setWeightInputValue(value);
            } else {
                setPriceInputValue(value);
            }
        }
    };

    const AddEditForm = () => {
        return (
            <Grid container mt={2} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">Select Weight Type from Dropdown:</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Select
                        sx={{ width: 'auto', flex: 1, display: 'flex' }}
                        value={dataDrawer.name}
                        placeholder="Select Weight"
                        disabled={isEdit}
                        onChange={handleWeightChange}
                    >
                        <MenuItem value="kg">Kg</MenuItem>
                        <MenuItem value="lbs">Lbs</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="weight"
                        name="weight"
                        inputMode="decimal"
                        label="Enter Weight"
                        autoComplete="off"
                        autoCorrect="off"
                        onChange={handleChange}
                        value={weightInputValue}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="price"
                        name="price"
                        autoComplete="off"
                        autoCorrect="off"
                        inputMode="decimal"
                        label="Enter price"
                        value={priceInputValue}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
            </Grid>
        );
    };

    return (
        <CustomDialog
            window={window}
            buttonLabel={label}
            dialogTitle={label}
            showDrawer={showDrawer}
            isLoading={isLoading}
            onClickCallback={() => {
                resetForm();
                const valueEdited = { ...dataDrawer, weight: { ...dataDrawer.weight, weight: weightInputValue, price: priceInputValue } };
                isEdit ? onEditCallback(valueEdited) : onAddCallback(valueEdited);
            }}
            isButtonEnable={isButtonEnable}
            closeDrawer={closeDialog}
            customFormUi={AddEditForm()}
        />
    );
}

export default AddWeightDrawer;
