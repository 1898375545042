import { Button, useMediaQuery } from '@mui/material';
import DashboardMainLayout from 'layout/DashboardMainLayout';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AppBannerForm from '../../components/AppBannerForm';
import config from 'utils/config/config';
import PhotoPlaceholder from 'assets/images/image_placeholder.svg';
import useAxios from 'hooks/useAxios';
import useFeedbackContext from 'contexts/FeedbackContext';
import { ADD_BANNER_CONFIG, DELETE_BANNER_CONFIG } from 'utils/config/endpoints';
import { OK } from 'utils/config/response-codes';
import { ToastMessageType } from 'utils/constants';
import DeleteDialogConfirmation from 'components/Dialogs/DeleteDialogConfirmation';
import { isValidUrl } from 'utils/miscellaneous';

export default function AddBannerScreen() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [isEditScreen, setIsEditScreen] = React.useState(false);
    const [serviceTypes, setServiceTypes] = useState([]);
    const { axiosInstance } = useAxios();
    const { showToastMessage, showLoader, hideLoader } = useFeedbackContext();
    const [isDeleteDialogShow, setDeleteDialogShow] = React.useState(false);
    const [isButtonDisable, setButtonDisable] = React.useState(true);

    const [bannerData, setBannerData] = React.useState({
        id: null,
        title: '',
        description: '',
        image_blob: '',
        hyperlink: '',
        banner_image_url: '',
        serviceType: [],
        type: config.platformOS.Android,
        order_seq: 0
    });

    const isMobileUi = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width:600px) and (max-width: 991px)');

    useEffect(() => {
        console.log(JSON.stringify(state.data));
        setIsEditScreen(state.data !== null);
        if (state.data !== null) {
            const selectedIds = state.data.serviceType;
            const updatedServiceTypes = config.serviceDefaultTypeList.map((item) => ({
                ...item,
                value: selectedIds.includes(item.id)
            }));
            setServiceTypes(updatedServiceTypes);
            setBannerData(state.data);
        } else {
            setServiceTypes(config.serviceDefaultTypeList);
            setBannerData({ ...bannerData, type: state.type });
        }
    }, []);

    const handleFormEvent = (e) => {
        const value = e.target.value;
        const id = e.target.id;
        setBannerData({ ...bannerData, [id]: value });
    };

    useEffect(() => {
        if (serviceTypes.length > 0) {
            const selectedIds = serviceTypes.filter((item) => item.value === true).map((item) => item.id);

            setBannerData((prevData) => ({
                ...prevData,
                serviceType: selectedIds
            }));
        }
    }, [serviceTypes]);

    useEffect(() => {
        if (bannerData !== null) {
            if (bannerData.title.length > config.maxBannerTitleLength && bannerData.serviceType.length > 0) {
                if (bannerData?.hyperlink.length > 0) {
                    if (isValidUrl(bannerData?.hyperlink)) {
                        setButtonDisable(false);
                    } else {
                        setButtonDisable(true);
                    }
                } else {
                    setButtonDisable(false);
                }
            } else {
                setButtonDisable(true);
            }
        } else {
            setButtonDisable(true);
        }
    }, [bannerData]);

    const addEditBanner = () => {
        showLoader();

        if (isEditScreen) {
            axiosInstance
                .post(ADD_BANNER_CONFIG, {
                    ...bannerData
                })
                .then((response) => {
                    hideLoader();
                    if (response.data.response_code === OK) {
                        showToastMessage('Successfully Updated!!', ToastMessageType.SUCCESS);
                        navigate(-1, {
                            state: { data: response.data.data }
                        });
                    } else {
                        showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                    }
                })
                .catch((error) => {
                    hideLoader();
                    showToastMessage('Something went wrong', ToastMessageType.ERROR);
                });
        } else {
            axiosInstance
                .post(ADD_BANNER_CONFIG, {
                    ...bannerData,
                    order_seq: state.length
                })
                .then((response) => {
                    hideLoader();
                    if (response.data.response_code === OK) {
                        showToastMessage('Successfully Added New Banner!!', ToastMessageType.SUCCESS);
                        navigate(-1, {
                            state: { data: response.data.data }
                        });
                    } else {
                        showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                    }
                })
                .catch((error) => {
                    hideLoader();
                    showToastMessage('Something went wrong', ToastMessageType.ERROR);
                });
        }
    };

    const deleteBanner = () => {
        axiosInstance
            .delete(DELETE_BANNER_CONFIG + '/' + bannerData['id'])
            .then((response) => {
                hideLoader();
                if (response.data.response_code === OK) {
                    showToastMessage('Successfully Deleted!!', ToastMessageType.SUCCESS);
                    navigate(-1, {
                        state: { data: response.data.data }
                    });
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch((error) => {
                hideLoader();
                showToastMessage('Something went wrong', ToastMessageType.ERROR);
            });
    };

    return (
        <DashboardMainLayout
            title={isEditScreen ? 'Edit Banner' : 'Add Banner'}
            isBackButton={true}
            navigateBack={() => {
                navigate(-1);
            }}
            secondary={
                isEditScreen ? (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            setDeleteDialogShow(true);
                        }}
                    >
                        Delete
                    </Button>
                ) : null
            }
        >
            <AppBannerForm
                serviceTypes={serviceTypes}
                setServiceTypes={setServiceTypes}
                bannerData={bannerData}
                handleFormEvent={handleFormEvent}
            />

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 40, marginBottom: 20 }}>
                <Button
                    fullWidth={isMobileUi}
                    variant="contained"
                    color="secondary"
                    disabled={isButtonDisable}
                    onClick={() => {
                        addEditBanner();
                    }}
                    sx={{ color: 'white', width: isMobileUi ? '100%' : 200, p: 1.5 }}
                >
                    {isEditScreen ? 'Save' : 'Add New Banner'}
                </Button>
            </div>

            <DeleteDialogConfirmation
                deleteCallback={() => {
                    deleteBanner();
                    setDeleteDialogShow(false);
                }}
                cancelCallback={() => {
                    setDeleteDialogShow(false);
                }}
                msg="Do you want to delete this Banner?"
                showDeleteConfirmation={isDeleteDialogShow}
            />
        </DashboardMainLayout>
    );
}
