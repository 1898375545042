import React, { createContext, useContext, useEffect } from 'react';

const QuotationContext = createContext();

export const QuotationContextProvider = ({ children }) => {
    useEffect(() => {}, []);

    return <QuotationContext.Provider value={{}}>{children}</QuotationContext.Provider>;
};

export default function useQuotationContext() {
    return useContext(QuotationContext);
}
