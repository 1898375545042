// export const firebaseConfig = {
//     apiKey: 'AIzaSyBF8_zAL5Tihe6zcNEgj0HZmP7w_5EABNs',
//     authDomain: 'rice-admin-56367.firebaseapp.com',
//     projectId: 'rice-admin-56367',
//     storageBucket: 'rice-admin-56367.appspot.com',
//     messagingSenderId: '990661356579',
//     appId: '1:990661356579:web:fff37cecec753e50005596',
//     measurementId: 'G-CGVLD2JQM2'
// };

export const firebaseConfig = {
  apiKey: "AIzaSyDBnxXoEaFKB7T8BufRllbklx3OyV6m_H0",
  authDomain: "ricedetailsapp.firebaseapp.com",
  databaseURL: "https://ricedetailsapp.firebaseio.com",
  projectId: "ricedetailsapp",
  storageBucket: "ricedetailsapp.appspot.com",
  messagingSenderId: "550417327904",
  appId: "1:550417327904:web:abe113dcc5052065809945",
  measurementId: "G-73YNLL08JF"
};

export const subProductFolderPath = 'product-subcategory';
