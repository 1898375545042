import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    SwipeableDrawer,
    Switch,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { shadows, useTheme } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { ToastMessageType, userTypes } from 'utils/constants';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import { dateFormat, formatCurrency, formatServerDate, formatTimeStampDate } from 'utils/miscellaneous';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import VerifiedRoundedIcon from 'assets/images/circle-check.svg';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import config from 'utils/config/config';
import { LoadingButton } from '@mui/lab';
import useAxios from 'hooks/useAxios';
import { GET_USERS, UPDATE_SELLER_APPROVAL, UPDATE_SELLER_REJECTED, USER_STATUS_UPDATE } from 'utils/config/endpoints';
import { OK } from 'utils/config/response-codes';
import useFeedbackContext from 'contexts/FeedbackContext';
import CardLoading from 'components/Loading/CardLoading';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

const CardContentNoPadding = styled(CardContent)(`
          padding: 0.5;
          &:last-child {
            padding-bottom: 0;
          }
        `);

function QuotationDetailsPopUp({
    showDrawer,
    closeDrawer,
    quotationData,
    onUpdateCallback,
    isUserApprovalJourney = false,
    sellerDetailsCallback,
    userStatusLoading = false,
    selectedButtonAction,
    onButtonCallback
}) {
    const theme = useTheme();
    const { showToastMessage } = useFeedbackContext();

    const isMobileUi = useMediaQuery('(max-width:600px)');

    React.useEffect(() => {
        if (quotationData != null) {
        }
    }, [quotationData]);

    const closeDialog = () => {
        if (userStatusLoading) {
            showToastMessage('Updating status please wait!!', ToastMessageType.ERROR);
        } else {
            closeDrawer();
        }
    };

    return (
        <SwipeableDrawer
            anchor={'right'}
            open={showDrawer}
            onOpen={(val) => {}}
            onClose={closeDialog}
            PaperProps={{
                style: {
                    width: isMobileUi ? '100%' : 400
                }
            }}
        >
            <Box
                as="div"
                sx={{
                    width: isMobileUi ? '100%' : 400,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    bgcolor: 'white'
                }}
            >
                {quotationData !== null && (
                    <>
                        <div
                            style={{
                                backgroundColor: quotationData?.status === config.quotationStatus.pending ? '#C1EAF6' : 'transparent',
                                display: 'flex',
                                flexDirection: 'row',
                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                            }}
                        >
                            <Tooltip
                                title="Close"
                                style={{ position: quotationData?.status === config.quotationStatus.pending ? 'unset' : 'unset' }}
                            >
                                <IconButton
                                    onClick={closeDialog}
                                    sx={{
                                        position: 'relative',
                                        '&:after': {
                                            content: "''",
                                            position: 'absolute',
                                            top: 10,
                                            left: 0,
                                            right: 10,
                                            borderRadius: '10%',
                                            opacity: 0.3
                                        }
                                    }}
                                >
                                    <CloseIcon style={{ color: 'black' }} />
                                </IconButton>
                            </Tooltip>

                            <Typography
                                as="div"
                                variant="h5"
                                color="white"
                                sx={{
                                    display: quotationData?.status === config.quotationStatus.pending ? 'flex' : 'none',
                                    color: 'black',
                                    width: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: 1
                                }}
                            >
                                Pending Approval Id:
                                <b>{quotationData?.id}</b>
                            </Typography>
                        </div>

                        <Box sx={{ flex: 1, overflowY: 'scroll' }}>
                            <Box
                                sx={{
                                    backgroundColor: theme.palette.primary.light,
                                    borderRadius: 5,
                                    m: 3,
                                    mt: 4,
                                    boxShadow: '0 2px 14px 0 rgb(32 40 45 / 20%)',
                                    p: 2
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Typography variant="h5"> Quotation Id: </Typography>
                                            <Typography> {quotationData.id}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Typography variant="h5">
                                                {quotationData.riceSubCategory?.title === null ? 'Rice Id:' : 'Rice Name:'}
                                            </Typography>
                                            <Typography>
                                                {quotationData.riceSubCategory?.title === null
                                                    ? quotationData.riceSubCategory?.id
                                                    : quotationData.riceSubCategory?.title}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Typography variant="h5">Date: </Typography>
                                            <Typography> {formatServerDate(quotationData.chdate)}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Typography variant="h5"> Expiry: </Typography>
                                            <Typography> {formatTimeStampDate(quotationData.expiry)}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography variant="h5"> Quantity: </Typography>
                                            <Chip
                                                sx={{ fontWeight: 700, ml: 1, backgroundColor: '#E3E0F7', color: '#3C0C9C' }}
                                                label={quotationData.quantity}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography variant="h5"> Price: </Typography>
                                            <Chip
                                                sx={{ fontWeight: 700, ml: 1, backgroundColor: '#E0F7EB', color: '#0C9C52' }}
                                                label={formatCurrency(quotationData.price)}
                                            />
                                        </div>
                                    </Grid>

                                    {quotationData?.totalExtraCost && (
                                        <Grid item xs={12}>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <Typography variant="h5">Total Extra Cost: </Typography>
                                                <Chip
                                                    sx={{ fontWeight: 700, ml: 1, backgroundColor: '#E0F7EB', color: '#0C9C52' }}
                                                    label={formatCurrency(quotationData?.totalExtraCost)}
                                                />
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>

                            {quotationData?.reason && (
                                <Typography
                                    variant="h5"
                                    style={{
                                        backgroundColor: quotationData?.status === config.quotationStatus.approved ? '#E0FAD9' : '#F6D4D4',
                                        color: quotationData?.status === config.quotationStatus.approved ? '#119122' : '#EC5454',
                                        margin: 20,
                                        padding: 20,
                                        borderRadius: 10
                                    }}
                                >
                                    {quotationData?.reason}
                                </Typography>
                            )}
                            <Divider m={2} sx={{ m: 2 }} />

                            <Box sx={{ m: 2 }}>
                                <Box sx={{ mt: 2 }}>
                                    <Card
                                        elevation={2}
                                        sx={{ mb: 2, borderRadius: 2, backgroundColor: '#EFF7FD' }}
                                        onClick={sellerDetailsCallback}
                                    >
                                        <CardContent>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <Typography sx={{ flex: 1 }} variant="h5">
                                                    Seller Details
                                                </Typography>
                                                <ArrowForwardIosRoundedIcon />
                                            </div>
                                        </CardContent>
                                    </Card>

                                    <Accordion elevation={2} sx={{ p: 1, mb: 2, borderRadius: 2 }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="seaports-content"
                                            id="seaports-header"
                                        >
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant="h5">SeaPorts</Typography>
                                                <Typography variant="body1">Total SeaPorts: {quotationData.seaports.length}</Typography>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Divider />
                                            <div style={{ marginTop: 5 }}>
                                                {quotationData.seaports.map((data, index) => {
                                                    return (
                                                        <div
                                                            key={`${data.id}-${index}`}
                                                            style={{ display: 'flex', flexDirection: 'row', marginTop: 5, marginBottom: 5 }}
                                                        >
                                                            <Typography sx={{ flex: 1 }}>
                                                                {data.title !== null && data.title !== '' ? data.title : ''}
                                                            </Typography>
                                                            <Typography>{formatCurrency(data.price)}</Typography>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion elevation={2} sx={{ p: 1, mb: 2, borderRadius: 2 }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="packaging-content"
                                            id="packaging-header"
                                        >
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant="h5">Packaging</Typography>
                                                <Typography variant="body1">Total Packaging: {quotationData.packaging.length}</Typography>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Divider />
                                            <Grid container sx={{ mt: 1 }} spacing={1}>
                                                {quotationData.packaging.map((data, index) => (
                                                    <Grid item xs={12} key={data.id}>
                                                        <div>
                                                            <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                                                {index + 1}.{data.title}
                                                            </Typography>

                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                                                    {data.kgsWeightItem.map((data, index) => (
                                                                        <Typography key={data.id}>
                                                                            {data.weight + 'kg' + ' | ' + formatCurrency(data.price)}
                                                                        </Typography>
                                                                    ))}
                                                                </div>

                                                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                                                    {data.lbsWeightItem.map((data, index) => (
                                                                        <Typography key={data.id}>
                                                                            {data.weight + 'lbs' + ' | ' + formatCurrency(data.price)}
                                                                        </Typography>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>

                                    {quotationData.extraCharges && Object.keys(quotationData.extraCharges).length > 0 && (
                                        <Accordion elevation={2} sx={{ p: 1, mb: 2, borderRadius: 2 }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="Extra-charges-content"
                                                id="Extra-charges"
                                            >
                                                <Typography variant="h5">Extra Charges</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Divider sx={{ mb: 2 }} />
                                                {quotationData.extraCharges !== undefined &&
                                                    Object.keys(quotationData.extraCharges).map((key, index) => (
                                                        <Grid item xs={6} key={key}>
                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <Typography sx={{ fontWeight: 700, flex: 1 }}>{key}</Typography>
                                                                <Typography>
                                                                    {' ' + quotationData.extraCharges[key].toLocaleString()}
                                                                </Typography>
                                                            </div>
                                                        </Grid>
                                                    ))}
                                            </AccordionDetails>
                                        </Accordion>
                                    )}
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: quotationData?.status === config.quotationStatus.pending ? 'flex' : 'none',
                                flexDirection: 'row',
                                height: 50
                            }}
                        >
                            <LoadingButton
                                fullWidth
                                loading={userStatusLoading}
                                variant="contained"
                                hidden={selectedButtonAction === config.quotationStatus.approved && userStatusLoading}
                                onClick={() => {
                                    onButtonCallback(config.quotationStatus.approved);
                                }}
                                sx={{
                                    display:
                                        selectedButtonAction !== config.quotationStatus.approved && userStatusLoading ? 'none' : 'auto',
                                    borderRadius: 0,
                                    color: 'white',
                                    ':hover': { backgroundColor: '#82DEAF' },
                                    backgroundColor: '#0D9D52'
                                }}
                            >
                                Approve
                            </LoadingButton>
                            <LoadingButton
                                fullWidth
                                variant="contained"
                                loading={userStatusLoading}
                                onClick={() => {
                                    onButtonCallback(config.quotationStatus.rejected);
                                }}
                                sx={{
                                    display:
                                        selectedButtonAction !== config.quotationStatus.rejected && userStatusLoading ? 'none' : 'auto',
                                    borderRadius: 0,
                                    color: 'white',
                                    ':hover': { backgroundColor: '#E38282' },
                                    backgroundColor: '#D80B0B'
                                }}
                            >
                                Reject
                            </LoadingButton>
                        </Box>
                    </>
                )}
            </Box>
        </SwipeableDrawer>
    );
}

export default QuotationDetailsPopUp;
