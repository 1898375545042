import { Snackbar } from '@mui/material';
import React, { createContext, useContext, useState } from 'react';
import { TOAST_MESSAGE_TIME, ToastMessageType } from 'utils/constants';
import MuiAlert from '@mui/material/Alert';
import Loader from 'components/Dialogs/Loader';

const FeedbackContext = createContext();

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const FeedbackContextProvider = ({ children }) => {
    const [isToastMessage, setIsToastMessage] = useState(false);
    const [toastMessage, setToastMessage] = useState({ type: ToastMessageType.DEFAULT, message: '' });
    const [isLoading, setIsLoading] = useState(false);

    const getToastMessageType = () => {
        switch (toastMessage.type) {
            case ToastMessageType.DEFAULT:
                return 'info';
            case ToastMessageType.ERROR:
                return 'error';
            case ToastMessageType.SUCCESS:
                return 'success';
            case ToastMessageType.WARNING:
                return 'warning';
            default:
                return 'info';
        }
    };
    const showToastMessage = (message, toastMessageTypeSelect) => {
        setToastMessage({ type: toastMessageTypeSelect, message: message });
        setIsToastMessage(true);
    };

    const closeToastMessage = () => {
        setIsToastMessage(false);
    };

    const showLoader = () => {
        setIsLoading(true);
    };

    const hideLoader = () => {
        setTimeout(() => {
            setIsLoading(false);
        }, 300);
    };

    return (
        <FeedbackContext.Provider value={{ showToastMessage, showLoader, hideLoader }}>
            <>
                {children}
                <Snackbar
                    open={isToastMessage}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={TOAST_MESSAGE_TIME}
                    onClose={closeToastMessage}
                >
                    <Alert severity={getToastMessageType()} sx={{ width: '100%' }}>
                        {toastMessage.message}
                    </Alert>
                </Snackbar>
                <Loader showLoader={isLoading} />
            </>
        </FeedbackContext.Provider>
    );
};

export default function useFeedbackContext() {
    return useContext(FeedbackContext);
}
