import dashboard from './dashboard';
import userManagement from './user_management';
import settings from './settings';
import productManagement from './product-management';
import others from './others';
import orders from './orders';
import approval from './approval';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, userManagement, productManagement, approval, orders, others, settings]
};

export default menuItems;
