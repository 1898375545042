import React, { createContext, useContext, useEffect } from 'react';

const SettingsContext = createContext();

export const SettingsContextProvider = ({ children }) => {
    useEffect(() => {}, []);

    return <SettingsContext.Provider value={{}}>{children}</SettingsContext.Provider>;
};

export default function useSettingsContext() {
    return useContext(SettingsContext);
}
