import React from 'react';
import DraggableListItem from './DraggableListItem';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Grid } from '@mui/material';

const DraggableList = React.memo(({ items, onDragEnd, onItemClick }) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-list">
                {(provided) => (
                    <Grid spacing={5} rowSpacing={2} container ref={provided.innerRef} {...provided.droppableProps}>
                        {items.map((item, index) => (
                            <Grid key={item.id} item>
                                <DraggableListItem
                                    item={item}
                                    index={index}
                                    key={item.id}
                                    onItemClick={() => {
                                        onItemClick(item);
                                    }}
                                />
                            </Grid>
                        ))}
                        {provided.placeholder}
                    </Grid>
                )}
            </Droppable>
        </DragDropContext>
    );
});

export default DraggableList;
