export const saveUserToken = (value) => {
    localStorage.setItem('token', value);
};

export const getUserToken = () => {
    return localStorage.getItem('token');
};

export const saveUserLogin = (value) => {
    localStorage.setItem('login', value);
};

export const getUserLogin = () => {
    return localStorage.getItem('login');
};

export const removeUserLogin = () => {
    localStorage.removeItem('login');
};

export const removeUserToken = () => {
    localStorage.removeItem('token');
};

export const clearLogout = () => {
    removeUserLogin();
    removeUserToken();
};
