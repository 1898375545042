import { LoadingButton } from '@mui/lab';
import { Box, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import useAppConfigContext from 'pages/settings/provider/AppConfigContext';
import React, { useEffect, useState } from 'react';

function ExtraChargesTab() {
    const [selectedOtherTabData, setSelectedOtherTabData] = useState({
        localFreight: '',
        labourCost: '',
        thc: '',
        cha: '',
        sortex: '',
        others: ''
    });

    const [isButtonEnable, setIsButtonEnable] = useState(false);

    const isMobileUi = useMediaQuery('(max-width:600px)');
    const { isOthersSaveLoading, selectedTabOtherAppConfig, createAdditionalConfig } = useAppConfigContext();

    useEffect(() => {
        const extraChargesData = selectedTabOtherAppConfig?.extraCharges;
        if (extraChargesData !== null && extraChargesData !== undefined) {
            setSelectedOtherTabData({
                localFreight: extraChargesData.localFreight ?? '',
                labourCost: extraChargesData.labourCost ?? '',
                thc: extraChargesData.thc ?? '',
                cha: extraChargesData.cha ?? '',
                sortex: extraChargesData.sortex ?? '',
                others: extraChargesData.others ?? ''
            });
        } else {
            setSelectedOtherTabData({
                localFreight: '',
                labourCost: '',
                thc: '',
                cha: '',
                sortex: '',
                others: ''
            });
        }
    }, [selectedTabOtherAppConfig]);

    useEffect(() => {
        if (
            selectedOtherTabData.others.toString().length > 1 &&
            selectedOtherTabData.sortex.toString().length > 1 &&
            selectedOtherTabData.cha.toString().length > 1 &&
            selectedOtherTabData.thc.toString().length > 1 &&
            selectedOtherTabData.labourCost.toString().length > 1 &&
            selectedOtherTabData.localFreight.toString().length > 1
        ) {
            setIsButtonEnable(true);
        } else {
            setIsButtonEnable(false);
        }
    }, [selectedOtherTabData]);

    const handleFormChange = (e) => {
        var id = e.target.id;
        var value = e.target.value;
        setSelectedOtherTabData({ ...selectedOtherTabData, [id]: value });
    };

    const addAdditionalData = () => {
        createAdditionalConfig({ extraCharges: selectedOtherTabData });
    };

    return (
        <Box sx={{ mb: 2 }}>
            <Grid container spacing={3} sx={{ mt: 1, mb: 2 }}>
                <Grid item xs={12} md={6}>
                    <Typography sx={{ color: 'grey' }}>Local Freight</Typography>
                    <TextField
                        id="localFreight"
                        variant="outlined"
                        sx={{ mt: 2 }}
                        fullWidth
                        type="number"
                        value={selectedOtherTabData.localFreight}
                        placeholder="Enter Local Freight Cost"
                        onChange={handleFormChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography sx={{ color: 'grey' }}>Labour Cost</Typography>
                    <TextField
                        id="labourCost"
                        variant="outlined"
                        type="number"
                        margin="dense"
                        sx={{ mt: 2 }}
                        fullWidth
                        value={selectedOtherTabData.labourCost}
                        onChange={handleFormChange}
                        placeholder="Enter Labour Cost"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography sx={{ color: 'grey' }}>Thc</Typography>
                    <TextField
                        id="thc"
                        variant="outlined"
                        type="number"
                        margin="dense"
                        sx={{ mt: 2 }}
                        fullWidth
                        value={selectedOtherTabData.thc}
                        onChange={handleFormChange}
                        placeholder="Enter Thc Cost"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography sx={{ color: 'grey' }}>Cha</Typography>
                    <TextField
                        id="cha"
                        type="number"
                        variant="outlined"
                        sx={{ mt: 2 }}
                        fullWidth
                        value={selectedOtherTabData.cha}
                        placeholder="Enter Cha Cost"
                        onChange={handleFormChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography sx={{ color: 'grey' }}>Sortex</Typography>
                    <TextField
                        id="sortex"
                        variant="outlined"
                        type="number"
                        margin="dense"
                        sx={{ mt: 2 }}
                        fullWidth
                        value={selectedOtherTabData.sortex}
                        onChange={handleFormChange}
                        placeholder="Enter Sortex Cost"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography sx={{ color: 'grey' }}>Others</Typography>
                    <TextField
                        id="others"
                        variant="outlined"
                        type="number"
                        margin="dense"
                        sx={{ mt: 2 }}
                        fullWidth
                        value={selectedOtherTabData.others}
                        onChange={handleFormChange}
                        placeholder="Enter Others Cost"
                    />
                </Grid>
            </Grid>
            <div
                style={{
                    padding: 2,
                    marginBottom: 10,
                    alignItems: 'center',
                    justifyContent: isMobileUi ? 'center' : 'end',
                    display: 'flex'
                }}
            >
                <LoadingButton
                    loading={isOthersSaveLoading}
                    fullWidth={isMobileUi}
                    disabled={!isButtonEnable}
                    variant="contained"
                    onClick={addAdditionalData}
                    color="secondary"
                    sx={{ color: '#ffffff' }}
                >
                    Submit
                </LoadingButton>
            </div>
        </Box>
    );
}

export default ExtraChargesTab;
