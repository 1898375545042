import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'utils/routes';

// defaultTheme
import themes from './styles/theme';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { AuthContextProvider } from 'contexts/AuthContext';
import { FeedbackContextProvider } from 'contexts/FeedbackContext';
import AppErrorDialog from 'components/AppErrorDialog';

export default function App() {
    const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <AuthContextProvider>
                        <FeedbackContextProvider>
                            <Routes />
                            <AppErrorDialog />
                        </FeedbackContextProvider>
                    </AuthContextProvider>
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
