import React from 'react';
import { Box, Tab, Tabs, useMediaQuery } from '@mui/material';

import config from 'utils/config/config';
import TabPanel, { a11yProps } from 'components/table/TablePanel';

import BannerTab from '../banner/BannerTab';
import useAppConfigContext from 'pages/settings/provider/AppConfigContext';
import PaymentTermsTab from '../others/PaymentTermsTab';
import ContactTab from '../others/ContactTab';
import ExtraChargesTab from '../others/ExtraChargesTab';
import SideMenuTab from '../menu/SideMenuTab';
import OtherTab from '../others/OtherTab';

function ConfigLayout() {
    const isMobileUi = useMediaQuery('(max-width:600px)');

    const { platformSelected, handlePlatformTabChange, handleInnerTabChange, innerTabSelectedValue } = useAppConfigContext();

    const tabContainerAndView = () => {
        return (
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={platformSelected}
                        onChange={handlePlatformTabChange}
                        aria-label="basic tabs example"
                        variant="scrollable"
                        scrollButtons="auto"
                        textColor="secondary"
                        indicatorColor="secondary"
                    >
                        <Tab label="Android" {...a11yProps(config.platformOS.Android)} />
                        <Tab label="iOS" {...a11yProps(config.platformOS.iOS)} />
                        <Tab label="Web" {...a11yProps(config.platformOS.Web)} />
                    </Tabs>
                    <Tabs
                        value={innerTabSelectedValue}
                        onChange={handleInnerTabChange}
                        aria-label="basic tabs example"
                        variant="scrollable"
                        scrollButtons="auto"
                        textColor="secondary"
                        indicatorColor="secondary"
                    >
                        <Tab label="Banner" {...a11yProps(0)} />
                        <Tab label="Side Menu" {...a11yProps(1)} />
                        <Tab label="Extra Charges" {...a11yProps(2)} />
                        <Tab label="Contact" {...a11yProps(3)} />
                        <Tab label="Payment Terms" {...a11yProps(4)} />
                        <Tab label="Others" {...a11yProps(5)} />
                    </Tabs>
                </Box>
                <div>
                    <TabPanel value={innerTabSelectedValue} index={0}>
                        <BannerTab />
                    </TabPanel>
                    <TabPanel value={innerTabSelectedValue} index={1}>
                        <SideMenuTab />
                    </TabPanel>
                    <TabPanel value={innerTabSelectedValue} index={2}>
                        <ExtraChargesTab />
                    </TabPanel>
                    <TabPanel value={innerTabSelectedValue} index={3}>
                        <ContactTab />
                    </TabPanel>
                    <TabPanel value={innerTabSelectedValue} index={4}>
                        <PaymentTermsTab />
                    </TabPanel>
                    <TabPanel value={innerTabSelectedValue} index={5}>
                        <OtherTab />
                    </TabPanel>
                </div>
            </Box>
        );
    };
    return (
        <>
            {isMobileUi ? (
                <Box
                    sx={{
                        maxWidth: 400,
                        position: 'absolute',
                        flexGrow: 1,
                        display: 'flex',
                        left: 0,
                        right: 0,
                        flexDirection: 'column',
                        height: 'calc(100%)',
                        overflow: 'hidden',
                        bgcolor: 'background.default'
                    }}
                >
                    {tabContainerAndView()}
                </Box>
            ) : (
                tabContainerAndView()
            )}
        </>
    );
}

export default ConfigLayout;
