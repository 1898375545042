// assets
import { IconUser } from '@tabler/icons';

// constant
const icons = { IconUser };

// ==============================|| USER MANAGEMENT MENU ITEMS ||============================== //

const userManagement = {
    id: 'user-management',
    title: '',
    type: 'group',
    children: [
        {
            id: 'user-collapsable',
            title: 'User Management',
            type: 'item',
            icon: icons.IconUser,
            breadcrumbs: false,
            url: '/user-management'
        }
    ]
};

export default userManagement;
