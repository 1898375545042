import { Box, Drawer, IconButton, Tooltip, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import FilterProducts from './FilterProducts';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';

function QuotationFilterDialog({ showDrawer, closeDrawer, data, onFilterSelected, filterDefaultSelected }) {
    const isMobileUi = useMediaQuery('(max-width:600px)');
    const [filterSelected, setFilterSelected] = useState(null);

    return (
        <Drawer anchor={'right'} open={showDrawer} onClose={closeDrawer}>
            <Box style={{ width: isMobileUi ? '100%' : 400, display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        flex: 0,
                        mt: 5,
                        justifyContent: 'center',
                        mb: 2
                    }}
                >
                    <Typography
                        variant="h3"
                        sx={{ flex: 1, alignItems: 'center', display: 'flex', justifyContent: 'center', textTransform: 'uppercase' }}
                    >
                        Filter Products
                    </Typography>
                    <Tooltip title="Close" sx={{ mr: 2 }}>
                        <IconButton onClick={closeDrawer}>
                            <CloseIcon style={{ color: 'black' }} />
                        </IconButton>
                    </Tooltip>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        p: 1,
                        flex: 1,
                        mb: isMobileUi ? 2 : 0,
                        overflowY: 'scroll'
                    }}
                >
                    <FilterProducts
                        filterDefaultSelected={filterDefaultSelected}
                        filterList={data}
                        selectedIdCallback={(ids, subProductIds) => {
                            setFilterSelected({ cat_ids: ids, subProductIds: subProductIds });
                        }}
                    />
                </Box>
                <Box sx={{ flex: 0, p: isMobileUi ? 3 : 5, boxShadow: '0px -5px 5px -5px rgba(0,0,0,0.2)' }}>
                    <LoadingButton
                        variant="contained"
                        fullWidth
                        size="large"
                        onClick={() => {
                            onFilterSelected(filterSelected);
                        }}
                    >
                        Filter
                    </LoadingButton>
                </Box>
            </Box>
        </Drawer>
    );
}

export default QuotationFilterDialog;
