import { Box, Card, CardContent, Typography } from '@mui/material';
import CalendarSelector from 'components/Common/CalendarSelector';
import GraphLoadingWidget from 'components/Loading/GraphLoading';
import ServerError from 'components/ServerError/ServerError';
import React, { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import { formatServerDate, formatServerDateTimeStamp } from 'utils/miscellaneous';
import NoDataImage from 'assets/images/nodata.svg';

const chartData = {
    options: {
        chart: {
            id: 'Price-vs-Date',
            type: 'line',
            height: 350,
            zoom: {
                enabled: true
            },
            animations: {
                enabled: true
            },
            selection: {
                enabled: false
            }
        },
        xaxis: {
            type: 'datetime'
        },
        yaxis: {
            title: {
                text: 'Price'
            }
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            onItemClick: {
                toggleDataSeries: true
            }
        }
    }
};

function MultiSelectorGraph({
    data,
    isLoading,
    isError,
    retryCallback,
    onDateFetch,
    defaultDateSelection,
    dateSelection,
    defaultDateSelected,
    height = 350
}) {
    const chartRef = useRef(null);
    const [chartOptions, setChartOptions] = useState(chartData.options);
    const [chartSeries, setChartSeries] = useState([]);
    const [dateSelected, setDateSelected] = useState(null);

    useEffect(() => {
        const temp = {
            ...chartData.options,
            chart: {
                ...chartData.options.chart,
                height: height
            }
        };
        setChartOptions(temp);
    }, []);

    useEffect(() => {
        if (dateSelected === null && defaultDateSelected !== null) {
            setDateSelected({ startDate: defaultDateSelected.startDate, endDate: defaultDateSelected.endDate });
        }
    }, [defaultDateSelected]);

    useEffect(() => {
        if (data !== null) {
            try {
                // // Generate random colors for each rice name
                const colors = data && data.length > 0 ? data.map(() => `#${Math.floor(Math.random() * 16777215).toString(16)}`) : [];
                // // Convert data into series format expected by ApexCharts
                const series = data
                    ? data.map((riceData, index) => ({
                          name: riceData?.rice_name ? riceData.rice_name : '',
                          data: riceData?.data ? riceData.data.map((item) => [formatServerDateTimeStamp(item.date), item.price]) : [],
                          color: colors[index]
                      }))
                    : [];
                // // Update state with new series and options
                setChartSeries(series);
                setChartOptions((prevOptions) => ({
                    ...prevOptions,
                    colors
                }));
            } catch (error) {
                console.log(error);
            }
        } else {
            setChartOptions(chartData.options);
            setChartSeries([]);
        }
    }, [data]);

    return (
        <Box
            sx={{
                border: 0.5,
                borderRadius: { xs: 0, sm: 5, lg: 5 },
                marginTop: { xs: 5, sm: 2, lg: 2 },
                marginBottom: { xs: 5, sm: 4, lg: 5 },
                mb: 2,
                padding: { xs: 2, lg: 5 },
                borderColor: 'lightgrey'
            }}
        >
            {isLoading ? (
                <>
                    <GraphLoadingWidget />
                </>
            ) : isError ? (
                <>
                    <ServerError onCallback={retryCallback} />
                </>
            ) : (
                <>
                    <Typography sx={{ mt: 2, mb: 2 }} variant="h4">
                        Trading Price vs Time (Per MT)
                    </Typography>
                    <CalendarSelector
                        onSelect={(date) => {}}
                        onSubmit={(data) => {
                            if (data.startDate !== null && data.endDate !== null) {
                                onDateFetch(data);
                            }
                            setDateSelected(data);
                        }}
                        dateSelection={dateSelection}
                        defaultSelection={defaultDateSelection}
                    />
                    <div style={{ maxWidth: '100%', overflowX: 'auto', overflowY: 'hidden' }}>
                        {chartSeries.length === 0 ? (
                            <Card variant="outlined" sx={{}}>
                                <CardContent
                                    sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
                                >
                                    <img src={NoDataImage} alt="no-data-logo" width={100} />
                                    <Typography variant="h4" fontWeight={400} component="div" sx={{ mt: 2 }}>
                                        No data found!!
                                    </Typography>
                                </CardContent>
                            </Card>
                        ) : (
                            <Chart ref={chartRef} options={chartOptions} series={chartSeries} type="line" height={330} />
                        )}
                    </div>
                    <Typography sx={{ mt: 2, mb: 2, alignItems: 'center', display: 'flex', justifyContent: 'center' }} variant="h4">
                        {dateSelected !== null
                            ? formatServerDate(dateSelected.startDate) + ' - ' + formatServerDate(dateSelected.endDate)
                            : '-'}
                    </Typography>
                </>
            )}
        </Box>
    );
}

export default MultiSelectorGraph;
