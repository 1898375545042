import React, { createContext, useContext, useEffect } from 'react';
import config from 'utils/config/config';

const NotificationContext = createContext();

export const NotificationContextProvider = ({ children }) => {
    const defaultUserTypes = [
        { name: 'Buyer', value: config.userRoles.user },
        { name: 'Seller', value: config.userRoles.seller },
        { name: 'Guest', value: config.userRoles.guest }
    ];

    useEffect(() => {}, []);

    return <NotificationContext.Provider value={{ defaultUserTypes }}>{children}</NotificationContext.Provider>;
};

export default function useNotificationContext() {
    return useContext(NotificationContext);
}
