import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import Lottie from 'lottie-react';
import NoDataAnimation from 'assets/lottie/nodata';
import NoDataImage from 'assets/images/nodata.svg';

function NoDatWidget({ buttonLabel, onCallback }) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                {/* <Lottie animationData={NoDataAnimation} style={{ height: 200 }} />
                <Typography>No Data Found!!</Typography> */}

                <img src={NoDataImage} alt="no-data-logo" width={200} />

                <Button variant="contained" sx={{ mt: 2 }} onClick={onCallback}>
                    {buttonLabel}
                </Button>
            </Box>
        </Box>
    );
}

export default NoDatWidget;
