import React from 'react';
import useNotificationContext from '../provider/NotificationContext';
import {
    Box,
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from '@mui/material';
import AutoCompleteSearchList from './AutoCompleteSearchList';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

function StepperTargetUserForm({
    userTypeSelected,
    setUserTypeSelected,
    usersListSelected,
    setUserListSelected,
    userSelectionType,
    setUserSelectionType
}) {
    const { defaultUserTypes } = useNotificationContext();

    const handleToggleChange = (event, data) => {
        if (data !== null) {
            setUserSelectionType(data);
        }
    };

    function findNameById(id) {
        const item = defaultUserTypes.find((item) => item.value === id);
        return item ? item.name : '';
    }

    const handleChange = (event) => {
        const {
            target: { value }
        } = event;

        setUserTypeSelected(typeof value === 'string' ? value.split(',') : value);
    };
    return (
        <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
            <Grid item xs={12} md={12}>
                <Typography variant="h5">Select Target user</Typography>
                <ToggleButtonGroup
                    sx={{ mt: 2, fontSize: 12 }}
                    color="secondary"
                    value={userSelectionType}
                    exclusive
                    onChange={handleToggleChange}
                    aria-label="target-users"
                >
                    <ToggleButton sx={{ fontSize: 12 }} value="multi">
                        Multi user{' '}
                    </ToggleButton>
                    <ToggleButton sx={{ fontSize: 12 }} value="user-select">
                        Select from Existing Users
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} md={12}>
                <Box sx={{}}>
                    {userSelectionType === 'multi' ? (
                        <FormControl sx={{ mt: 2, display: 'flex', flex: 1 }}>
                            <InputLabel id="multiple-user-typ-checkbox-label">Select User</InputLabel>
                            <Select
                                labelId="multiple-user-type-checkbox-label"
                                id="multiple-user-type-checkbox"
                                multiple
                                value={userTypeSelected}
                                onChange={handleChange}
                                input={<OutlinedInput label="Select User" />}
                                renderValue={(selected) => selected.map((id) => findNameById(id)).join(',')}
                                MenuProps={MenuProps}
                            >
                                {defaultUserTypes.map((d) => (
                                    <MenuItem key={d.name} value={d.value}>
                                        <Checkbox checked={userTypeSelected.indexOf(d.value) > -1} />
                                        <ListItemText primary={d.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : (
                        <FormControl sx={{ mt: 1, display: 'flex', flex: 1 }}>
                            <AutoCompleteSearchList
                                setUserListSelected={setUserListSelected}
                                usersListSelected={usersListSelected}
                                clearSearchItemCallback={(data) => {
                                    const updatedItems = usersListSelected.filter((item) => item.id !== data.id);
                                    setUserListSelected(updatedItems);
                                }}
                            />
                        </FormControl>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
}

export default StepperTargetUserForm;
