import { Box, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import ServerError from 'components/ServerError/ServerError';
import GraphLoadingWidget from 'components/Loading/GraphLoading';

const chartData = {
    options: {
        chart: {
            id: 'quotation-count-vs-rice-id',
            zoom: {
                enabled: true
            },
            animations: {
                enabled: true
            },
            selection: {
                enabled: true
            }
        },
        xaxis: {
            title: {
                text: 'Rice Name'
            },
            categories: []
        },
        yaxis: {
            title: {
                text: 'Quotation Count'
            }
        },
        tooltip: {
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: function (seriesName) {
                        return 'Quotation Count';
                    }
                }
            },
            marker: {
                show: false
            },
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const category = w.globals.labels[dataPointIndex];
                const selectableInfo = category;
                const count = series[seriesIndex][dataPointIndex];
                return (
                    '<div class="apexcharts-tooltip-custom">' +
                    '<div>' +
                    '<span>' +
                    '<span class="tooltip-title">' +
                    'Quotation Count:' +
                    '</span>' +
                    count +
                    '</span>' +
                    '</div>' +
                    '<span class="apexcharts-tooltip-custom-selectable">' +
                    selectableInfo +
                    '</span>' +
                    '</div>'
                );
            }
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    style: {
                        colors: ['#121111'] // set the text color to white
                    }
                }
            }
        },
        colors: ['#119A41']
    },
    series: [
        {
            name: 'Quotation Count',
            data: []
        }
    ]
};

function QuotationGraph({ data, isLoading, isError, retryCallback, key, ref, isNoHeading = false }) {
    const [chartOptions, setChartOptions] = useState(chartData.options);
    const [chartSeries, setChartSeries] = useState(chartData.series);
    const chartRef = useRef(null);

    useEffect(() => {
        if (chartRef.current) {
            // Set the chart width to the width of its container
            setChartOptions((options) => ({
                ...options,
                chart: {
                    ...options.chart,
                    width: chartRef.current.offsetWidth
                }
            }));
        }
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            const sortedData = data.sort((a, b) => b.count - a.count); // sort data array in descending order based on count property
            const countListData = sortedData.map((item) => item.count);
            const categories = sortedData.map((item) => item.rice_name);

            const newChartDta = {
                ...chartData.options,
                xaxis: {
                    ...chartData.options.xaxis,
                    categories: categories
                }
            };

            const newChartSeries = [
                {
                    name: 'Quotation Count',
                    data: countListData
                }
            ];

            setChartOptions(newChartDta);
            setChartSeries(newChartSeries);
        } else {
            const blankChartOptions = {
                ...chartData.options,
                xaxis: {
                    ...chartData.options.xaxis,
                    categories: []
                }
            };

            const blankChartSeries = [
                {
                    name: 'Quotation Count',
                    data: []
                }
            ];

            setChartOptions(blankChartOptions);
            setChartSeries(blankChartSeries);
        }
    }, [data]);

    return (
        <Box
            ref={ref}
            key={key}
            sx={{
                border: 0.5,
                borderRadius: { xs: 0, sm: 5, lg: 5 },
                marginTop: { xs: 5, sm: 2, lg: 2 },
                marginBottom: { xs: 5, sm: 4, lg: 5 },
                mb: 2,
                padding: { xs: 2, lg: 5 },
                borderColor: 'lightgrey'
            }}
        >
            {isLoading ? (
                <>
                    <GraphLoadingWidget />
                </>
            ) : isError ? (
                <>
                    <ServerError onCallback={retryCallback} />
                </>
            ) : (
                <>
                    <Typography sx={{ mt: 2, mb: 2, display: isNoHeading ? 'none' : 'unset' }} variant="h4">
                        Rice Vs Quotation
                    </Typography>
                    <div style={{ maxWidth: '100%', overflowX: 'auto', overflowY: 'hidden' }}>
                        <Chart ref={chartRef} options={chartOptions} series={chartSeries} type="bar" height={330} />
                    </div>
                </>
            )}
        </Box>
    );
}

export default QuotationGraph;
