import useFeedbackContext from 'contexts/FeedbackContext';
import useAxios from 'hooks/useAxios';
import React, { createContext, useContext, useEffect } from 'react';
import config from 'utils/config/config';
import {
    ADD_ADDITIONAL_CONFIG,
    GET_ADDITIONAL_CONFIG,
    GET_BANNER_CONFIG,
    GET_MENU_CONFIG,
    UPDATE_MENU_CONFIG,
    UPDATE_ORDER_BANNER_CONFIG
} from 'utils/config/endpoints';
import { OK } from 'utils/config/response-codes';
import { ToastMessageType } from 'utils/constants';

const AppConfigContext = createContext();

export const AppConfigContextProvider = ({ children }) => {
    const [platformSelected, setPlatformSelected] = React.useState(config.platformOS.Android);
    const [isBannerSaveLoading, setIsBannerSaveLoading] = React.useState(false);
    const [isSideMenuSaveLoading, setIsSideMenuSaveLoading] = React.useState(false);
    const [isPaymentTermsSaveLoading, setIsPaymentTermsSaveLoading] = React.useState(false);
    const [isLoadAdditionalInfo, setIsLoadAdditionalInfo] = React.useState(false);
    const [isOthersSaveLoading, setIsOthersSaveLoading] = React.useState(false);

    const [selectedTabAppBannerList, setSelectedTabAppBannerList] = React.useState([]);
    const [selectedTabAppMenuList, setSelectedTabMenuList] = React.useState([]);
    const [selectedTabOtherAppConfig, setSelectedTabOtherAppConfig] = React.useState(null);
    const [innerTabSelectedValue, setValue] = React.useState(0);

    const { showToastMessage } = useFeedbackContext();
    const { axiosInstance } = useAxios();

    const handlePlatformTabChange = (e, newValue) => {
        setPlatformSelected(newValue);
    };
    const handleInnerTabChange = (e, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (innerTabSelectedValue === 0) {
            setSelectedTabAppBannerList([]);
            setIsBannerSaveLoading(true);
            getBannerConfig();
        } else if (innerTabSelectedValue === 1) {
            setIsSideMenuSaveLoading(true);
            setSelectedTabMenuList([]);
            getMenuConfig();
        } else {
            console.log('innerTabSelectedValue getAdditionalConfig');
            setIsLoadAdditionalInfo(true);
            setSelectedTabOtherAppConfig(null);
            getAdditionalConfig();
        }
    }, [platformSelected, innerTabSelectedValue]);

    const onBannerSaveCallback = () => {
        setIsBannerSaveLoading(true);
    };

    const onSideMenuTabSaveCallback = () => {
        setIsSideMenuSaveLoading(true);
    };

    const onPaymentTermsSaveCallback = () => {
        setIsPaymentTermsSaveLoading(true);
    };

    const onOthersTabSaveCallback = () => {
        setIsOthersSaveLoading(true);
    };

    const getBannerConfig = () => {
        axiosInstance
            .get(GET_BANNER_CONFIG, {
                params: {
                    type: platformSelected
                }
            })
            .then((response) => {
                setIsBannerSaveLoading(false);
                if (response.data.response_code === OK) {
                    setSelectedTabAppBannerList(response.data.data);
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch((error) => {
                setIsBannerSaveLoading(false);
                showToastMessage('Something went wrong', ToastMessageType.ERROR);
            });
    };

    const getMenuConfig = () => {
        axiosInstance
            .get(GET_MENU_CONFIG, {
                params: {
                    type: platformSelected
                }
            })
            .then((response) => {
                setIsSideMenuSaveLoading(false);
                if (response.data.response_code === OK) {
                    const tempMenuList = response.data.data;
                    const sortedList = sortByOrderSeq(tempMenuList);
                    setSelectedTabMenuList(sortedList);
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch((error) => {
                setIsSideMenuSaveLoading(false);
                showToastMessage('Something went wrong', ToastMessageType.ERROR);
            });
    };

    const sortByOrderSeq = (list) => {
        return list.sort((a, b) => a.order_seq - b.order_seq);
    };

    const getAdditionalConfig = () => {
        axiosInstance
            .get(GET_ADDITIONAL_CONFIG, {
                params: {
                    type: platformSelected
                }
            })
            .then((response) => {
                setIsSideMenuSaveLoading(false);
                if (response.data.response_code === OK) {
                    setSelectedTabOtherAppConfig(response.data.data);
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch((error) => {
                setIsSideMenuSaveLoading(false);
                showToastMessage('Something went wrong', ToastMessageType.ERROR);
            });
    };

    const updateBannerPosition = (newItems) => {
        const updatedPosList = newItems.reduce((acc, item, index) => {
            acc[item.id] = index + 1;
            return acc;
        }, {});
        axiosInstance
            .post(UPDATE_ORDER_BANNER_CONFIG, updatedPosList)
            .then((response) => {
                if (response.data.response_code === OK) {
                    setSelectedTabAppBannerList(newItems);
                    showToastMessage('Item position updated!!');
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch((error) => {
                showToastMessage('Something went wrong', ToastMessageType.ERROR);
            });
    };

    const updateMenuPosition = (newItems) => {
        const updatedPosList = newItems.reduce((acc, item, index) => {
            acc[item.id] = index + 1;
            return acc;
        }, {});

        axiosInstance
            .post(UPDATE_MENU_CONFIG, updatedPosList)
            .then((response) => {
                if (response.data.response_code === OK) {
                    setSelectedTabMenuList(newItems);
                    showToastMessage('Item position updated!!');
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch((error) => {
                showToastMessage('Something went wrong', ToastMessageType.ERROR);
            });
    };

    const createAdditionalConfig = (data) => {
        setIsOthersSaveLoading(true);

        const tempConfig = {
            ...selectedTabOtherAppConfig,
            ...data,
            type: platformSelected
        };
        axiosInstance
            .post(ADD_ADDITIONAL_CONFIG, {
                ...tempConfig
            })
            .then((response) => {
                setIsOthersSaveLoading(false);
                if (response.data.response_code === OK) {
                    setSelectedTabOtherAppConfig(tempConfig);
                    showToastMessage('Config updated!!');
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch((error) => {
                setIsOthersSaveLoading(false);
                showToastMessage('Something went wrong', ToastMessageType.ERROR);
            });
    };

    return (
        <AppConfigContext.Provider
            value={{
                platformSelected,
                selectedTabAppBannerList,
                selectedTabAppMenuList,
                isBannerSaveLoading,
                isSideMenuSaveLoading,
                isOthersSaveLoading,
                isLoadAdditionalInfo,
                innerTabSelectedValue,
                selectedTabOtherAppConfig,
                handlePlatformTabChange,
                handleInnerTabChange,
                getBannerConfig,
                getMenuConfig,
                getAdditionalConfig,
                updateBannerPosition,
                updateMenuPosition,
                createAdditionalConfig
            }}
        >
            {children}
        </AppConfigContext.Provider>
    );
};

export default function useAppConfigContext() {
    return useContext(AppConfigContext);
}
