import { useTheme } from '@emotion/react';
import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import NavigationMenuAddEditDrawer from '../../components/NavigationMenuAddEditDrawer';
import DeleteDialogConfirmation from 'components/Dialogs/DeleteDialogConfirmation';
import DraggableNavigationMenu from '../../components/DraggableNavigationMenu';
import { reorder } from 'components/DraggingList/helper';
import useFeedbackContext from 'contexts/FeedbackContext';
import useAppConfigContext from 'pages/settings/provider/AppConfigContext';
import useAxios from 'hooks/useAxios';
import { ADD_MENU_CONFIG, DELETE_MENU_CONFIG } from 'utils/config/endpoints';
import { OK } from 'utils/config/response-codes';
import { ToastMessageType } from 'utils/constants';
import MenuLoading from 'components/Loading/MenuLoading';

export default function SideMenuTab() {
    const theme = useTheme();
    const [menuItems, setMenuItems] = useState([]);
    const [isShowMenuAddEditPopup, setShowMenuAddEditPopup] = useState(false);
    const [isShowMenuDeletePopup, setShowMenuDeletePopup] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState(null);
    const { showToastMessage, hideLoader, showLoader } = useFeedbackContext();
    const { platformSelected, isSideMenuSaveLoading, selectedTabAppMenuList, updateMenuPosition, getMenuConfig } = useAppConfigContext();
    const { axiosInstance } = useAxios();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setMenuItems(selectedTabAppMenuList);
    }, [selectedTabAppMenuList]);

    const updateAddLogic = (data, isAdd) => {
        setIsLoading(true);
        console.log(`data ${isAdd} ${JSON.stringify(data)}`);
        if (isAdd) {
            axiosInstance
                .post(ADD_MENU_CONFIG, {
                    ...data,
                    order_seq: selectedTabAppMenuList.length + 1,
                    type: platformSelected
                })
                .then((response) => {
                    setIsLoading(false);
                    if (response.data.response_code === OK) {
                        showToastMessage('Successfully Added New Menu Item!!', ToastMessageType.SUCCESS);
                        getMenuConfig();
                        setSelectedMenuItem(null);
                    } else {
                        showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    showToastMessage('Something went wrong', ToastMessageType.ERROR);
                });
        } else {
            axiosInstance
                .post(ADD_MENU_CONFIG, {
                    ...data
                })
                .then((response) => {
                    setIsLoading(false);
                    if (response.data.response_code === OK) {
                        showToastMessage('Successfully Updated!!', ToastMessageType.SUCCESS);
                        getMenuConfig();
                        setSelectedMenuItem(null);
                    } else {
                        showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    showToastMessage('Something went wrong', ToastMessageType.ERROR);
                });
        }
    };

    const deleteSideMenuItem = () => {
        console.log('deleteSideMenuItem');

        if (selectedMenuItem !== undefined) {
            showLoader();
            axiosInstance
                .delete(DELETE_MENU_CONFIG + '/' + selectedMenuItem['id'])
                .then((response) => {
                    setShowMenuDeletePopup(false);
                    hideLoader();
                    if (response.data.response_code === OK) {
                        setSelectedMenuItem(null);
                        showToastMessage('Successfully Deleted!!', ToastMessageType.SUCCESS);
                        getMenuConfig();
                    } else {
                        showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                    }
                })
                .catch((error) => {
                    hideLoader();
                    showToastMessage('Something went wrong', ToastMessageType.ERROR);
                });
        }
    };

    const onDragEnd = ({ destination, source }) => {
        // dropped outside the list
        if (!destination) return;
        const newItems = reorder(menuItems, source.index, destination.index);
        updateMenuPosition(newItems);
    };

    return (
        <div>
            <Box
                sx={{
                    backgroundColor: theme.palette.secondary.light,
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
            >
                <Typography sx={{ flex: 1 }} variant="h4">
                    Navigation Menu
                </Typography>
                <Button
                    sx={{ height: 30, width: 40, marginLeft: 2, mt: 1, color: theme.palette.secondary.main, fontSize: 24 }}
                    variant="text"
                    onClick={(e) => {
                        setSelectedMenuItem(null);
                        setShowMenuAddEditPopup(true);
                    }}
                >
                    +
                </Button>
            </Box>

            {isSideMenuSaveLoading ? (
                <MenuLoading />
            ) : (
                <Box sx={{ mt: 2, border: 0.2, p: 2, borderColor: 'grey' }}>
                    <DraggableNavigationMenu
                        items={menuItems}
                        onDragEnd={onDragEnd}
                        onDeleteItem={(itemData) => {
                            setSelectedMenuItem(itemData);
                            setShowMenuDeletePopup(true);
                        }}
                        onEditItem={(itemData) => {
                            console.log('itemData', itemData);
                            setSelectedMenuItem(itemData);
                            setShowMenuAddEditPopup(true);
                        }}
                    />
                </Box>
            )}

            <DeleteDialogConfirmation
                deleteCallback={() => {
                    deleteSideMenuItem();
                }}
                cancelCallback={() => {
                    setShowMenuDeletePopup(false);
                }}
                msg="Do you want to delete this item?"
                showDeleteConfirmation={isShowMenuDeletePopup}
            />

            <NavigationMenuAddEditDrawer
                showDrawer={isShowMenuAddEditPopup}
                isLoading={isLoading}
                closeDrawer={() => {
                    setSelectedMenuItem(null);
                    setShowMenuAddEditPopup(false);
                }}
                isEdit={selectedMenuItem !== null}
                data={selectedMenuItem}
                onAddCallback={(dataDrawer) => {
                    updateAddLogic(dataDrawer, true);
                    setShowMenuAddEditPopup(false);
                }}
                onEditCallback={(dataDrawer) => {
                    setSelectedMenuItem(null);
                    updateAddLogic(dataDrawer, false);
                    setShowMenuAddEditPopup(false);
                }}
            />
        </div>
    );
}
