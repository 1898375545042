import { Draggable } from 'react-beautiful-dnd';
import React from 'react';
import ItemNavigationMenu from './ItemNavigationMenu';

const DraggableNavigationMenuItem = ({ item, index, onDeleteItem, onEditItem }) => {
    return (
        <Draggable draggableId={item.id.toString()} index={index}>
            {(provided, snapshot) => (
                <ItemNavigationMenu
                    ref={provided.innerRef}
                    draggableProps={provided.draggableProps}
                    dragHandleProps={provided.dragHandleProps}
                    menuData={item}
                    isDragging={snapshot.isDragging}
                    itemEditCallback={onEditItem}
                    itemDeleteCallback={onDeleteItem}
                />
            )}
        </Draggable>
    );
};

export default DraggableNavigationMenuItem;
