import { Box, Fab, Grid, Tab, Tabs, TextField, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import config from 'utils/config/config';
import AddWeightDrawer from './AddWeightDrawer';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { useDemoData } from '@mui/x-data-grid-generator';
import DynamicTableSelection from 'components/Common/DynamicTableSelection';
import TabPanel, { a11yProps } from 'components/table/TablePanel';
import { DELETE_PACKAGING_KG, DELETE_PACKAGING_LBS } from 'utils/config/endpoints';
import useAxios from 'hooks/useAxios';
import { ToastMessageType } from 'utils/constants';
import { OK } from 'utils/config/response-codes';
import useFeedbackContext from 'contexts/FeedbackContext';

function PackagingAddEditSideDrawer(props) {
    const {
        window,
        showDrawer,
        closeDrawer,
        isLoading = false,
        isEdit = false,
        onAddCallback,
        onEditCallback,
        dataList,
        configDrawer
    } = props;

    const [isButtonEnable, setButtonEnable] = useState(false);
    const defaultFormValue = { id: '', title: '', kgsWeightItem: [], lbsWeightItem: [] };
    const [dataDrawer, setDataDrawer] = useState(defaultFormValue);
    const [value, setValue] = React.useState(0);
    const [weightPopupDialog, setWeightPopupDialog] = React.useState(false);

    const label = isEdit ? 'Edit Packaging' : 'Add Packaging';
    const [showAddWeightSideDrawer, setShowAddWeightSideDrawer] = useState(false);
    const isMobileUi = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width:600px) and (max-width: 991px)');
    const [weightSelectedData, setWeightSelectedData] = React.useState(null);
    const { axiosInstance, loading, response, error } = useAxios();
    const { showToastMessage } = useFeedbackContext();

    const columns = [
        { field: 'id', headerName: 'ID', hide: true },
        { field: 'weight', headerName: 'Weight', editable: true },
        { field: 'price', headerName: 'Price', editable: true }
    ];

    const { data } = useDemoData({
        dataSet: 'Commodity',
        rowLength: 10,
        editable: true
    });

    useEffect(() => {
        if (dataList === null) {
            resetForm();
        } else {
            console.log('Data', dataList);
            setDataDrawer({
                id: dataList.id,
                title: dataList.title,
                kgsWeightItem: dataList.kgsWeightItem,
                lbsWeightItem: dataList.lbsWeightItem
            });
        }
    }, [dataList]);

    useEffect(() => {
        if (dataDrawer.title.length > 0 && dataDrawer.title.length <= config.maxDefaultInputLength) {
            setButtonEnable(true);
        } else {
            setButtonEnable(false);
        }
    }, [dataDrawer]);

    const headCells = [
        {
            id: 'id',
            headCellStyle: { display: 'none' },
            tableCellStyle: { display: 'none' },
            drawerFieldStyle: { display: 'none' }
        },
        {
            id: 'weight',
            disablePadding: false,
            label: 'Weight(Kg)',
            mandatory: true,
            maxLength: 0
        },
        {
            id: 'price',
            numeric: true,
            disablePadding: false,
            label: 'Price($)',
            mandatory: true,
            maxLength: 0
        }
    ];

    const headCellsLbs = [
        {
            id: 'id',
            headCellStyle: { display: 'none' },
            tableCellStyle: { display: 'none' },
            drawerFieldStyle: { display: 'none' }
        },
        {
            id: 'weight',
            disablePadding: false,
            label: 'Weight(Lbs)',
            mandatory: true,
            maxLength: 0
        },
        {
            id: 'price',
            numeric: true,
            disablePadding: false,
            label: 'Price($)',
            mandatory: true,
            maxLength: 0
        }
    ];

    const tableConfig = {
        noDataMessage: 'No Weights Added',
        addButtonLabel: 'Add Weights',
        isActionEdit: true
    };

    const handleInputFiled = (event) => {
        const inputName = event.target.name;
        const inputValue = event.target.value;
        const pattern = /^[0-9, ]*$/;

        if (inputName === 'title') {
            if (inputValue.length < config.maxDefaultInputLength) {
                setDataDrawer({ ...dataDrawer, [inputName]: inputValue });
            }
        } else {
            if (inputValue.length <= config.maxWeightsInputLength && pattern.test(inputValue)) {
                var isValidChar = true;
                if (inputValue !== ',') {
                    var splitChar = inputValue.split(',');
                    var lastChar = splitChar.length > 0 ? splitChar[splitChar.length - 1] : splitChar[0];
                    if (lastChar.length > config.maxWeightsLength) isValidChar = false;
                }
                if (isValidChar) {
                    setDataDrawer({ ...dataDrawer, [inputName]: inputValue });
                }
            }
        }
    };

    const resetForm = () => {
        setDataDrawer(defaultFormValue);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const deleteLogic = (items, tableType) => {
        if (tableType === 'kg') {
            axiosInstance
                .delete(DELETE_PACKAGING_KG, { data: items })
                .then((response) => {
                    if (response.data.response_code === OK) {
                        const newData = dataDrawer.kgsWeightItem.filter((item) => !items.includes(item.id));
                        setDataDrawer({ ...dataDrawer, kgsWeightItem: newData });
                        showToastMessage('KG Deleted', ToastMessageType.SUCCESS);
                    } else {
                        showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                    }
                })
                .catch(() => {});
        } else {
            axiosInstance
                .delete(DELETE_PACKAGING_LBS, { data: items })
                .then((response) => {
                    if (response.data.response_code === OK) {
                        const newData = dataDrawer.lbsWeightItem.filter((item) => !items.includes(item.id));
                        setDataDrawer({ ...dataDrawer, lbsWeightItem: newData });
                        showToastMessage('Lbs Deleted', ToastMessageType.SUCCESS);
                    } else {
                        showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                    }
                })
                .catch(() => {});
        }
    };

    const savePackaging = () => {
        onEditCallback(dataDrawer);
    };

    const updateAddLogic = (editAddData) => {
        let newDataValues = dataDrawer;
        if (editAddData !== null) {
            newDataValues = addOrEditWeightKgData(newDataValues, editAddData);
            setDataDrawer(newDataValues);
            setWeightSelectedData(null);
        }
    };

    function addOrEditWeightKgData(data, editAddData) {
        const newData = JSON.parse(JSON.stringify(data)); // make a deep copy of the data to avoid modifying the original data
        const keyEdit = editAddData.name === 'kg' ? 'kgsWeightItem' : 'lbsWeightItem';

        const index = newData[keyEdit].findIndex((item) => item.id === editAddData.weight.id); // find the index of the item to edit

        if (index === -1) {
            // item not found, add new item
            const newId = newData[keyEdit].length > 0 ? newData[keyEdit][newData[keyEdit].length - 1].id + 1 : 1; // generate a new id for the new item
            newData[keyEdit].push({ id: '', ...editAddData.weight }); // add the new item to the array
        } else {
            // item found, edit existing item
            newData[keyEdit][index] = { ...newData[keyEdit][index], ...editAddData.weight }; // merge the new data with the existing item
        }
        return newData;
    }

    return (
        <>
            <Grid container spacing={5}>
                <Grid item xs={12} md={12}>
                    <TextField name="title" label="Enter Packaging" value={dataDrawer.title} onChange={handleInputFiled} fullWidth />
                </Grid>

                <Grid item xs={12} md={12}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            variant="scrollable"
                            scrollButtons="auto"
                            textColor="secondary"
                            indicatorColor="secondary"
                        >
                            <Tab label="Weight(kg)" {...a11yProps(0)} />
                            <Tab label="Weight(lbs)" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <DynamicTableSelection
                            data={dataDrawer.kgsWeightItem}
                            headCells={headCells}
                            tableConfig={tableConfig}
                            onEditClickCallback={(id) => {
                                const weightSelected = dataDrawer.kgsWeightItem.filter((item) => item.id === id);
                                setWeightSelectedData({ weight: 'kg', data: weightSelected[0] });
                                setWeightPopupDialog(true);
                                setShowAddWeightSideDrawer(true);
                            }}
                            onDeleteClickCallback={(items) => deleteLogic(items, 'kg')}
                            isLoading={false} //is data loading from server?
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <DynamicTableSelection
                            data={dataDrawer.lbsWeightItem}
                            headCells={headCellsLbs}
                            tableConfig={tableConfig}
                            onEditClickCallback={(id) => {
                                const weightSelected = dataDrawer.lbsWeightItem.filter((item) => item.id === id);
                                setWeightSelectedData({ weight: 'lbs', data: weightSelected[0] });
                                setWeightPopupDialog(true);
                                setShowAddWeightSideDrawer(true);
                            }}
                            onDeleteClickCallback={(items) => deleteLogic(items, 'lbs')}
                            isLoading={false} //is data loading from server?
                        />
                    </TabPanel>
                </Grid>
            </Grid>

            <AddWeightDrawer
                data={weightSelectedData}
                showDrawer={showAddWeightSideDrawer}
                closeDrawer={() => {
                    setShowAddWeightSideDrawer(false);
                }}
                isEdit={weightPopupDialog}
                onAddCallback={(dataDrawer) => {
                    setShowAddWeightSideDrawer(false);
                    updateAddLogic(dataDrawer);
                    setWeightSelectedData(null);
                }}
                onEditCallback={(dataDrawer) => {
                    setShowAddWeightSideDrawer(false);
                    updateAddLogic(dataDrawer);
                    setWeightSelectedData(null);
                }}
            />

            <Box
                sx={{
                    position: 'fixed',
                    bottom: isMobileUi ? 30 : isTablet ? 40 : 50,
                    right: isMobileUi ? 30 : isTablet ? 40 : 50,
                    display: 'flex',
                    flexDirection: isMobileUi ? 'column' : ''
                }}
            >
                <Fab
                    color="primary"
                    aria-label="add"
                    variant={isMobileUi ? 'circular' : 'extended'}
                    sx={{ mb: isMobileUi ? 2 : 0, mr: isMobileUi ? 0 : 2 }}
                    onClick={() => {
                        setWeightSelectedData(null);
                        setWeightPopupDialog(false); // Add Flow
                        setShowAddWeightSideDrawer(true);
                    }}
                >
                    <AddIcon />
                    {isMobileUi ? '' : 'ADD'}
                </Fab>

                <Fab
                    color="success"
                    disabled={!isButtonEnable}
                    aria-label="save"
                    variant={isMobileUi ? 'circular' : 'extended'}
                    onClick={savePackaging}
                >
                    <SaveIcon sx={{ mr: isMobileUi ? 0 : 1 }} />
                    {isMobileUi ? '' : 'Save'}
                </Fab>
            </Box>
        </>
    );
}

export default PackagingAddEditSideDrawer;
