import { Box, Fab, useMediaQuery } from '@mui/material';
import DraggableList from 'components/DraggingList/DraggingList';
import { reorder } from 'components/DraggingList/helper';
import React, { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import BannerListLoading from 'components/Loading/BannerListLoading';
import NoDatWidget from 'components/NoData';
import useFeedbackContext from 'contexts/FeedbackContext';
import DeleteDialogConfirmation from 'components/Dialogs/DeleteDialogConfirmation';
import { useLocation, useNavigate } from 'react-router-dom';
import { ADD_BANNER_CONFIG } from 'utils/config/endpoints';
import { ToastMessageType } from 'utils/constants';
import { OK } from 'utils/config/response-codes';
import useAxios from 'hooks/useAxios';
import useAppConfigContext from 'pages/settings/provider/AppConfigContext';

function BannerTab() {
    const [items, setItems] = React.useState([]);
    const [isDeleteDialogShow, setDeleteDialogShow] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState(null);
    const navigate = useNavigate();
    const { state } = useLocation();

    const { showToastMessage } = useFeedbackContext();
    const isMobileUi = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width:600px) and (max-width: 991px)');

    const { platformSelected, isBannerSaveLoading, selectedTabAppBannerList, updateBannerPosition } = useAppConfigContext();

    console.log('useLocation', state);

    useEffect(() => {
        console.log('useLocation', state);
    }, [state]);

    useEffect(() => {
        setItems(selectedTabAppBannerList);
    }, [selectedTabAppBannerList]);

    const onDragEnd = ({ destination, source }) => {
        // dropped outside the list
        if (!destination) return;
        const newItems = reorder(items, source.index, destination.index);
        updateBannerPosition(newItems);
    };

    return (
        <>
            {isBannerSaveLoading ? (
                <>
                    <BannerListLoading />
                </>
            ) : (
                <>
                    <Box sx={{ height: '100%', overflowY: 'auto' }}>
                        {items.length > 0 ? (
                            <>
                                <DraggableList
                                    items={items}
                                    onDragEnd={onDragEnd}
                                    onItemClick={(itemData) => {
                                        navigate('/settings/app-banner', {
                                            state: { data: itemData, type: platformSelected, length: items.length }
                                        });
                                    }}
                                    onDeleteItem={(id) => {
                                        const result = items.find((item) => item.id === id);
                                        setSelectedItem(result);
                                        setDeleteDialogShow(true);
                                    }}
                                />
                                <Box sx={{ height: 10, mt: 10, mb: 10 }} />
                                <Fab
                                    sx={{
                                        position: 'fixed',
                                        bottom: isMobileUi ? 30 : isTablet ? 40 : 50,
                                        right: isMobileUi ? 30 : isTablet ? 40 : 100
                                    }}
                                    onClick={() => {
                                        navigate('/settings/app-banner', {
                                            state: { data: null, type: platformSelected, length: items.length }
                                        });
                                    }}
                                    color="primary"
                                    aria-label="add"
                                    variant={isMobileUi ? 'circular' : 'extended'}
                                >
                                    <AddIcon />
                                    {isMobileUi ? '' : 'ADD'}
                                </Fab>
                            </>
                        ) : (
                            <NoDatWidget
                                buttonLabel={'Add Banner'}
                                onCallback={() => {
                                    navigate('/settings/app-banner', {
                                        state: { data: null, type: platformSelected, length: items.length }
                                    });
                                }}
                            />
                        )}
                    </Box>
                </>
            )}

            <DeleteDialogConfirmation
                deleteCallback={() => {
                    const newListData = items.filter((item) => item.id !== selectedItem.id);
                    setItems(newListData);
                    setDeleteDialogShow(false);
                }}
                cancelCallback={() => {
                    setDeleteDialogShow(false);
                }}
                msg="Do you want to delete this Banner?"
                showDeleteConfirmation={isDeleteDialogShow}
            />
        </>
    );
}

export default BannerTab;
