import React, { useEffect, useState } from 'react';

import { Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { validateEmail } from 'utils/miscellaneous';
import useAppConfigContext from 'pages/settings/provider/AppConfigContext';

function ContactTab() {
    const [isButtonEnable, setIsButtonEnable] = useState(false);

    const [selectedOtherTabData, setSelectedOtherTabData] = useState({
        customerCareNumber: '',
        mailId: '',
        customerCareTime: ''
    });
    const { isOthersSaveLoading, selectedTabOtherAppConfig, createAdditionalConfig } = useAppConfigContext();

    const isMobileUi = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width:600px) and (max-width: 991px)');

    const handleFormEvent = (e) => {
        var id = e.target.id;
        var value = e.target.value;
        if (id === 'customerCareNumber') {
            setSelectedOtherTabData({ ...selectedOtherTabData, [id]: value === '' ? '' : parseInt(value) });
        } else {
            setSelectedOtherTabData({ ...selectedOtherTabData, [id]: value });
        }
    };

    useEffect(() => {
        if (selectedTabOtherAppConfig !== null) {
            setSelectedOtherTabData({
                customerCareNumber: selectedTabOtherAppConfig.customerCareNumber ?? '',
                mailId: selectedTabOtherAppConfig.mailId ?? '',
                customerCareTime: selectedTabOtherAppConfig.customerCareTime ?? ''
            });
        }
    }, [selectedTabOtherAppConfig]);

    useEffect(() => {
        if (validateEmail(selectedOtherTabData.mailId) && selectedOtherTabData.customerCareNumber.toString().length > 4) {
            setIsButtonEnable(true);
        } else {
            setIsButtonEnable(false);
        }
    }, [selectedOtherTabData]);

    const addAdditionalData = () => {
        createAdditionalConfig(selectedOtherTabData);
    };

    return (
        <Grid container spacing={3} sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={12} md={12}>
                <Typography sx={{ color: 'grey' }}>Contact Email</Typography>
                <TextField
                    id="mailId"
                    variant="outlined"
                    sx={{ mt: 2 }}
                    fullWidth
                    value={selectedOtherTabData.mailId}
                    type="email"
                    placeholder="Enter Email Id"
                    onChange={handleFormEvent}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <Typography sx={{ color: 'grey' }}>Contact Number</Typography>
                <TextField
                    id="customerCareNumber"
                    variant="outlined"
                    type="number"
                    margin="dense"
                    sx={{ mt: 2 }}
                    fullWidth
                    value={`${selectedOtherTabData.customerCareNumber}`}
                    placeholder="Enter Contact Number"
                    onChange={handleFormEvent}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <Typography sx={{ color: 'grey', mt: 2 }}>Timing</Typography>
                <TextField
                    id="customerCareTime"
                    variant="outlined"
                    margin="dense"
                    sx={{ mt: 2 }}
                    fullWidth
                    value={selectedOtherTabData.customerCareTime}
                    placeholder="Enter Timing"
                    onChange={handleFormEvent}
                />
            </Grid>

            <Grid item xs={12} md={12} mt={2} justifyContent={isMobileUi || isTablet ? 'center' : 'end'} display={'flex'}>
                <LoadingButton
                    loading={isOthersSaveLoading}
                    disabled={!isButtonEnable}
                    variant="contained"
                    color="secondary"
                    onClick={addAdditionalData}
                    sx={{ color: '#ffffff' }}
                >
                    Submit
                </LoadingButton>
            </Grid>
        </Grid>
    );
}

export default ContactTab;
