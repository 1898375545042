import { Card, CardContent, IconButton, Switch, Tooltip, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { useTheme } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import { formatServiceTypeUsers } from 'utils/miscellaneous';

const ItemNavigationMenu = React.forwardRef(
    ({ menuData, draggableProps, dragHandleProps, isDragging, itemEditCallback, itemDeleteCallback }, ref) => {
        const theme = useTheme();
        const isMobileUi = useMediaQuery('(max-width:600px)');

        const formatServiceTypeUserText = formatServiceTypeUsers(menuData.serviceType);

        return (
            <Card
                ref={ref}
                {...draggableProps}
                {...dragHandleProps}
                sx={{
                    background: isDragging ? theme.palette.primary.light : '',
                    boxShadow: 1,
                    mb: 2,
                    width: isMobileUi ? '100%' : 'auto',
                    minWidth: 300,
                    minHeight: 150
                }}
            >
                <CardContent>
                    <div style={{ display: 'flex' }}>
                        {isMobileUi ? (
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            marginBottom: 5,
                                            width: '100%'
                                        }}
                                    >
                                        <Typography sx={{ flex: 1 }} variant="h4">
                                            {menuData.title}
                                        </Typography>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Tooltip title="Delete">
                                                <IconButton onClick={itemDeleteCallback}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Edit">
                                                <IconButton onClick={itemEditCallback}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <Typography>{menuData.description}</Typography>
                                    {formatServiceTypeUserText !== null && formatServiceTypeUserText !== '' && (
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                mt: 1,
                                                display: '-webkit-box',
                                                WebkitLineClamp: 3,
                                                WebkitBoxOrient: 'vertical',
                                                textOverflow: 'ellipsis',
                                                fontSize: 12,
                                                overflow: 'hidden'
                                            }}
                                        >
                                            {formatServiceTypeUserText}
                                        </Typography>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ flex: 0.95 }}>
                                        <Typography variant="h4">{menuData.title}</Typography>
                                        <Typography>{menuData.description}</Typography>
                                    </div>
                                </div>

                                {formatServiceTypeUserText !== null && formatServiceTypeUserText !== '' && (
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            mt: 1,
                                            display: '-webkit-box',
                                            WebkitLineClamp: 3,
                                            WebkitBoxOrient: 'vertical',
                                            textOverflow: 'ellipsis',
                                            fontSize: 12,
                                            overflow: 'hidden'
                                        }}
                                    >
                                        {formatServiceTypeUserText}
                                    </Typography>
                                )}

                                <div style={{ alignItems: 'flex-end', justifyContent: 'end', display: 'flex', marginTop: 5 }}>
                                    <Tooltip title="Delete">
                                        <IconButton onClick={itemDeleteCallback}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Edit">
                                        <IconButton onClick={itemEditCallback}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        )}
                    </div>
                </CardContent>
            </Card>
        );
    }
);

export default ItemNavigationMenu;
