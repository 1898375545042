export const GET_ORDERS = 'order';
export const GET_FAQ = 'faq';
export const ADD_UPDATE_FAQ = 'faq';
export const DELETE_FAQ = 'faq/multiple';
export const GET_CURRENCY = 'currency/get';
export const ADD_UPDATE_CURRENCY = 'currency/create';
export const DELETE_CURRENCY = 'currency/delete/multiple';
export const GET_SEAPORT = 'seaports/get';
export const ADD_UPDATE_SEAPORT = 'seaports/create';
export const DELETE_SEAPORT = 'seaports/delete/multiple';
export const GET_PACKAGING = 'packaging/get';
export const ADD_UPDATE_PACKAGING = 'packaging/create';
export const DELETE_PACKAGING = 'packaging/delete/multiple';
export const DELETE_PACKAGING_KG = 'packaging/delete/Kgs/multiple';
export const DELETE_PACKAGING_LBS = 'packaging/delete/Lbs/multiple';

// Approval
export const GET_APPROVAL_COUNT = 'approvals/get/all/count';
export const GET_SELLER_APPROVALS = 'approvals/get/all';
export const UPDATE_SELLER_APPROVAL = 'approvals/approve';
export const UPDATE_SELLER_REJECTED = 'approvals/reject';
export const UPDATE_SELLER_APPROVAL_ALL = 'approvals/approve/all';
export const UPDATE_SELLER_REJECTED_ALL = 'approvals/reject/all';
// User
export const GET_USER = 'users/get_user';
export const GET_USERS = 'users/get';
export const USER_STATUS_UPDATE = 'users/block';
export const GET_ALL_USERS = 'users/get/all';
export const GET_ALL_USERS_COUNT = 'users/get/all/count';
export const GET_ALL_ORDERS = 'order/get/all';
export const GET_ALL_NOTIFICATION = 'notification/get/all';
export const GET_MY_ORDERS = 'order/query';
export const GET_ALL_ORDERS_COUNT = 'order/get/all/count';
export const GET_PROFORMA_INVOICE = 'order/download/proforma_invoice';
export const SEND_NOTIFICATION = 'notification/send';

// Product
export const GET_PRODUCTS = 'rice_details/get';
export const GET_PRODUCTS_ALL = 'rice_details/get/all';
export const DELETE_SUB_PRODUCT = 'rice_details/delete/sub_category';
export const UPDATE_SUB_PRODUCT = 'rice_details/update/subcategory';
export const ADD_SUB_PRODUCT = 'rice_details/create/subcategory';
export const ADD_PRODUCT = 'rice_details/create/category';
export const UPDATE_PRODUCT = 'rice_details/update/category';
export const UPDATE_PRODUCT_ORDER = 'rice_details/category/order';

// Quotation
export const GET_ALL_QUOTES = 'quotation/get/all';
export const GET_ALL_QUOTES_GRAPH = 'quotation/get/rice/graph';
export const GET_ALL_LIVE_TRADE_PRICE = 'quotation/get/rice/dashboard';

//News
export const SAVE_UPDATE_NEWS = 'news';
export const GET_NEWS = 'news';
export const DELETE_NEWS = 'news';

//Config
export const GET_BANNER_CONFIG = 'app_config/banner/get';
export const DELETE_BANNER_CONFIG = 'app_config/banner/delete';
export const ADD_BANNER_CONFIG = 'app_config/banner/create';
export const UPDATE_ORDER_BANNER_CONFIG = 'app_config/banner/order';

export const GET_MENU_CONFIG = 'app_config/menu/get';
export const DELETE_MENU_CONFIG = 'app_config/menu/delete';
export const ADD_MENU_CONFIG = 'app_config/menu/create';
export const UPDATE_MENU_CONFIG = 'app_config/menu/order';

export const GET_ADDITIONAL_CONFIG = 'app_config/additional/get';
export const DELETE_ADDITIONAL_CONFIG = 'app_config/additional/delete';
export const ADD_ADDITIONAL_CONFIG = 'app_config/additional/create/all';

// export const USER_STATUS_UPDATE = 'users/block';
// export const GET_ALL_USERS = 'users/get/all';
// export const GET_ALL_USERS_COUNT = 'users/get/all/count';
