import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import config from 'utils/config/config';
import { GET_ALL_USERS } from 'utils/config/endpoints';
import { OK } from 'utils/config/response-codes';
import { ToastMessageType, userTypes } from 'utils/constants';
import useAxios from 'hooks/useAxios';
import useFeedbackContext from 'contexts/FeedbackContext';

function AutoCompleteSearchList({ usersListSelected, setUserListSelected, clearSearchItemCallback }) {
    const { showToastMessage } = useFeedbackContext();
    const { axiosInstance } = useAxios();

    const [userList, setUserList] = React.useState([]);
    const [searchText, setSearchText] = React.useState('');

    useEffect(() => {
        console.log('userList', userList);
    }, [userList]);

    const fetchUsers = () => {
        axiosInstance
            .get(GET_ALL_USERS, {
                params: {
                    limit: 50,
                    page_count: 0,
                    // status: userStatusSelected,
                    user_type: `${config.userRoles.user},${config.userRoles.seller}`,
                    search: searchText
                }
            })
            .then((response) => {
                if (response.data.response_code === OK) {
                    const dataList = response.data.data.content ?? [];
                    const newItems = dataList.map((item) => {
                        return {
                            ...item,
                            seller_id: item.id,
                            type: item.roles.includes('ROLE_SUPPLIER') ? userTypes.SELLER : userTypes.BUYER
                        };
                    });

                    setUserList(newItems);
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [searchText]);

    const handleOnChangeAutoComplete = (e, value) => {
        let tempUsers = [...usersListSelected]; // create a new array with the same elements
        if (!tempUsers.find((item) => item.id === value.id)) {
            tempUsers.push(value);
        }
        setUserListSelected(tempUsers);
    };

    const handleSearch = (event, value, reason) => {
        if (value.length > config.defaultTableConfig.minSearchCharacters || value.length === 0) {
            setSearchText(value);
        }
    };

    return (
        <Box sx={{ mt: 1 }}>
            <Autocomplete
                id="auto-complete-user-selection"
                clearOnBlur={false}
                options={userList}
                disableClearable={false}
                getOptionLabel={(option) => {
                    return `${option.name} | ${option.cp_name} | ${option.type} | ${option.mobile} | ${option.cp_email}`;
                }}
                onInputChange={handleSearch}
                onChange={handleOnChangeAutoComplete}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            label="Search User"
                            InputProps={{
                                ...params.InputProps,
                                type: 'search'
                            }}
                        />
                    );
                }}
            />
            <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap' }}>
                {usersListSelected.map((data) => (
                    <Chip
                        key={data.id}
                        label={`${data.name} | ${data.cp_name}`}
                        variant="filled"
                        sx={{ m: 1 }}
                        onDelete={() => {
                            clearSearchItemCallback(data);
                        }}
                    />
                ))}
            </Box>
        </Box>
    );
}

export default AutoCompleteSearchList;
