import React from 'react';
import ArticleForm from '../components/ArticleForm';
import DashboardMainLayout from 'layout/DashboardMainLayout';
import { Box, Button, useMediaQuery } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArticleDefaultData, ArticleType, ToastMessageType } from 'utils/constants';
import { isValidUrl } from 'utils/miscellaneous';
import DeleteDialogConfirmation from 'components/Dialogs/DeleteDialogConfirmation';
import useAxios from 'hooks/useAxios';
import useFeedbackContext from 'contexts/FeedbackContext';
import { SAVE_UPDATE_NEWS } from 'utils/config/endpoints';
import { OK } from 'utils/config/response-codes';

function AddEditArticleDetails({ data, isBackCallback, saveDeleteCallback }) {
    const navigate = useNavigate();
    const isMobileUi = useMediaQuery('(max-width:600px)');
    const [articleData, setArticleData] = React.useState(ArticleDefaultData);
    const [articleType, setArticleType] = React.useState(ArticleType.DEFAULT);
    const [isArticleFormValid, setIsArticleFormValid] = React.useState(false);
    const [isArticleEdit, setIsArticleEdit] = React.useState(false);
    const [isDeleteDialogShow, setDeleteDialogShow] = React.useState(false);
    const { showToastMessage } = useFeedbackContext();
    const { axiosInstance } = useAxios();

    const location = useLocation();

    React.useEffect(() => {
        //const routerData = location.state;
        if (data === null) {
            setIsArticleEdit(false);
        } else {
            setArticleType(data.type === 1 ? ArticleType.DEFAULT : ArticleType.CUSTOM);
            setArticleData(data);
            setIsArticleEdit(true);
        }
    }, []);

    React.useEffect(() => {
        if (articleData === null) {
            resetForm();
        }
    }, [articleData]);

    React.useEffect(() => {
        let isValidForm = false;
        if (articleType === ArticleType.DEFAULT) {
            if (articleData.title.length > 0 && isValidUrl(articleData.uri)) {
                isValidForm = true;
            } else {
                isValidForm = false;
            }
        } else {
            if (articleData.title?.length > 0 && articleData.description?.length > 0) {
                if (articleData.image.length > 0) {
                    isValidForm = true;
                } else if (articleData.uri.length > 0) {
                    isValidForm = isValidUrl(articleData.uri);
                } else {
                    isValidForm = true;
                }
            } else {
                isValidForm = false;
            }
        }
        setIsArticleFormValid(isValidForm);
    }, [articleType, articleData]);

    const resetForm = () => {
        setArticleData(ArticleDefaultData);
        saveDeleteCallback();
    };

    const handleFormEvent = (event) => {
        const id = event.target.id;
        const value = event.target.value;

        console.log('handleFormEvent', value);
        setArticleData({ ...articleData, [id]: value });
    };

    const handleChange = (event) => {
        setArticleType(event.target.value);
    };

    const handleSave = () => {
        var articleDataNew = {};
        articleDataNew['id'] = articleData['id'];
        articleDataNew['title'] = articleData['title'];
        articleDataNew['uri'] = articleData['uri'];
        articleDataNew['type'] = 1;
        articleDataNew['date'] = articleData['date'];
        if (articleType === ArticleType.CUSTOM) {
            articleDataNew['type'] = 2;
            articleDataNew['description'] = articleData['description'];
            articleDataNew['image_blob'] = articleData['image_blob'];
            articleDataNew['image'] = articleData['image'];
        }

        // console.log(JSON.stringify(articleDataNew));

        axiosInstance
            .post(SAVE_UPDATE_NEWS, articleDataNew)
            .then((response) => {
                if (response.data.response_code === OK) {
                    showToastMessage('Successfully Processed!!', ToastMessageType.SUCCESS);
                    resetForm();
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch((error) => {
                console.log(error);
                showToastMessage('Something went wrong', ToastMessageType.ERROR);
            });
    };

    const onDeleteArticle = () => {
        axiosInstance
            .delete(SAVE_UPDATE_NEWS + '/' + articleData['id'])
            .then((response) => {
                if (response.data.response_code === OK) {
                    showToastMessage('Successfully Deleted!!', ToastMessageType.SUCCESS);
                    resetForm();
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch((error) => {
                console.log(error);
                showToastMessage('Something went wrong', ToastMessageType.ERROR);
            });
        setDeleteDialogShow(false);
    };

    return (
        <Box sx={{ backgroundColor: '#ffffff', height: '100%', mb: 4 }}>
            <DashboardMainLayout
                title={isArticleEdit ? 'Edit Article' : 'Create Article'}
                isBackButton={true}
                navigateBack={isBackCallback}
                secondary={
                    isArticleEdit ? (
                        <Button
                            onClick={() => {
                                setDeleteDialogShow(true);
                            }}
                            color="error"
                            variant="contained"
                        >
                            Delete Article
                        </Button>
                    ) : null
                }
            >
                <ArticleForm
                    handleArticleChange={handleChange}
                    articleData={articleData}
                    articleType={articleType}
                    handleFormEvent={handleFormEvent}
                />
                <Box sx={{ justifyContent: isMobileUi ? 'center' : 'end', display: 'flex', mr: 2, mt: 2, mb: 2 }}>
                    <Button
                        disabled={!isArticleFormValid}
                        sx={{ width: 200 }}
                        onClick={() => {
                            handleSave();
                        }}
                        variant="contained"
                    >
                        {isArticleEdit ? 'Edit Article' : 'Create Article'}
                    </Button>
                </Box>
            </DashboardMainLayout>

            <DeleteDialogConfirmation
                deleteCallback={onDeleteArticle}
                cancelCallback={() => {
                    setDeleteDialogShow(false);
                }}
                msg="Do you want to delete this article?"
                showDeleteConfirmation={isDeleteDialogShow}
            />
        </Box>
    );
}

export default AddEditArticleDetails;
