import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import config from 'utils/config/config';
import CloseIcon from '@mui/icons-material/Close';
import useFeedbackContext from 'contexts/FeedbackContext';
import { ToastMessageType } from 'utils/constants';

function UpdateStatusDialog({ userStatus, showDialog, onCloseCallback, updateStatusCallback }) {
    const isMobileUi = useMediaQuery('(max-width:600px)');

    const [messageET, setMessageET] = useState('');
    const [messageETError, setMessageETError] = useState(false);
    const { showToastMessage } = useFeedbackContext();

    console.log('UpdateStatusDialog', showDialog);

    const closeDialog = () => {
        setMessageET('');
        setMessageETError(false);
        onCloseCallback();
    };

    useEffect(() => {
        if (messageET.length === 0) {
            setMessageETError(false);
        } else if (userStatus === config.userStatus.rejected && messageET.length < 5) {
            setMessageETError(true);
        } else {
            setMessageETError(false);
        }
    }, [messageET]);

    return (
        <Dialog open={showDialog} fullWidth={isMobileUi ? true : false} maxWidth="sm">
            <div style={{ display: 'flex', flexDirection: 'row', padding: 5, alignItems: 'center' }}>
                <Typography
                    variant="h4"
                    id="update-status-dialog-title"
                    sx={{ marginLeft: 2, fontSize: 16, flex: 1, alignItems: 'center' }}
                >
                    Update Status
                </Typography>
                <Tooltip title="Close" style={{ position: 'unset' }}>
                    <IconButton onClick={closeDialog}>
                        <CloseIcon style={{ color: 'black' }} />
                    </IconButton>
                </Tooltip>
            </div>

            <DialogContent style={{ width: isMobileUi ? 'auto' : 400 }}>
                <TextField
                    error={messageETError}
                    fullWidth
                    value={messageET}
                    maxRows={8}
                    variant="filled"
                    label="Enter Reason"
                    placeholder="Enter Reason"
                    multiline
                    inputProps={{
                        style: {
                            height: '100px'
                        }
                    }}
                    color="secondary"
                    helperText={messageETError ? `Enter Minimum ${5} Characters` : ''}
                    onChange={(e) => {
                        setMessageET(e.target.value);
                    }}
                />
                {userStatus === config.userStatus.approved && <Typography style={{ mt: 2 }}>Optional</Typography>}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    onClick={() => {
                        if (userStatus === config.userStatus.rejected) {
                            if (messageET.length < 5) {
                                setMessageETError(true);
                                showToastMessage(`Enter Minimum ${5} Characters needed for reason message`, ToastMessageType.ERROR);
                                return;
                            }
                        } else {
                            if (messageET.length > 0 && messageET.length < 5) {
                                setMessageETError(true);
                                showToastMessage(`Enter Minimum ${5} Characters needed for reason message`, ToastMessageType.ERROR);
                                return;
                            }
                        }
                        updateStatusCallback(messageET);
                        setMessageET('');
                    }}
                >
                    Update Status
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default UpdateStatusDialog;
