import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';

function SessionExpiry({ handleOnClose }) {
    return (
        <Dialog open={true} aria-labelledby="session-expired-dialog-title" aria-describedby="session-expired-dialog-description">
            <DialogTitle fontSize={16} id="session-expired-dialog-title">
                Session Expired
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="session-expired-dialog-description">
                    Your session has expired. Please log in again to continue.
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                <Button variant="contained" color="error" onClick={handleOnClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SessionExpiry;
