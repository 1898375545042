import React, { useState, useEffect } from 'react';
import {
    Box,
    Step,
    Stepper,
    Typography,
    Button,
    Paper,
    StepContent,
    StepLabel,
    useMediaQuery,
    MobileStepper,
    Grid,
    Card
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import NotificationImage from 'assets/images/push_notifications.svg';
import StepperTargetUserForm from './components/StepperTargetUserForm';
import StepperNotificationForm from './components/StepperNotificationForm';
import config from 'utils/config/config';
import useFeedbackContext from 'contexts/FeedbackContext';
import { ToastMessageType } from 'utils/constants';
import { isValidUrl } from 'utils/miscellaneous';
import DashboardMainLayout from 'layout/DashboardMainLayout';
import { NotificationContextProvider } from './provider/NotificationContext';
import { OK } from 'utils/config/response-codes';
import useAxios from 'hooks/useAxios';
import { SEND_NOTIFICATION } from 'utils/config/endpoints';

const defaultNotificationData = { title: '', description: '', image: '', selectedAction: '' };

function CreateNotification() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const { showToastMessage } = useFeedbackContext();
    const { axiosInstance } = useAxios();
    const [isNotificationFormValid, setIsNotificationFormValid] = useState(false);
    const [isTargetFormValid, setIsTargetFormValid] = useState(false);

    const [notificationFormData, setNotificationFormData] = useState(defaultNotificationData);
    const [userSelectionType, setUserSelectionType] = useState('multi');
    const [usersListSelected, setUserListSelected] = useState([]);
    const [userTypeSelected, setUserTypeSelected] = useState([]);
    const isMobileUi = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width:600px) and (max-width: 991px)');

    useEffect(() => {
        if (notificationFormData.title.length > 0 && notificationFormData.description.length > 0) {
            if (notificationFormData.image.length > 0) {
                setIsNotificationFormValid(isValidUrl(notificationFormData.image));
            } else {
                setIsNotificationFormValid(true);
            }
        } else {
            setIsNotificationFormValid(false);
        }
    }, [notificationFormData]);

    useEffect(() => {
        let isValidForm = false;
        if (userSelectionType === 'multi') {
            isValidForm = userTypeSelected.length > 0;
        } else {
            isValidForm = usersListSelected.length > 0;
        }
        setIsTargetFormValid(isValidForm);
    }, [userTypeSelected, usersListSelected, userSelectionType]);

    const handleNext = () => {
        if (activeStep === 1) {
            // submit form to server
            console.log(JSON.stringify(notificationFormData));
            // send_notification();
            // var test = {
            //     notification_type: userSelectionType,
            //     title: notificationFormData['title'],
            //     body: notificationFormData['description'],
            //     image: notificationFormData['image'],
            //     uri: notificationFormData['uri'],
            //     action: notificationFormData['action'],
            //     action_text: notificationFormData['action_text'],
            //     ids: extractIdsFromModel(usersListSelected),
            //     roles: userTypeSelected
            // };
            // console.log(test);
            send_notification();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const send_notification = () => {
        axiosInstance
            .post(SEND_NOTIFICATION, {
                notification_type: userSelectionType === 'multi' ? 1 : 2,
                title: notificationFormData['title'],
                body: notificationFormData['description'],
                image: notificationFormData['image'],
                uri: notificationFormData['uri'],
                action: notificationFormData['selectedAction']['id'],
                action_text: notificationFormData['action_text'],
                ids: extractIdsFromModel(usersListSelected),
                roles: userTypeSelected
            })
            .then((response) => {
                if (response.data.response_code === OK) {
                    showToastMessage('Notification Sent!!', ToastMessageType.SUCCESS);
                    resetForm();
                    setActiveStep(0);
                } else {
                    showToastMessage(response.data.response_message, ToastMessageType.ERROR);
                }
            })
            .catch((error) => {
                console.log(error);
                showToastMessage('Something went wrong', ToastMessageType.ERROR);
            });
    };

    const resetForm = () => {
        setNotificationFormData(defaultNotificationData);
        setUserSelectionType('multi');
        setUserListSelected([]);
        setUserTypeSelected([]);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNotificationFormChange = (event) => {
        const id = event.target.id;
        const value = event.target.value;

        if (id === 'title' || id === 'description') {
            var isValidData = value.length <= (id === 'description' ? config.notificationMessageLength : config.notificationTitleLength);
            if (!isValidData) return;
            setNotificationFormData({ ...notificationFormData, [id]: value });
        } else if (event.target.name === 'action-notify') {
            setNotificationFormData({ ...notificationFormData, selectedAction: value });
        } else {
            setNotificationFormData({ ...notificationFormData, [id]: value });
        }
    };

    function extractIdsFromModel(model) {
        return model.map((item) => item.id);
    }

    const steps = [
        {
            label: 'Notification',
            description: <StepperNotificationForm data={notificationFormData} handleFormEvent={handleNotificationFormChange} />
        },
        {
            label: 'Target',
            description: (
                <StepperTargetUserForm
                    userSelectionType={userSelectionType}
                    setUserTypeSelected={setUserTypeSelected}
                    setUserListSelected={setUserListSelected}
                    setUserSelectionType={setUserSelectionType}
                    userTypeSelected={userTypeSelected}
                    usersListSelected={usersListSelected}
                />
            )
        }
    ];

    return (
        <NotificationContextProvider>
            <DashboardMainLayout title="Create Notification">
                {isMobileUi ? (
                    <Box
                        sx={{
                            maxWidth: 400,
                            position: 'relative',
                            flexGrow: 1,
                            display: 'flex',
                            left: 0,
                            right: 0,
                            flexDirection: 'column',
                            height: 'calc(100%)',
                            bgcolor: 'background.default'
                        }}
                    >
                        <Paper
                            square
                            elevation={0}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: 50,
                                pl: 2
                            }}
                        >
                            <Typography>{steps[activeStep].label}</Typography>
                        </Paper>
                        <Box sx={{ flex: 1, maxWidth: 400, width: '100%', p: 2, overflowY: 'scroll', height: '80vh' }}>
                            {steps[activeStep].description}
                        </Box>
                        <MobileStepper
                            steps={steps.length}
                            sx={{ bottom: 0, left: 0, right: 0, position: 'fixed' }}
                            activeStep={activeStep}
                            nextButton={
                                <Button size="small" onClick={handleNext} disabled={activeStep === steps.length - 1}>
                                    {activeStep === steps.length - 1 ? 'Done' : 'Next'}
                                    {activeStep !== steps.length - 1 && <KeyboardArrowRight />}
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                    Back
                                </Button>
                            }
                        />
                    </Box>
                ) : (
                    <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                        <Grid item md={6} order={{ sm: 2, md: 1 }}>
                            <Box>
                                <Stepper activeStep={activeStep} orientation="vertical">
                                    {steps.map((step, index) => (
                                        <Step key={step.label}>
                                            <StepLabel
                                                onClick={() => {
                                                    if (!isNotificationFormValid) return;
                                                    setActiveStep(index);
                                                }}
                                                sx={{ cursor: 'pointer' }}
                                                optional={
                                                    index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null
                                                }
                                            >
                                                {step.label}
                                            </StepLabel>
                                            <StepContent>
                                                {step.description}
                                                <Box sx={{ mb: 2, mt: 5 }}>
                                                    <Button
                                                        disabled={index === 0 ? !isNotificationFormValid : !isTargetFormValid}
                                                        variant="contained"
                                                        onClick={handleNext}
                                                        sx={{ mt: 1, mr: 1 }}
                                                    >
                                                        {index === steps.length - 1 ? 'Send Notification' : 'Continue'}
                                                    </Button>
                                                </Box>
                                            </StepContent>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                        </Grid>
                        <Grid item order={{ md: 2, sm: 1 }} md={6} sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                            <img src={NotificationImage} alt="notification-logo" width={300} />
                        </Grid>
                    </Grid>
                )}
            </DashboardMainLayout>
        </NotificationContextProvider>
    );
}

export default CreateNotification;
