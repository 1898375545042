import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, IconButton, Toolbar, useMediaQuery, Typography, Button, Card } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// project imports
import Breadcrumbs from 'components/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from 'utils/menu-items';
import { drawerWidth } from 'styles/constants';

// assets
import { IconChevronRight } from '@tabler/icons';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MENU } from 'store/actions';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import LogoutDialogConfirmation from 'components/Dialogs/LogoutDialogConfirmation';
import useAuthContext from 'contexts/AuthContext';
import useFirebaseService from 'hooks/useFirebaseService';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(open
        ? {
              transition: theme.transitions.create('margin', {
                  easing: theme.transitions.easing.easeOut,
                  duration: theme.transitions.duration.enteringScreen
              }),
              marginLeft: 0,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              width: `calc(100% - ${drawerWidth}px)`,
              [theme.breakpoints.down('md')]: {
                  marginLeft: '20px',
                  padding: '5px',
                  paddingBottom: 0
              },
              [theme.breakpoints.down('sm')]: {
                  marginLeft: '10px',
                  padding: '5px',
                  paddingBottom: 0
              }
          }
        : {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              transition: theme.transitions.create('margin', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen
              }),
              [theme.breakpoints.up('md')]: {
                  marginLeft: -(drawerWidth - 20),
                  width: `calc(100% - ${drawerWidth}px)`
              },
              [theme.breakpoints.down('md')]: {
                  marginLeft: '20px',
                  width: `calc(100% - ${drawerWidth}px)`,
                  padding: '5px',
                  paddingBottom: 0
              },
              [theme.breakpoints.down('sm')]: {
                  marginLeft: '10px',
                  width: `calc(100% - ${drawerWidth}px)`,
                  padding: '5px',
                  marginRight: '10px',
                  paddingBottom: 0
              }
          }),
    marginTop: 60
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const isLeftDrawerOpened = useSelector((state) => state.customization.opened);

    const [leftDrawerOpened, setLeftDrawerOpened] = useState(false);
    const [isLogoutDialogOpened, setIsLogoutDialogOpened] = useState(false);
    const { logout } = useAuthContext();
    const { logoutFirebase } = useFirebaseService();

    const dispatch = useDispatch();

    useEffect(() => {
        setLeftDrawerOpened(isLeftDrawerOpened);
    }, [isLeftDrawerOpened]);

    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });

        setLeftDrawerOpened((prevState) => {
            return !prevState;
        });
    };

    useEffect(() => {
        //dispatch({ type: SET_MENU, opened: !matchDownMd });
        setLeftDrawerOpened(!matchDownMd);
    }, [matchDownMd]);

    const styles = {
        button: {
            background: '#F8D8D8',
            color: '#720B0B',
            fontWeight: 'bold',
            marginRight: 4,
            marginTop: 1,
            '&:hover': {
                background: '#D89F9F' // Change the background color on hover
            },
            '&:focus': {
                background: '#F8D8D8' // Change the background color on focus
            },
            '&:active': {
                background: '#F27474' // Change the background color on active/tap
            }
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    backgroundColor: 'red',
                    bgcolor: theme.palette.background.default,
                    height: 50,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                    <Box sx={{ flex: 1, display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                        <Button
                            sx={styles.button}
                            variant="contained"
                            startIcon={<LogoutRoundedIcon />}
                            onClick={() => {
                                setIsLogoutDialogOpened(true);
                            }}
                        >
                            Logout
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened}>
                {/* breadcrumb */}
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <Outlet />
            </Main>
            <LogoutDialogConfirmation
                showLogoutDialogConfirmation={isLogoutDialogOpened}
                positiveCallback={() => {
                    setIsLogoutDialogOpened(false);
                    logoutFirebase();
                    logout();
                }}
                cancelCallback={() => {
                    setIsLogoutDialogOpened(false);
                }}
            />
        </Box>
    );
};

export default MainLayout;
