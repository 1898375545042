import { initializeApp } from 'firebase/app';
import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { firebaseConfig } from 'utils/config/firebase-config';
import useFeedbackContext from 'contexts/FeedbackContext';
import { ToastMessageType } from 'utils/constants';

const useFirebaseService = () => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [idToken, setIdToken] = useState(null);
    const [authObj, setAuthObj] = useState(null);
    const [storageObj, setStorageObj] = useState(null);

    const { showToastMessage } = useFeedbackContext();

    useEffect(() => {
        const initializeFirebaseApp = async () => {
            // Initialize Firebase app
            const firebaseApp = initializeApp(firebaseConfig);

            // Initialize Firebase auth
            const firebaseAuth = getAuth(firebaseApp);
            setAuthObj(firebaseAuth);
            const storage = getStorage(firebaseApp);
            setStorageObj(storage);

            // Subscribe to user authentication state changes
            const unsubscribe = firebaseAuth.onAuthStateChanged((authUser) => {
                console.log('authUser', authUser);
                setIsLoading(false);
                setUser(authUser);
                if (authUser) {
                    authUser.getIdToken().then((token) => {
                        setIdToken(token);
                    });
                } else {
                    setIdToken(null);
                }
            });

            return unsubscribe;
        };

        initializeFirebaseApp();
    }, []);

    const handleFirebaseMessage = (error) => {
        let errorMessage = '';
        switch (error.code) {
            case 'auth/user-not-found':
                errorMessage = 'Error: User not found';
                break;
            case 'auth/wrong-password':
                errorMessage = 'Error: Incorrect password';
                break;
            case 'auth/invalid-email':
                errorMessage = 'Error: Invalid email address';
                break;
            default:
                errorMessage = `Error: ${error.message}`;
        }
        showToastMessage(errorMessage, ToastMessageType.ERROR);
    };

    // User authentication functions
    const loginFirebase = async (email, password) => {
        try {
            const firebaseAuth = getAuth();
            const { user } = await signInWithEmailAndPassword(firebaseAuth, email, password);
            setUser(user);
            return user;
        } catch (error) {
            throw error;
        }
    };

    const logoutFirebase = async () => {
        try {
            const firebaseAuth = getAuth();
            await signOut(firebaseAuth);
            setUser(null);
        } catch (error) {
            throw error;
        }
    };

    const passwordReset = async (email) => {
        try {
            const firebaseAuth = getAuth();
            return await sendPasswordResetEmail(firebaseAuth, email);
        } catch (error) {
            throw error;
        }
    };

    // Firestore functions

    const getDocument = async (collection, id) => {
        try {
            const docRef = doc(getFirestore(), collection, id);
            const docSnap = await getDoc(docRef);
            return docSnap.exists() ? docSnap.data() : null;
        } catch (error) {
            throw error;
        }
    };

    const setDocument = async (collection, id, data) => {
        try {
            const docRef = doc(getFirestore(), collection, id);
            await setDoc(docRef, data, { merge: true });
            return true;
        } catch (error) {
            throw error;
        }
    };

    // Storage functions

    const uploadFile = async (path, file) => {
        try {
            const fileRef = ref(storageObj, `/${path}/${file.name}`);
            const snapshot = await uploadBytes(fileRef, file);
            const downloadURL = await getDownloadURL(snapshot.ref);
            return downloadURL;
        } catch (error) {
            throw error;
        }
    };

    return {
        user,
        isLoading,
        idToken,
        authObj,
        loginFirebase,
        logoutFirebase,
        getDocument,
        setDocument,
        uploadFile,
        handleFirebaseMessage,
        passwordReset
    };
};

export default useFirebaseService;
