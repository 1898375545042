import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import QuotationManagement from 'pages/product-management/quotations';
import AppConfig from 'pages/settings/AppConfig';
import CurrencyConfig from 'pages/settings/CurrencyConfig';
import SeaPortConfig from 'pages/settings/SeaPortConfig';
import PackagingConfig from 'pages/settings/PackagingConfig';
import Faqs from 'pages/settings/Faqs';
import ArticleDetails from 'pages/others/articles/details/AddEditArticleDetails';
import CreateNotification from 'pages/others/notifications/CreateNotification';
import AddBannerScreen from 'pages/settings/AppConfig/banner/AddBannerScreen';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// utilities routing
const User = Loadable(lazy(() => import('pages/user-management/user')));
const Products = Loadable(lazy(() => import('pages/product-management/products')));
const Articles = Loadable(lazy(() => import('pages/others/articles')));
const Feedback = Loadable(lazy(() => import('pages/others/feedback')));
const Notifications = Loadable(lazy(() => import('pages/others/notifications')));
const Orders = Loadable(lazy(() => import('pages/orders')));
const ApprovalSeller = Loadable(lazy(() => import('pages/approval/seller')));
const ApprovalQuotation = Loadable(lazy(() => import('pages/approval/quotation')));

const ProductDetails = Loadable(lazy(() => import('pages/product-management/product-details')));

// sample page routing
const NotFound = Loadable(lazy(() => import('pages/NotFound')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            index: true,
            title: 'Dashboard',
            element: <DashboardDefault />
        },
        {
            path: '/home',
            title: 'Home',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            title: 'Dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'user-management',
            element: <User />
        },
        {
            path: 'approval',
            children: [
                {
                    path: 'seller',
                    element: <ApprovalSeller />
                },
                {
                    path: 'quotation',
                    element: <ApprovalQuotation />
                }
            ]
        },
        {
            path: 'product-management',
            children: [
                {
                    path: 'products',
                    children: [
                        {
                            path: '',
                            element: <Products />
                        },
                        {
                            path: 'product-details',
                            element: <ProductDetails />
                        }
                    ]
                },
                {
                    path: 'quotation',
                    element: <QuotationManagement />
                }
            ]
        },
        {
            path: 'orders',
            element: <Orders />
        },
        {
            path: 'settings',
            children: [
                {
                    path: 'app-config',
                    element: <AppConfig />
                },
                {
                    path: 'currency-config',
                    element: <CurrencyConfig />
                },
                {
                    path: 'seaport-config',
                    element: <SeaPortConfig />
                },
                {
                    path: 'packaging-config',
                    element: <PackagingConfig />
                },
                {
                    path: 'faqs-config',
                    element: <Faqs />
                },
                {
                    path: 'app-banner',
                    element: <AddBannerScreen />
                }
            ]
        },

        {
            path: 'others',
            children: [
                {
                    path: 'notification',
                    children: [
                        {
                            path: '',
                            element: <Notifications />
                        },
                        {
                            path: 'create-notification',
                            element: <CreateNotification />
                        }
                    ]
                },
                {
                    path: 'articles',
                    children: [
                        {
                            path: '',
                            element: <Articles />
                        },
                        {
                            path: 'create-article',
                            element: <ArticleDetails />
                        },
                        {
                            path: 'edit-article',
                            element: <ArticleDetails />
                        }
                    ]
                },
                {
                    path: 'feedback',
                    element: <Feedback />
                }
            ]
        },
        {
            path: '*',
            element: <NotFound />
        }
    ]
};

export default MainRoutes;
