import { Box, Button, Divider, IconButton, List, ListItem, ListItemText, TextField, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import useAppConfigContext from 'pages/settings/provider/AppConfigContext';

export default function PaymentTermsTab() {
    const [paymentTermList, setPaymentTermList] = useState([]);
    const [paymentTerms, setPaymentTerms] = useState('');

    const isMobileUi = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width:600px) and (max-width: 991px)');

    const [isButtonEnable, setIsButtonEnable] = useState(false);
    const { createAdditionalConfig, isOthersSaveLoading, selectedTabOtherAppConfig } = useAppConfigContext();

    const handleNewPaymentTerm = (event) => {
        const value = event.target.value;
        setPaymentTerms(value);
    };

    useEffect(() => {
        if (selectedTabOtherAppConfig !== null) {
            setPaymentTermList(selectedTabOtherAppConfig.paymentTerms ?? []);
        }
    }, [selectedTabOtherAppConfig]);

    const handleAddPaymentTerm = () => {
        if (paymentTerms && !paymentTermList.includes(paymentTerms)) {
            setPaymentTermList([...paymentTermList, paymentTerms]);
        }
        setPaymentTerms('');
    };

    useEffect(() => {
        if (paymentTermList.length > 0) {
            setIsButtonEnable(true);
        } else {
            setIsButtonEnable(false);
        }
    }, [paymentTermList]);

    const handleDeletePaymentTerm = (dataPaymentTerm) => {
        const updatedList = paymentTermList.filter((element) => element !== dataPaymentTerm);
        const values = { paymentTerms: updatedList };
        createAdditionalConfig(values);
    };

    const addAdditionalData = () => {
        const values = { paymentTerms: paymentTermList };
        createAdditionalConfig(values);
    };

    return (
        <div>
            <Typography variant="h4" sx={{ mt: 2 }}>
                Payment Terms
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <TextField
                    id="payment_terms"
                    variant="outlined"
                    margin="dense"
                    sx={{ mt: 2, flex: 1 }}
                    fullWidth
                    value={paymentTerms}
                    placeholder="Enter Payment Terms"
                    onChange={handleNewPaymentTerm}
                />
                <Button sx={{ height: 30, width: 30, marginLeft: 2, mt: 1 }} variant="contained" onClick={handleAddPaymentTerm}>
                    {' '}
                    +{' '}
                </Button>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', justifyItems: 'flex-start' }}>
                <List sx={{ width: '100%', mt: 2 }}>
                    {paymentTermList.map((value, index) => {
                        console.log('value paymentTermList', value);
                        return (
                            <div key={value}>
                                <ListItem
                                    key={value}
                                    disableGutters
                                    secondaryAction={
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => {
                                                handleDeletePaymentTerm(value);
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemText primary={value} />
                                </ListItem>
                                {index !== paymentTermList.length - 1 ? <Divider /> : null}
                            </div>
                        );
                    })}
                </List>
            </div>

            <Box justifyContent={isMobileUi || isTablet ? 'center' : 'end'} display={'flex'}>
                <LoadingButton
                    loading={isOthersSaveLoading}
                    disabled={!isButtonEnable}
                    variant="contained"
                    color="secondary"
                    onClick={addAdditionalData}
                    sx={{ color: '#ffffff' }}
                >
                    Submit
                </LoadingButton>
            </Box>
        </div>
    );
}
